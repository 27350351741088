import FedmonApi from '../api/fedmonApi'
import * as types from "./actionTypes";

import { normalize } from 'normalizr';
import * as schema from '../api/schema';

export function loadCoreInfo() {
    return (dispatch) => {
        dispatch(coreInfoIsLoading(true));

        FedmonApi.getTestbedsByCategories([])
            .then(result => {
                const normalizedResult = normalize(result, [schema.testbed]);
                dispatch(fetchCoreInfoSuccess(normalizedResult));
                dispatch(coreInfoIsLoading(false));
            })
            .catch(error => {
                dispatch(fetchCoreInfoError(error));
                dispatch(coreInfoIsLoading(false));
            });
    }
}

export function coreInfoIsLoading(isLoading) {
    return {'type': types.COREINFO_IS_LOADING, isLoading}
}

export function fetchCoreInfoSuccess(results) {
    return {'type': types.LOAD_COREINFO_SUCCESS, results, 'timestamp': Date.now()}
}

export function fetchCoreInfoError(error) {
    return {'type': types.LOAD_COREINFO_ERROR, error, 'timestamp': Date.now()}
}