import FedmonApi from '../api/fedmonApi'
import * as types from "./actionTypes";

import {normalize} from 'normalizr';
import * as schema from '../api/schema';


export function loadLatestResultsByTestbedAndTestDefinition(testbedId, testDefinition) {
    return (dispatch, getState) => {
        dispatch(latestResultsForTestDefinitionIsLoading(true, testbedId, testDefinition));

        FedmonApi.getLatestResultsByTestbeds([testbedId], [testDefinition])
            .then(result => {
                dispatch(latestResultsForTestDefinitionIsLoading(false, testbedId, testDefinition));

                const normalizedResult = normalize(result, [schema.result]);
                dispatch(fetchLatestResultsForTestDefinitionSuccess(normalizedResult, testbedId, testDefinition));
            })
            .catch(error => {
                dispatch(latestResultsForTestDefinitionIsLoading(false, testbedId, testDefinition));
            });
    }
}

export function latestResultsForTestDefinitionIsLoading(isLoading, testbedId, testDefinition) {
    return {'type': types.TESTDEFINITION_LATESTRESULTS_IS_LOADING, isLoading, testbedId, testDefinition}
}

export function fetchLatestResultsForTestDefinitionSuccess(results, testbedId, testDefinition) {
    return {
        'type': types.LOAD_TESTDEFINITION_LATESTRESULTS_SUCCESS,
        results,
        testbedId,
        testDefinition,
        'timestamp': Date.now(),
    }
}
export function fetchLatestResultsByCategoriesError(error, testbedId, testDefinition) {
    return {
        'type': types.LOAD_TESTDEFINITION_LATESTRESULTS_ERROR,
        error,
        testbedId,
        testDefinition,
        'timestamp': Date.now()
    }
}