import React, {Component} from 'react';
import {Tooltip} from 'antd';
import {PropTypes} from 'prop-types';

class StatusIcon extends Component {
    static lateIcon = "#late";

    static defaultProps = {
        viewBoxWidth: 100,
        viewBoxHeight: 100,
    };

    render() {
        const {iconName,  late} = this.props;
        let {status,tooltipContent} = this.props;

        if(late){
            status="UNAVAILABLE";
            tooltipContent="This test is not up-to-date"
        }

        const fullIconName = "#".concat(iconName);

        var classNames = "status-icon status-icon-".concat(status.toLowerCase());

        return (
            <div className="testbed-status-container">
                <Tooltip placement="bottom" title={tooltipContent}>
                    <svg ref="connectivityIcon" className={classNames} viewBox={`0 0 ${this.props.viewBoxWidth} ${this.props.viewBoxHeight}`}>
                        <use href={fullIconName}/>
                        {late ? <use href={StatusIcon.lateIcon} x={5} y={5} transform="scale(2.5)" className="late-icon"/> : []}
                    </svg>
                </Tooltip>
            </div>
        );


    }


    static propTypes = {
        iconName: PropTypes.string.isRequired,
        tooltipContent:PropTypes.any.isRequired,
        status: PropTypes.oneOf(["SUCCESS", "FAILURE", "WARNING", "UNAVAILABLE"]),
        late: PropTypes.bool
    }

}

export default StatusIcon;