import React, {Component} from 'react';
import {connect} from 'react-redux';
import './SelfTest.css'
import {Link} from 'react-router-dom';
import {PropTypes} from 'prop-types';

class SelfTestWarning extends Component {

    constructor(props) {
        super(props);

        this.state = {
            moreInfo: false
        };
    }

    render() {
        const {results, error} = this.props;

        const {moreInfo} = this.state;

        let showAlert = false;
        let alertType = "danger";
        let summaryStatus = "error";

        let alertText = [];
        let moreInfoText = [];

        if (error) {
            showAlert = true;
            alertText = "The status of the federation monitor could not be fetched. This is typically caused by network problems.";
        }

        if (results && results.length > 0) {
            const lastResult = results[0];
            summaryStatus = lastResult.summary ? lastResult.summary.toLowerCase() : "error";


            if (lastResult.summary === "ERROR") {
                alertType="danger";
                alertText = (<span><strong>Warning:</strong> The federation monitor is currently out of order. The test results
                    are no longer updated.&nbsp;</span>);
                moreInfoText = (<span>This is typically caused by network problems, or
                                    due to issues with the imec user authority.<br/>
                                    The federation monitor will <Link to="/history/365">periodically check</Link> if
                                    this is resolved.</span>);
            } else if (lastResult.summary === "WARNING") {
                alertType = "warning";
                alertText = (<span> <strong>Warning:</strong> The federation monitor is currently overloaded. One or more tests
                            might be out of date.&nbsp;</span>);
                moreInfoText = (<span>This is typically due to problems related to slow
                                    tests on the
                                    monitoring server. All monitoring will continue, but the test overview below might
                                    contain out
                                    of date test results. These out of date results will be marked.<br/>
                                    The federation monitor will <Link to="/history/365">periodically check</Link> if
                                    this is resolved.</span>);
            } else {
                return null;
            }
        }

        if (showAlert) {
            return (
                <div className="selftest-wrapper">
                    <div role="alert" className={`alert alert-${alertType}`}>
                        <div className={`selftest-alert-content ${summaryStatus}`}>

                            <svg className="selftest-status-icon" ref="healthIcon" viewBox={`0 0 73 66`} >
                                <use href={"#health-".concat(summaryStatus)}/>
                            </svg>
                            <div className="selftest-alert-text">{alertText}
                                {moreInfoText.length > 0 ?
                                    moreInfo ? (
                                        <div className="selftest-moreinfo"><p>{moreInfoText}</p>
                                            <p><span onClick={() => {
                                                this.setState({moreInfo: false})
                                            }}>Less info</span></p>
                                        </div>) : (
                                        <span onClick={() => {
                                            this.setState({moreInfo: true})
                                        }}>More info</span>
                                    )
                                    : []}

                            </div>
                        </div>
                    </div>
                </div>);
        }
        else return null;
    }

}

SelfTestWarning.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    results: PropTypes.array.isRequired,
    error: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    // state = { selftest: { fetch_success: true, results [ ...] }

    const st = state.selftest;

    return {
        'isLoading': st.isLoading,
        'results': st.results,
        'error': st.error
    }
}

export default connect(mapStateToProps)(SelfTestWarning);