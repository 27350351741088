import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card, Divider} from 'antd';

import './CallState.css'
import LogLinesTable from "./LogLinesTable";
import {TimeWithTooltip} from "./TimeWithTooltip";
import FedmonUtils from '../../../utils';

export class CallStateHeader extends Component {
    render() {
        const {callState} = this.props;
        const {name, statestate} = callState;

        return (
            <span><span style={{marginRight: 8}}>{FedmonUtils.getFontAwesomeFromStatus(statestate)}</span> {name}</span>
        );
    }
}

CallStateHeader.propTypes = {
    method: PropTypes.object.isRequired,
};

export class CallStateContent extends Component {


    render() {
        const {callState} = this.props;
        const {start, end} = callState;

        const lines = callState.logentries ? (callState.logentries.logentry instanceof Array ? callState.logentries.logentry : [callState.logentries.logentry]) : [];

        return (
            <Card title={<CallStateHeader callState={callState}/>} style={{marginBottom: 24}}>
                <p>Ran from <TimeWithTooltip
                    time={start}/> until <TimeWithTooltip time={end}/></p>

                <Divider orientation="left">Logs</Divider>
                <LogLinesTable logLines={lines}/>

            </Card>
        );
    }
}

CallStateContent.propTypes = {
    method: PropTypes.object.isRequired,
    showLogTimestamp: PropTypes.bool,
};
