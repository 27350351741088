import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import './FInteropTestSuiteTable.css';

export default class FInteropTestSuiteTable extends Component {

    renderCell(testsuite, tcId) {
        const {resultId} = this.props;

        const linkUrl = `/result/${resultId}/finterop/details/#${testsuite.testname}-${tcId}`;
        const testcaseResult = testsuite.tc_results.find(ttc => ttc.testcase_id === tcId);

        const verdict = testcaseResult ? testcaseResult.verdict : null;

        return (<td key={`${testsuite.testname}-${tcId}`} className={`verdict-${verdict}`}>
            <Link to={linkUrl}>{verdict !== "None" ? verdict : "skipped"}</Link>
        </td>);
    }

    render() {
        const {finteroptests} = this.props;

        const nonEmptyResults = finteroptests.map(finteroptest => finteroptest.tc_results).find(results => results !== null);

        if (!nonEmptyResults) {
            return (<div>No results</div>);
        }

        const testcase_ids = nonEmptyResults.map(tc => tc.testcase_id);


        return (
            <table className="finterop-testsuite-table">
                <thead>
                <tr>
                    <th></th>
                    {finteroptests.map(t => (<th key={t.testname}>{t.testname}</th>))}
                </tr>
                </thead>
                <tbody>
                {testcase_ids.map(tcId => (<tr key={tcId}>
                        <td>{tcId}</td>
                        {finteroptests.map(t => this.renderCell(t, tcId))}

                    </tr>)
                )}
                </tbody>
            </table>
        );
    }
}

FInteropTestSuiteTable.propTypes = {
    resultId: PropTypes.number.isRequired,
    finteroptests: PropTypes.array.isRequired
};