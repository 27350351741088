import * as types from "../actions/actionTypes";

const initialState = {
    isLoading: [],//list of testDefinitionIds for which the info is loading
    byId: {},    // the rest of the info is kept here by testDefinition-id
    errors: {},
    timestamps: {}
};


export default function testDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case types.TESTDEFINITION_LATESTRESULTS_IS_LOADING:
            if (action.isLoading) {
                return {...state, isLoading: [...state.isLoading, [action.testbedId, action.testDefinition]]}
            } else {
                return {
                    ...state,
                    isLoading: state.isLoading.filter(id => id !== [action.testbedId, action.testDefinition])
                }
            }
        case types.LOAD_TESTDEFINITION_LATESTRESULTS_SUCCESS:

            const prevTestbedResults = state.byId[action.testbedId] || {};
            const prevTestbedErrors = state.errors[action.testbedId] || {};
            const prevTestbedTimestamps = state.timestamps[action.testbedId] || {};


            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.testbedId]: {
                        ...prevTestbedResults,
                        [action.testDefinition]: action.results.entities.results
                    }
                },
                errors: {
                    ...state.errors,
                    [action.testbedId]: {
                        ...prevTestbedErrors,
                        [action.testDefinition]: null
                    }
                },
                timestamps: {
                    ...state.timestamps,
                    [action.testbedId]: {
                        ...prevTestbedTimestamps,
                        [action.testDefinition]: action.timestamp
                    }
                }
            };
        case types.LOAD_TESTDEFINITION_LATESTRESULTS_ERROR:
            const prev2TestbedResults = state.byId[action.testbedId] || {};
            const prev2TestbedErrors = state.errors[action.testbedId] || {};
            const prev2TestbedTimestamps = state.timestamps[action.testbedId] || {};


            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.testbedId]: {
                        ...prev2TestbedResults,
                        [action.testDefinition]: []
                    }
                },
                errors: {
                    ...state.errors,
                    [action.testbedId]: {
                        ...prev2TestbedErrors,
                        [action.testDefinition]: action.error
                    }
                },
                timestamps: {
                    ...state.timestamps,
                    [action.testbedId]: {
                        ...prev2TestbedTimestamps,
                        [action.testDefinition]: action.timestamp
                    }
                }
            };

        default:
            return state;
    }
}