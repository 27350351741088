

import FedmonUtils from "../utils";

export class TestNames {
    static PING = "ping";
    static GETVERSION = "anyGetVersion";
    static LISTRESOURCES = "listResources";
    static INTERNAL_STATUS = "internalStatus";
    static NODELOGIN = "nodelogin";
    static NODELOGIN2 = "nodelogin2";
    static NODELOGIN2LONG = "nodelogin2long";
    static AGGREGATE_STATUS = "aggregateTestbedStatus";
}

export class Periods {
    static TEN_YEAR = "10years";
    static ONE_YEAR = "1year";
    static ONE_MONTH = "1month";
    static ONE_WEEK = "1week";
}

export function getTestInstanceIdByTestDefinition(testDefinitionName, testInstances) {
    if (!testInstances)
        return null;

    const testInstance = testInstances
        .find(testInstance => FedmonUtils.getIdFromUrlString(testInstance.testDefinition) === testDefinitionName);

    if (testInstance)
        return testInstance.id;
    else
        return null;
}

export function getRrdByTestDefinitionAndPeriod(testDefinitionName, period, rrd) {
    if(!rrd)
        return null;

    const rrdResult = rrd
        .find(r =>
            FedmonUtils.getIdFromUrlString(r.testDefinition) === testDefinitionName
            && r.periodDescription === period);

    if (rrdResult) {
        return rrdResult.id;
    } else {
        return null;
    }
}

export function getRrdByTestDefinitionsAndPeriod(testDefinitionNames, period, rrd, preferredTestInstanceId) {
    if(!rrd)
        return null;

    let rrdResult = rrd
        .find(r =>
            testDefinitionNames.includes(FedmonUtils.getIdFromUrlString(r.testDefinition))
            && r.periodDescription === period
            && r.testInstance.endsWith('/'+preferredTestInstanceId));

    if (!rrdResult) {
        rrdResult = rrd
            .find(r =>
                testDefinitionNames.includes(FedmonUtils.getIdFromUrlString(r.testDefinition))
                && r.periodDescription === period);
    }

    if (rrdResult) {
        return rrdResult.id;
    } else {
        return null;
    }
}
