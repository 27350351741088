import React, {Component} from "react";
import PropTypes from 'prop-types';
import {parseString as parseXmlString} from 'xml2js';
import Loading from "../../../components/Loading";
import {loadTestResultIfNeeded} from "../../../actions/testResults";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {Element} from 'react-scroll';
import {Layout, Anchor, Card, Row, Col, Divider, Steps, Alert} from "antd";
import {TestStepHeader, TestStepContent} from "./TestStep";
import {scroller} from "react-scroll/modules/index";
import FedmonUtils from '../../../utils'

import './TestInfo.css'

const {Sider, Content} = Layout;

function XMLStringToObject(string) {
    return new Promise((resolve, reject) => {
        parseXmlString(string, {explicitArray: false}, (err, result) => {
            if (err) reject(err);

            return resolve(result);
        });
    });
}


class DescriptionList extends Component {
    render() {
        const {children} = this.props;
        return (<div className="description-list">{children}</div>)
    }
}

class Description extends Component {
    render() {
        const {term, children, valueAsCode} = this.props;
        return (<div className={`description ${valueAsCode ? "code-description" : ""}`}>
            <div className="description-title">{term}</div>
            <div className="description-value">{children}</div>
        </div>)
    }
}

class TestInfo extends Component {


    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            error: null,
            overview: null,
            siderCollapsed: null,
        };
    }

    componentDidMount() {
        const {resultId, resultXml, dispatch} = this.props;
        dispatch(loadTestResultIfNeeded(resultId));

        if (resultXml) {
            this.fetchOverview(resultXml);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {resultId, resultXml, dispatch, hash} = this.props;
        const {overview} = this.state;

        if (resultId !== prevProps.resultId) {
            dispatch(loadTestResultIfNeeded(resultId));
        }

        if (resultXml !== prevProps.resultXml) {
            this.fetchOverview(resultXml);
        }

        if (hash !== prevProps.hash || overview !== prevState.overview) {
            scroller.scrollTo(hash, {offset: -24});
        }
    }

    fetchOverview(resultXml) {
        this.setState({overview: null, hasError: false});

        fetch(resultXml)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(XMLStringToObject)
            .then(result => {
                this.setState({overview: result ? result.overview : null});
            }).catch(err => {
            console.log(err);
            this.setState({hasError: true, error: err})
        });
    }

    render() {
        const {hasError, error, overview, siderCollapsed} = this.state;

        if (hasError) {
            return (<Layout><Content className="content-wrapper"><Alert
                message="An error occured while fetching the steps info" description={error ? error.message : null}
                type="error"/></Content></Layout>);
        } else if (!overview) {
            return (<Layout><Content className="content-wrapper"><Loading/></Content></Layout>);
        } else {

            const {
                version, environment, TestUserUrn, TestUserAuthorityUrn,
                TestedAuthorityUrn, TestedClass, TestedMethods
            } = overview;

            return (<Layout>
                <Sider style={{backgroundColor: "#f0f2f5"}} width={240} breakpoint="lg" theme="light" collapsedWidth={0}
                       onCollapse={(status) => this.setState({siderCollapsed: status})}>
                    {!siderCollapsed ?
                        <Anchor className="apicalls-anchor" offsetTop={24}>

                            <Anchor.Link href="#test-info" title="Test info"/>
                            <Anchor.Link href="#overview" title="Overview"/>
                            <Anchor.Link href="#steps" title="Test steps">
                                {overview.methods.method.map(method => {
                                    const {methodName} = method['$'];

                                    return (
                                        <Anchor.Link href={`#${methodName}`}
                                                     title={<TestStepHeader method={method}/>}/>);
                                })}
                            </Anchor.Link>
                        </Anchor>
                        : null}
                </Sider>
                <Content style={{margin: 24}}>


                    <h2 id="test-info">Test info</h2>
                    <Row gutter={16}>
                        <Col md={12} sm={24}>
                            <Card title="Environment" style={{marginBottom: 24}}>
                                <DescriptionList size="small" layout="horizontal">
                                    <Description term="jFed Version">{version}</Description>
                                    <Description term="Server environment">{environment}</Description>
                                </DescriptionList>
                                <Divider/>
                                <DescriptionList size="large" layout="horizontal">
                                    <Description valueAsCode={true} term="TestedClass">{TestedClass}</Description>
                                    <Description term="TestedMethods">{TestedMethods}</Description>
                                </DescriptionList>
                            </Card>
                        </Col>
                        <Col md={12} sm={24}>
                            <Card title="URNs" style={{marginBottom: 24}}>
                                <DescriptionList size="large" layout="horizontal">
                                    <Description valueAsCode={true} term="TestUserUrn">{TestUserUrn}</Description>
                                    <Description valueAsCode={true}
                                                 term="TestUserAuthorityUrn">{TestUserAuthorityUrn}</Description>
                                    <Description valueAsCode={true}
                                                 term="TestedAuthorityUrn">{TestedAuthorityUrn}</Description>
                                </DescriptionList>
                            </Card>
                        </Col>
                    </Row>

                    <h2 id="overview">Overview</h2>

                    <div style={{backgroundColor: '#fff', padding: 24}}>
                        <Steps className="test-steps" size="small" direction="vertical"
                               current={overview.methods.method.length + 1}>
                            {overview.methods.method.map(method => {
                                const {methodName, state, description} = method['$'];
                                return (<Steps.Step key={methodName} icon={FedmonUtils.getFontAwesomeFromStatus(state)} title={(<Link
                                    to={`#${methodName}`}>{methodName}</Link>)} description={description}/>);
                            })}
                        </Steps>
                    </div>

                    <h2 id="steps">Test steps</h2>


                    {overview.methods.method.map(method => {
                        const {methodName} = method['$'];

                        return (<Element id={methodName} key={methodName}>
                            <TestStepContent method={method}/></Element>);
                    })}

                </Content>
            </Layout>);
        }
    }
}

TestInfo.propTypes = {
    resultId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    const resultId = ownProps.match.params.resultId;
    const result = state.testResults.byId[resultId];

    let resultXml = null;
    if (result && result.results) {
        resultXml = result.results.resultXml;
    }

    const hash = state.router.location.hash ? state.router.location.hash.substring(1) : null;

    return {resultId, resultXml, hash}
}

export default connect(mapStateToProps)(TestInfo);