import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card, Divider, Collapse} from 'antd';
import FedmonUtils from '../../../utils.js'

import humanizeDuration from 'humanize-duration'
import './TestStep.css'
import LogLinesTable from "../Logs/LogLinesTable";
import {TimeWithTooltip} from "../Logs/TimeWithTooltip";
import APICall from "../CallsInfo/APICall";

export class TestStepHeader extends Component {
    render() {
        const {method} = this.props;
        const {methodName, state} = method['$'];

        return (
            <span><span style={{marginRight: 8}}>{FedmonUtils.getFontAwesomeFromStatus(state)}</span> {methodName}</span>
        );
    }
}

TestStepHeader.propTypes = {
    method: PropTypes.object.isRequired,
};


export class TestStepContent extends Component {

    renderAPICalls() {
        const {method} = this.props;

        const calls = method.apicalls ? (method.apicalls.apicall instanceof Array ? method.apicalls.apicall : [method.apicalls.apicall]) : [];

        if (calls.length === 0) {
            return (<div>No calls</div>);
        }

        return (<Collapse>
            {calls.map(call => {

                const adaptedCall = {
                    ...call,
                    authority_name: call.autority_name,
                    connection_user_certs: call.connection_sslauth_user_certs,
                    connection_config_authentication_method: call.connection_auth,
                    connection_config_protocol: call.connection_proto,
                    connection_config_type: call.connection_type,
                    end_time: call.stop_time
                };

                return (
                    <Collapse.Panel header={<span><samp>{adaptedCall.geni_method_name}</samp>{adaptedCall.authority_name ? (<span> to <em>{adaptedCall.authority_name}</em></span>): null}</span>}>
                    <APICall call={adaptedCall}/>
                </Collapse.Panel>)})}
        </Collapse>);
    }

    renderLogLines() {
        const {method} = this.props;

        const lines = method.loglines ? (method.loglines.logline instanceof Array ? method.loglines.logline : [method.loglines.logline]) : [];

        return (<LogLinesTable logLines={lines}/>)
    }

    render() {
        const {method} = this.props;
        const {description, startTimeMs, stopTimeMs, timeMs} = method['$'];

        return (
            <Card title={<TestStepHeader method={method}/>} style={{marginBottom: 24}}>
                <p>{description}</p>
                <p>Ran in {humanizeDuration(timeMs)} from <TimeWithTooltip
                    time={Number(startTimeMs)}/> until <TimeWithTooltip time={Number(stopTimeMs)}/></p>


                <Divider orientation="left">API Calls</Divider>
                {this.renderAPICalls()}
                <Divider orientation="left">Logs</Divider>
                {this.renderLogLines()}

            </Card>
        );
    }
}

TestStepContent.propTypes = {
    method: PropTypes.object.isRequired,
    showLogTimestamp: PropTypes.bool,
};
