import * as types from "../actions/actionTypes";

const initialState = {
    isLoading: [],//list of testDefinitionIds for which the info is loading
    byId: {},    // the rest of the info is kept here by testDefinition-id
    errors: {},
    timestamps: {}
};


export default function testDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case types.TESTDEFINITION_IS_LOADING:
            if (action.isLoading) {
                return {...state, isLoading: [...state.isLoading, action.testDefinitionId]}
            } else {
                return {...state, isLoading: state.isLoading.filter(id => id !== action.testDefinitionId)}
            }
        case types.LOAD_TESTDEFINITION_SUCCESS:
            return {
                ...state,
                byId: {...state.byId, [action.testDefinitionId]: action.result},
                errors: {...state.errors, [action.testDefinitionId]: null},
                timestamps: {...state.timestamps, [action.testDefinitionId]: action.timestamp}
            };
        case types.LOAD_TESTDEFINITION_ERROR:
            return {
                ...state,
                byId: {...state.byId, [action.testDefinitionId]: null},
                errors: {...state.errors, [action.testDefinitionId]: action.error},
                timestamps: {...state.timestamps, [action.testDefinitionId]: action.timestamp}
            };
        default:
            return state;
    }
}