import {SELFTEST_MS_BETWEEN_CHECKS, loadSelfTestHistory} from './actions/selftest';
import {TESTRESULT_MS_BETWEEN_UPDATES, loadLatestResultsByCategories} from './actions/latestTestResults';
import {} from './actions/selftest'

export default function startTimer(store) {
    const tick = () => {
        let actions = calculate_pending_actions(store.getState());
        actions.forEach(store.dispatch);
    };
    tick();
    setInterval(tick, 100);
}


function calculate_pending_actions(state) {
    let {selftest, latestResults, selectableCategories} = state;
    let actions = [];
    // put all your conditions here...
    if (!selftest.isLoading && (!selftest.timestamp || (Date.now() - selftest.timestamp) > SELFTEST_MS_BETWEEN_CHECKS )) {
        actions.push(loadSelfTestHistory());
    }


    if (latestResults.updateRequestersCount > 0) {
        //request results per 3 categories
        const cats = Object.entries(selectableCategories)
            .filter(([id,cat]) => cat.enabled)
            .filter(([id,cat]) => !latestResults.isLoading.includes(id)
                && (!latestResults.timestamps[id] || latestResults.timestamps[id] < (Date.now() - TESTRESULT_MS_BETWEEN_UPDATES)))
            .sort((([id1,cat1], [id2,cat2]) => cat1.weight - cat2.weight))
            .map(([id,cat]) => id);

        for(let i = 0; i < (Math.ceil(cats.length / 3)); i++){
            actions.push(loadLatestResultsByCategories(cats.slice(i*3,Math.min((i+1)*3, cats.length))));
        }
    }


    // etc...
    return actions;
}
