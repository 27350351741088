import React, {Component} from 'react';
import {push} from "connected-react-router";
import InternalError from "./modules/Error/InternalError";
import connect from "react-redux/es/connect/connect";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = { error: null, errorInfo: null}
    }

    componentDidCatch(error, errorInfo) {
        console.log("Catching error", error);
        this.setState({error, errorInfo})
    }

    resetError(){
        const {dispatch} = this.props;

        dispatch(push('/'));
        this.setState({ error: null, errorInfo: null});
    }

    render() {
        const { error, errorInfo} = this.state;

        if(error){
            return (<InternalError error={error} errorInfo={errorInfo} reset={this.resetError.bind(this)}/>)
        }

        return this.props.children;
    }
}


export default connect()(ErrorBoundary);