import * as types from "../actions/actionTypes";

const initialState = {
    isLoading: [],//list of testInstanceIds for which the info is loading
    isRestarting: [],//list of testInstanceIds for which a restart is requested
    byTestbedId: {},    // the rest of the info is kept here by testinstance-id
    byId: {},    // the rest of the info is kept here by testinstance-id
    errors: {},
    timestamps: {},

    statisticsIsLoading: [],
    statisticsById: {}, //will be filled when a restart is requested
    statisticsErrors: {},
    statisticsTimestamps: {},
};


export default function testInstancesReducer(state = initialState, action) {
    switch (action.type) {
        case types.TESTINSTANCES_BY_TESTBED_IS_LOADING:
            if (action.isLoading) {
                return {...state, isLoading: [...state.isLoading, action.testbedId]}
            } else {
                return {...state, isLoading: state.isLoading.filter(id => id !== action.testbedId)}
            }
        case types.LOAD_TESTINSTANCES_BY_TESTBED_SUCCESS:
            return {
                ...state,
                byTestbedId: {
                    ...state.byTestbedId,
                    [action.testbedId]: Object.values(action.result.entities.testInstances)
                },
                byId: {...state.byId, ...action.result.entities.testInstances},
                errors: {...state.errors, [action.testbedId]: null},
                timestamps: {...state.timestamps, [action.testbedId]: action.timestamp}
            };
        case types.LOAD_TESTINSTANCES_BY_TESTBED_ERROR:
            return {
                ...state,
                byTestbedId: {...state.byTestbedId, [action.testbedId]: null},
                errors: {...state.errors, [action.testbedId]: action.error},
                timestamps: {...state.timestamps, [action.testbedId]: action.timestamp}
            };


        case types.TESTINSTANCE_IS_LOADING:
            if (action.isLoading) {
                return {...state, isLoading: [...state.isLoading, action.testInstanceId]}
            } else {
                return {...state, isLoading: state.isLoading.filter(id => id !== action.testInstanceId)}
            }

        case types.LOAD_TESTINSTANCE_SUCCESS:
            return {
                ...state,
                byId: {...state.byId, [action.testInstanceId]: action.result},
                errors: {...state.errors, [action.testInstanceId]: null},
                timestamps: {...state.timestamps, [action.testInstanceId]: action.timestamp}
            };
        case types.LOAD_TESTINSTANCE_ERROR:
            return {
                ...state,
                byId: {...state.byId, [action.testInstanceId]: null},
                errors: {...state.errors, [action.testInstanceId]: action.error},
                timestamps: {...state.timestamps, [action.testInstanceId]: action.timestamp}
            };

        case types.TESTINSTANCE_IS_RESTARTING:
            if (action.isRestarting) {
                return {...state, isRestarting: [...state.isRestarting, action.testInstanceId]}
            } else {
                return {...state, isRestarting: state.isRestarting.filter(id => id !== action.testInstanceId)}
            }

        case types.RESTART_TESTINSTANCE_SUCCESS:
            return {
                ...state,
                statisticsById: {...state.statisticsById, [action.testInstanceId]: action.result},
                statisticsErrors: {...state.statisticsErrors, [action.testInstanceId]: null},
                statisticsTimestamps: {...state.statisticsTimestamps, [action.testInstanceId]: action.timestamp}
            };
        case types.RESTART_TESTINSTANCE_ERROR:
            return {
                ...state,
                statisticsById: {...state.statisticsById, [action.testInstanceId]: null},
                statisticsErrors: {...state.statisticsErrors, [action.testInstanceId]: action.error},
                statisticsTimestamps: {...state.statisticsTimestamps, [action.testInstanceId]: action.timestamp}
            };



        case types.TESTINSTANCESTATISTICS_IS_LOADING:
            if (action.isLoading) {
                return {...state, statisticsIsLoading: [...state.isLoading, action.testInstanceId]}
            } else {
                return {...state, statisticsIsLoading: state.isLoading.filter(id => id !== action.testInstanceId)}
            }

        case types.LOAD_TESTINSTANCESTATISTICS_SUCCESS:
            return {
                ...state,
                statisticsById: {...state.statisticsById, [action.testInstanceId]: action.result},
                statisticsErrors: {...state.errors, [action.testInstanceId]: null},
                statisticsTimestamps: {...state.timestamps, [action.testInstanceId]: action.timestamp}
            };
        case types.LOAD_TESTINSTANCESTATISTICS_ERROR:
            return {
                ...state,
                statisticsById: {...state.statisticsById, [action.testInstanceId]: null},
                statisticsErrors: {...state.errors, [action.testInstanceId]: action.error},
                statisticsTimestamps: {...state.timestamps, [action.testInstanceId]: action.timestamp}
            };


        default:
            return state;
    }
}