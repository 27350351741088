import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';


import moment from 'moment';
import PingGraph from './PingGraph';
import AbstractChart from "./AbstractChart";
import {loadTestResultHistoryIfNeeded} from "../../actions/testResults";


const DATE_FORMAT = 'D MMMM YYYY HH:mm:ss';

class PingChart extends Component {

    componentDidMount() {
        const {dispatch, testInstanceId} = this.props;

        if (testInstanceId) {
            dispatch(loadTestResultHistoryIfNeeded(testInstanceId));
        }
    }

    componentDidUpdate(prevProps) {
        const {dispatch, testInstanceId} = this.props;

        if (testInstanceId && testInstanceId !== prevProps.testInstanceId) {
            dispatch(loadTestResultHistoryIfNeeded(testInstanceId));
        }
    }


    render() {
        const {testResults} = this.props;

        const footerText =
            testResults && testResults.length > 0 ?
                `Showing results of ${testResults.length} tests performed between ${moment(testResults[0].datetime).format(DATE_FORMAT)} and ${moment(testResults[testResults.length - 1].datetime).format(DATE_FORMAT)}`
                : "";

        const pingResults = testResults ? testResults.reverse().map(result => {
            return {
                datetime: result.created,
                pingValue: result.results.pingValue,
                success: result.results.returnValue
            }
        }) : [];


        return (<div>
        <AbstractChart title="Ping" footer={footerText}>
            <PingGraph pingResults={pingResults}/>
        </AbstractChart>
        </div>);


    }
}

PingChart.propTypes = {
    testInstanceId: PropTypes.number
};

function mapStateToProps(state, ownProps) {
    const {testInstanceId} = ownProps;

    let testResults = [];

    if(state.testResults.byTestInstanceId[testInstanceId]){
        testResults = state.testResults.byTestInstanceId[testInstanceId].map(id => state.testResults.byId[id]);
    }

    return {testResults}
}

export default connect(mapStateToProps)(PingChart);