import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Layout, Button, Icon, Popover} from 'antd';
import Exception from 'ant-design-pro/lib/Exception';


export default class InternalError extends Component {

    render() {
        const {error, errorInfo, reset} = this.props;

        return (
            <Layout style={{margin: 24}}>
                <Layout.Content
                    style={{height: "100vh"}}>
                    <Exception
                        type={500}
                        title="500"
                        desc={<div><p>Something went wrong</p>
                            <Popover placement="left" title="Stacktrace" content={errorInfo && errorInfo.componentStack ? (
                                <pre>{errorInfo.componentStack}</pre>) : "Not available"}>
                            <p>
                                <pre>{error.toString()}</pre>
                            </p></Popover>
                        </div>}
                        actions={[(<Button type="primary" onClick={reset}><Icon type="home"/> Return home</Button>)]}/>
                </Layout.Content>
            </Layout>)
    }
}

InternalError.propTypes = {
    error: PropTypes.string.isRequired,
    errorInfo: PropTypes.shape({
        componentStack: PropTypes.string
    }),

    reset: PropTypes.func
};