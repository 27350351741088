import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import countBy from "lodash.countby";
import {Card} from 'antd';

import {Pie, Chart} from 'react-chartjs-2';
import {faFileAlt} from "@fortawesome/fontawesome-free-regular/index";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';


//ant-colors 6
const LABEL_TO_BACKGROUNDCOLOR = {
    'pass': '#52c41a',
    'inconclusive': '#fa8c16', //orange
    'fail': '#fa541c', //volcano
    'error': '#f5222d', //red
    'skipped': '#1890ff'

};

//ant-colors 7
const LABEL_TO_HOVERBACKGROUNDCOLOR = {
    'pass': '#389e0d',
    'inconclusive': '#d46b08',
    'fail': '#d4380d',
    'error': '#cf1322',
    'skipped': '#096dd9'
};

// This function is a copy of generateLabels, with adapted text
// (https://github.com/chartjs/Chart.js/blob/da3aa68f38b9db25156c3b32c56098f9ca70f4d8/src/controllers/controller.doughnut.js#L40)
function generateLabelsWithValue(chart) {
    const data = chart.data;
    if (data.labels.length && data.datasets.length) {
        return data.labels.map(function (label, i) {
            const meta = chart.getDatasetMeta(0);
            const ds = data.datasets[0];
            const arc = meta.data[i];
            const custom = arc && arc.custom ? arc.custom : {};
            const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
            const arcOpts = chart.options.elements.arc;
            const fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
            const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
            const bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

            // We get the value of the current label
            const value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

            return {
                // Instead of `text: label,`
                // We add the value to the string
                text: value + " " + label,
                fillStyle: fill,
                strokeStyle: stroke,
                lineWidth: bw,
                hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                index: i
            };
        });
    } else {
        return [];
    }
}

export default class FInteropTestSuiteSummary extends Component {

    render() {
        const {testresult, detailsUrl} = this.props;
        let content = (<span>No info available</span>);

        if (testresult['tc_results']) {
            //count number of occurences of each state
            const countStates =
                countBy(testresult['tc_results']
                    .map(result => result.verdict)
                    .map(state => state === "None" ? "skipped" : state));

            //transform to format for chartjs
            const labels = [];
            const data = [];

            Object.entries(countStates).forEach(([k, v]) => {
                labels.push(k);
                data.push(v)
            });

            const chartData = {
                labels: labels,
                datasets: [{
                    data: data,
                    backgroundColor: labels.map(label => LABEL_TO_BACKGROUNDCOLOR[label]),
                    hoverBackgroundColor: labels.map(label => LABEL_TO_HOVERBACKGROUNDCOLOR[label]),
                }]
            };

            const chartOptions = {
                legend: {
                    position: 'right',
                    labels: {
                        generateLabels: generateLabelsWithValue
                    }
                }, maintainAspectRatio: false
            };

            content = (<Pie data={chartData} options={chartOptions} height={150}/>);
        }

        return (
            <Card title={testresult.testname} actions={[(<Link to={detailsUrl}><FontAwesomeIcon icon={faFileAlt}/><span  style={{paddingLeft: 6}}> Details</span></Link>)]}>
                <div>
                    {content}
                </div>
            </Card>
        );
    }
}

FInteropTestSuiteSummary.propTypes = {
    testresult: PropTypes.object.isRequired,
    detailsUrl: PropTypes.string.isRequired,
};