import FedmonApi from '../api/fedmonApi'
import * as types from "./actionTypes";


import { normalize } from 'normalizr';
import * as schema from '../api/schema';


const MAX_TESTRESULT_AGE_IN_MS = 1000 * 60; //one minute


export function shouldLoadTestResultHistory(testInstanceId, state) {
    return !state.testResults.byTestInstanceId[testInstanceId]
        || state.testResults.timestamps[testInstanceId] < (Date.now() - MAX_TESTRESULT_AGE_IN_MS)
}

export function loadTestResultHistoryIfNeeded(testInstanceId) {
    return (dispatch, getState) => {
        if (shouldLoadTestResultHistory(testInstanceId, getState())) {
            dispatch(loadTestResultHistory(testInstanceId));
        }
    }
}

export function loadTestResultHistory(testInstanceId) {
    return (dispatch) => {
        dispatch(testResultHistoryIsLoading(true, testInstanceId));

        FedmonApi.getTestInstanceResults(testInstanceId)
            .then(result => {

                const normalizedResult = normalize(result, [schema.result]);
                dispatch(testResultHistoryIsLoading(false, testInstanceId));
                dispatch(fetchTestResultHistorySuccess(normalizedResult, testInstanceId));
            })
            .catch(error => {
                dispatch(testResultHistoryIsLoading(false, testInstanceId));
                dispatch(fetchTestResultHistoryError(error, testInstanceId));
            });
    }
}

export function testResultHistoryIsLoading(isLoading, testInstanceId) {
    return {'type': types.TESTRESULTHISTORY_IS_LOADING, isLoading, testInstanceId}
}

export function fetchTestResultHistorySuccess(result, testInstanceId) {
    return {'type': types.LOAD_TESTRESULTHISTORY_SUCCESS, result, testInstanceId, 'timestamp': Date.now()}
}

export function fetchTestResultHistoryError(error, testInstanceId) {
    return {'type': types.LOAD_TESTRESULTHISTORY_ERROR, error, testInstanceId, 'timestamp': Date.now()}
}


////////////////////////


export function shouldLoadTestResult(testResultId, state) {
    return !state.testResults.isLoading.includes(testResultId) && !state.testResults.byId[testResultId];
}

export function loadTestResultIfNeeded(testResultId) {
    return (dispatch, getState) => {
        if (shouldLoadTestResult(testResultId, getState())) {
            dispatch(loadTestResult(testResultId));
        }
    }
}

export function loadTestResult(testResultId) {
    return (dispatch) => {
        dispatch(testResultIsLoading(true, testResultId));

        FedmonApi.getTestResult(testResultId)
            .then(result => {
                dispatch(testResultIsLoading(false, testResultId));
                dispatch(fetchTestResultSuccess(result, testResultId));
            })
            .catch(error => {
                dispatch(testResultIsLoading(false, testResultId));
                dispatch(fetchTestResultError(error, testResultId));
            });
    }
}

export function testResultIsLoading(isLoading, testResultId) {
    return {'type': types.TESTRESULT_IS_LOADING, isLoading, testResultId}
}

export function fetchTestResultSuccess(result, testResultId) {
    return {'type': types.LOAD_TESTRESULT_SUCCESS, result, testResultId, 'timestamp': Date.now()}
}

export function fetchTestResultError(error, testResultId) {
    return {'type': types.LOAD_TESTRESULT_ERROR, error, testResultId, 'timestamp': Date.now()}
}
