import FedmonApi from '../api/fedmonApi'
import * as types from "./actionTypes";


const MAX_RRD_AGE_IN_MS = 1000 * 60; //one hour

export function loadRrd(rrdId) {
    return (dispatch) => {
        dispatch(rrdIsLoading(true, rrdId));

        FedmonApi.getRrdData(rrdId)
            .then(result => {
                dispatch(rrdIsLoading(false, rrdId));
                dispatch(fetchRrdSuccess(result, rrdId));
            })
            .catch(error => {
                dispatch(rrdIsLoading(false, rrdId));
                dispatch(fetchRrdError(error, rrdId));
            });
    }
}

export function shouldLoadRrd(rrdId, state) {
    return !state.rrd.byId[rrdId]
        || (Date.now() - state.rrd.timestamps[rrdId]) > MAX_RRD_AGE_IN_MS;
}

export function loadRrdIfNeeded(rrdId) {
    return (dispatch, getState) => {
        if (shouldLoadRrd(rrdId, getState())) {
            dispatch(loadRrd(rrdId));
        }
    }
}

export function rrdIsLoading(isLoading, rrdId) {
    return {'type': types.RRD_IS_LOADING, isLoading, rrdId}
}

export function fetchRrdSuccess(result, rrdId) {
    return {'type': types.LOAD_RRD_SUCCESS, result, rrdId, 'timestamp': Date.now()}
}

export function fetchRrdError(error, rrdId) {
    return {'type': types.LOAD_RRD_ERROR, error, rrdId, 'timestamp': Date.now()}
}