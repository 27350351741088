import FedmonApi from '../api/fedmonApi'
import * as types from "./actionTypes";

const SELFTEST_TESTINSTANCE_ID = "365";
export const SELFTEST_MS_BETWEEN_CHECKS = 30*1000;


export function loadSelfTestHistory() {
    return (dispatch) => {
        dispatch(selfTestIsLoading(true));

        FedmonApi.getLastTestInstanceResult(SELFTEST_TESTINSTANCE_ID)
            .then(result => {
                dispatch(selfTestIsLoading(false));
                dispatch(fetchSelfTestHistorySuccess(result));
            })
            .catch(error => {
                dispatch(selfTestIsLoading(false));
                dispatch(fetchSelfTestHistoryError(error));
            });
    }
}

export function selfTestIsLoading(isLoading) {
    return {'type': types.SELFTESTHISTORY_IS_LOADING, isLoading}
}

export function fetchSelfTestHistorySuccess(results) {
    return {'type': types.LOAD_SELFTESTHISTORY_SUCCESS, results, 'timestamp': Date.now()}
}

export function fetchSelfTestHistoryError(error) {
    return {'type': types.LOAD_SELFTESTHISTORY_ERROR, error, 'timestamp': Date.now()}
}