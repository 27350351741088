import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';

import { connectRouter, routerMiddleware } from 'connected-react-router'

export default function configureStore(history) {

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(
        connectRouter(history)(rootReducer), // new root reducer with router state
        composeEnhancers(
            applyMiddleware(thunk),
            applyMiddleware(routerMiddleware(history)), // for dispatching history actions
        )
    );
}