import * as types from "../actions/actionTypes";

const initialState = {
    isLoading: [],//list of rrdIds for which the info is loading
    byId: {},    // the rest of the info is kept here by rrd-id
    errors: {},
    timestamps: {}
};


export default function rrdReducer(state = initialState, action) {
    switch (action.type) {
        case types.RRD_IS_LOADING:
            if (action.isLoading) {
                return {...state, isLoading: [...state.isLoading, action.rrdId]}
            } else {
                return {...state, isLoading: state.isLoading.filter(id => id !== action.rrdId)}
            }
        case types.LOAD_RRD_SUCCESS:
            return {
                ...state,
                byId: {...state.byId, [action.rrdId]: action.result},
                errors: {...state.errors, [action.rrdId]: null},
                timestamps: {...state.timestamps, [action.rrdId]: action.timestamp}
            };
        case types.LOAD_RRD_ERROR:
            return {
                ...state,
                byId: {...state.byId, [action.rrdId]: null},
                errors: {...state.errors, [action.rrdId]: action.error},
                timestamps: {...state.timestamps, [action.rrdId]: action.timestamp}
            };
        default:
            return state;
    }
}