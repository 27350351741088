import React, {Component} from "react";
import PropTypes from "prop-types";
import PageHeader from 'ant-design-pro/lib/PageHeader';

export class Page extends Component {


    render() {
        const {title, subtitle, image, children, breadcrumbs, action, extraContent, tabList, tabActiveKey, onTabChange, headerClassName} = this.props;

        return (
            <div>
                <PageHeader
                    className={`tabs ${headerClassName}`}
                    breadcrumbList={breadcrumbs}
                    title={<div className="title">{title}</div>}
                    logo={<div className="logo">{image}</div>}
                    action={<div className="action">{action}</div>}
                    content={<div className="content">{subtitle}</div>}
                    extraContent={<div className="extraContent">{extraContent}</div>}
                    tabList={tabList}
                    tabActiveKey={tabActiveKey}
                    onTabChange = {onTabChange}/>

                {children}
            </div>);
    }
}

Page.propTypes = {
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    image: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    breadcrumbs: PropTypes.array,
    action: PropTypes.node,
    extraContent: PropTypes.node,
    tabList: PropTypes.array,
    tabActiveKey: PropTypes.string,
    headerClassName: PropTypes.string,
};