import React, {Component} from "react";
import {Layout, Button, Icon} from 'antd';
import Exception from 'ant-design-pro/lib/Exception';


export default class NotFound extends Component {

    render() {
        return (
            <Layout style={{margin: 24}}>
                <Layout.Content>
                    <Exception
                        type={404}
                        title="Page not found"
                        desc="The page that you requested is not found"
                        actions={[(<Button type="primary" href="/"><Icon type="home"/> Return home</Button>)]}/>
                </Layout.Content>
            </Layout>)
    }
}