import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card, Collapse, Divider} from 'antd';
import FedmonUtils from '../../../utils'
import './CallInfo.css'
import {TimeWithTooltip} from "../Logs/TimeWithTooltip";
import LogLinesTable from "../Logs/LogLinesTable";
import APICall from "./APICall";

export class CallStateHeader extends Component {
    render() {
        const {callState} = this.props;
        const {name, statestate} = callState;


        return (
            <span><span style={{marginRight: 8}}>{FedmonUtils.getFontAwesomeFromStatus(statestate)}</span> {name}</span>
        );
    }
}

CallStateHeader.propTypes = {
    method: PropTypes.object.isRequired,
};


export class CallStateContent extends Component {


    renderAPICalls() {
        const {callState} = this.props;

        const calls = callState.calls ? (callState.calls.call instanceof Array ? callState.calls.call : [callState.calls.call]) : [];

        if (calls.length === 0) {
            return (<div>No calls</div>);
        }

        return (<Collapse>
            {calls.map(call => (
                <Collapse.Panel header={<span><samp>{call.geni_method_name}</samp>{call.authority_name ? (<span> to <em>{call.authority_name}</em></span>): null}</span>}>
                    <APICall call={call}/>
                </Collapse.Panel>))}
        </Collapse>);
    }

    renderLogLines() {
        const {callState} = this.props;

        const lines = callState.logentries ? (callState.logentries.logentry instanceof Array ? callState.logentries.logentry : [callState.logentries.logentry]) : [];

        return (<LogLinesTable logLines={lines}/>)
    }

    render() {
        const {callState} = this.props;
        const {start, end} = callState;

        return (
            <Card title={<CallStateHeader callState={callState}/>} style={{marginBottom: 24}}>
                <p>Ran from <TimeWithTooltip
                    time={start}/> until <TimeWithTooltip time={end}/></p>

                <Divider orientation="left">API Calls</Divider>
                {this.renderAPICalls()}
                <Divider orientation="left">Logs</Divider>
                {this.renderLogLines()}

            </Card>
        );
    }
}

CallStateContent.propTypes = {
    method: PropTypes.object.isRequired,
    showLogTimestamp: PropTypes.bool,
};
