import FedmonApi from '../api/fedmonApi'
import * as types from "./actionTypes";

import {normalize} from 'normalizr';
import * as schema from '../api/schema';


export const TESTRESULT_MS_BETWEEN_UPDATES = 1000*15;

export function subscribeToResultUpdates() {
    return {
        type: types.SUBSCRIBE_TO_LATEST_RESULTS
    }
}

export function unsubscribeToResultUpdates() {
    return {
        type: types.UNSUBSCRIBE_TO_LATEST_RESULTS
    }
}


export function loadLatestResults() {
    return loadLatestResultsByCategories([]);
}

export function loadLatestResultsByTestbed(testbedId) {
    return (dispatch, getState) => {
        dispatch(latestResultsIsLoading(true));


        FedmonApi.getLatestResultsByTestbeds([testbedId])
            .then(result => {
                dispatch(latestResultsIsLoading(false));

                const normalizedResult = normalize(result, [schema.result]);
                dispatch(fetchLatestResultsSuccess(normalizedResult));
                dispatch(updateDerivedInfoLatestResults(normalizedResult, getState().coreInfo.testbeds));
            })
            .catch(error => {
                dispatch(latestResultsIsLoading(false));
                dispatch(fetchLatestResultsByTestbedsError(error, [testbedId]));
            });
    }
}

const MAX_LATESTRESULTS_AGE_IN_MS = 1000 * 30;

function latestResultsByTestbedShouldUpdate(testbedId, state) {
    return !state.latestResults.isLoading.includes(testbedId) && (
        !state.latestResults.byTestbedId[testbedId]
        || state.latestResults.timestamps[testbedId] < (Date.now() - MAX_LATESTRESULTS_AGE_IN_MS))
}

export function loadLatestResultsByTestbedIfNeeded(testbedId) {
    return (dispatch, getState) => {
        if (latestResultsByTestbedShouldUpdate(testbedId, getState())) {
            dispatch(loadLatestResultsByTestbed(testbedId))
        }
    }
}


export function loadLatestResultsByCategories(categories) {
    return (dispatch, getState) => {
        dispatch(latestResultsIsLoading(true));


        FedmonApi.getLatestResultsByTestbedCategories(categories)
            .then(result => {
                dispatch(latestResultsIsLoading(false));

                const normalizedResult = normalize(result, [schema.result]);
                dispatch(fetchLatestResultsSuccess(normalizedResult, categories));
                dispatch(updateDerivedInfoLatestResults(normalizedResult, getState().coreInfo.testbeds));
            })
            .catch(error => {
                dispatch(latestResultsIsLoading(false));
                dispatch(fetchLatestResultsByCategoriesError(error, categories));
            });
    }
}

export function latestResultsIsLoading(isLoading) {
    return {'type': types.LATEST_RESULTS_IS_LOADING, isLoading}
}

export function fetchLatestResultsSuccess(results, categories = null) {
    return {
        'type': types.LOAD_LATEST_RESULTS_SUCCESS,
        results,
        categories,
        'timestamp': Date.now(),
    }
}

export function updateDerivedInfoLatestResults(results, testbeds) {
    return {
        'type': types.UPDATE_DIREVED_INFO_LATEST_RESULTS,
        results,
        testbeds,
    }
}

export function fetchLatestResultsByCategoriesError(error, categories) {
    return {
        'type': types.LOAD_LATEST_RESULTS_ERROR,
        error,
        categories,
        'timestamp': Date.now()
    }
}

export function fetchLatestResultsByTestbedsError(error, testbedIds) {
    return {
        'type': types.LOAD_LATEST_RESULTS_ERROR,
        error,
        testbedIds,
        'timestamp': Date.now()
    }
}