import {connect} from 'react-redux';

import TestbedTile from './TestbedTile';
import FedmonUtils from "../../utils";


function mapStateToProps(state, ownProps) {
    const {testbedId} = ownProps;

    return {
        testbedName: state.coreInfo.testbeds.byId[testbedId] ? state.coreInfo.testbeds.byId[testbedId].longName : "Unknown",
        testResultsSummary: state.latestResultsSummary[FedmonUtils.getIdFromUrlString(testbedId)]
    }
}

export default connect(mapStateToProps)(TestbedTile);