import React, {PureComponent} from 'react';
import {withRouter} from "react-router";
import {connect} from 'react-redux';
import isEqual from 'lodash.isequal';
import {Layout, Switch, Menu, Affix} from 'antd';
import {Element, scroller} from 'react-scroll'

import {subscribeToResultUpdates, unsubscribeToResultUpdates} from '../../actions/latestTestResults';
import {setEnabledCategories} from "../../actions/selectableCategories";

import TilesCategory from '../../components/TilesCategory';
import SelfTestWarning from "../../components/SelfTest/SelfTestWarning";
import {getSortedCategories, getRequestedCategoryIds} from '../../selectors/selectableCategories';

const {Content, Sider, Footer} = Layout;

class Overview extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            enabled_category_ids: props.requested_category_ids,
            siderCollapsed: null,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps.requested_category_ids, this.props.requested_category_ids)) {
            //specific categories were requested by the url, reflect this in the state!
            this.setState({
                enabled_category_ids: nextProps.requested_category_ids
            });

        }
    }

    componentWillMount() {
        const {dispatch} = this.props;
        dispatch(subscribeToResultUpdates());
    }

    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(unsubscribeToResultUpdates());
    }

    render() {

        const {categories, history, dispatch} = this.props;
        const {enabled_category_ids, siderCollapsed} = this.state;

        const setCategoryEnabled = (id, enabled) => {

            let new_enabled_ids = enabled ?
                [...enabled_category_ids, id] :
                enabled_category_ids.filter(enabled_id => id !== enabled_id);

            new_enabled_ids.sort((id1, id2) => {
                const idx1 = categories.findIndex(cat => cat.shortId === id1);
                const idx2 = categories.findIndex(cat => cat.shortId === id2);

                if (idx1 > -1 && idx2 > -1)
                    return idx1 - idx2;
                else if (idx1 > -1) { //these cases shouldn't happen, but test for them anyway
                    return 1;
                } else if (idx2 > -1) {
                    return -1;
                } else {
                    return id1.localeCompare(id2);
                }
            });

            dispatch(setEnabledCategories(new_enabled_ids));

            if (new_enabled_ids.length !== categories.length) {
                history.push(`/overview/${new_enabled_ids.join(',')}`);
            } else {
                history.push('/overview/');
            }
        };

        const onSiderBreakPoint = (broken) => {
            this.setState({siderCollapsed: broken});
        };
        const onMenuClick = (event) => {
            scroller.scrollTo(event.key);
        };

        return (
            <Layout>
                <Sider theme="light" collapsible={true} collapsedWidth={0} width={280}
                       breakpoint="md" trigger={siderCollapsed ? undefined : null} onBreakpoint={onSiderBreakPoint}>
                    <Affix offsetTop={0}>
                        <Menu mode="inline" className="overview-menu" onClick={onMenuClick}>
                            {categories.map(cat =>
                                (<Menu.Item key={cat.shortId} disabled={!enabled_category_ids.includes(cat.shortId)}>
                                    <div className="overview-menuitem">
                                        <div className="overview-menuitem-name">{cat.name}</div>
                                        <Switch key={cat.id}
                                                className="overview-menuitem-switch"
                                                checked={enabled_category_ids.includes(cat.shortId)}
                                                size="small"
                                                disabled={enabled_category_ids.includes(cat.shortId) && enabled_category_ids.length === 1}
                                                onChange={(checked) => setCategoryEnabled(cat.shortId, checked)}/></div>
                                </Menu.Item>))}
                        </Menu>
                    </Affix>
                </Sider>
                <Content>
                    <Layout>
                        <Content>
                            <SelfTestWarning/>
                            {
                                categories
                                    .filter(cat => enabled_category_ids.includes(cat.shortId))
                                    .map(cat => (<Element key={cat.shortId} name={cat.shortId}>
                                        <div className="category-wrapper">
                                            <TilesCategory key={cat.id} categoryShortId={cat.shortId}/>
                                        </div>
                                    </Element>))

                            }
                        </Content>
                        <Footer>&copy; imec</Footer>
                    </Layout>
                </Content>

            </Layout>);
    }
}


function mapStateToProps(state, ownProps) {
    return {
        categories: getSortedCategories(state),
        requested_category_ids: getRequestedCategoryIds(state, ownProps)
    }
}

export default withRouter(connect(mapStateToProps)(Overview));