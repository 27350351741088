import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon, Popover, Switch, Tabs, Divider} from "antd";
import XmlBeautify from "xml-beautify";
import ReactJson from "react-json-view";

import './ApiCallNameValue.css'
import './CallContent.css'

class APICallNameValue extends Component {
    render() {
        const {name, value} = this.props;
        return (
            <div className="call-info">
                <div className="call-info-name">{name}</div>
                <div className="call-info-value">{value}</div>
            </div>
        );
    }
}

APICallNameValue.propTypes = {
    name: PropTypes.node.isRequired,
    value: PropTypes.node.isRequired,
};

class CallContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prettify: props.defaultPrettify || false,
        }
    }

    render() {
        const {title, showPrettify} = this.props;
        const {prettify} = this.state;

        let {content} = this.props;

        if (content && prettify) {
            if (content.substr(0, 5) === "<?xml") {
                content = new XmlBeautify().beautify(content, {
                    indent: "  ",  //indent pattern like white spaces
                    useSelfClosingElement: true //true:use self-closing element when empty element.
                });
            } else {
                content = (<ReactJson src={JSON.parse(content)}/>)
            }
        }

        return (
            <div><h4>{title} {content && showPrettify ? (
                <span className="call-prettify"><Switch checkedChildren={<Icon type="check"/>}
                                                        unCheckedChildren={<Icon type="cross"/>}
                                                        checked={prettify} size="small"
                                                        onChange={checked => this.setState({prettify: checked})}/> Prettify</span>) : null}</h4>

                <pre className={`call-content ${!content ? "call-content-empty" : ""}`}>{content}</pre>
            </div>
        );
    }
}

CallContent.propTypes = {
    title: PropTypes.node.isRequired,
    content: PropTypes.string.isRequired,
    showPrettify: PropTypes.bool,
    defaultPrettify: PropTypes.bool,
}

class APICall extends Component {

    render() {
        const {call} = this.props;
        const {
            authority_name, authority_urn, base_server_url, server_url,
            connection_user_urn, connection_user_certs, connection_http_basic_authentication_username,
            connection_config_authentication_method, connection_config_protocol, connection_config_type,
            http_request_line, http_request_headers, http_request,
            http_status_line, http_response_headers, http_reply,
            api_name, java_method_name, geni_method_name,
            xmlrpc_request, xmlrpc_reply,
            geni_value, /* geni_output, geni_code,*/
            geni_response_code, geni_response_code_description, /* geni_code_is_success,*/ geni_response_output,
            start_time, end_time, protogeni_spew_log_url
        } = call;

        const connection_authentication =
            (<span>{connection_config_authentication_method}
                {connection_user_certs ?
                    (<Popover title="User certificate"
                              content={<pre>{connection_user_certs}</pre>}
                              placement="right">
                        <Icon type="info-circle-o" style={{marginLeft: 8}}/>
                    </Popover>) : null}

                {connection_http_basic_authentication_username ?
                    (<Popover title="Authentication username"
                              content={<pre>{connection_http_basic_authentication_username}</pre>}
                              placement="right">
                        <Icon type="info-circle-o" style={{marginLeft: 8}}/>
                    </Popover>) : null}
            </span>);

        return (
            <Tabs defaultActiveKey="call-info">
                <Tabs.TabPane tab="General Info" key="call-info">
                    <div className="call-infos">
                        <APICallNameValue name={"API Name"} value={api_name}/>
                        <APICallNameValue name={"Method Name"} value={geni_method_name}/>
                        <APICallNameValue name={"Java Method Name"} value={java_method_name}/>
                        <Divider/>
                        <APICallNameValue name={"Start Time"} value={start_time}/>
                        <APICallNameValue name={"End Time"} value={end_time}/>
                        <Divider/>
                        <APICallNameValue name={"Authority name"} value={authority_name}/>
                        <APICallNameValue name={"Authority URN"} value={authority_urn}/>
                        {base_server_url ? (
                            <APICallNameValue name={"Base Server URL"} value={base_server_url}/>) : null}
                        <APICallNameValue name={"Server URL"} value={server_url}/>
                        <APICallNameValue name={"User URN"} value={connection_user_urn}/>
                        <Divider/>
                        <APICallNameValue name={"Connection Authentication"} value={connection_authentication}/>
                        <APICallNameValue name={"Connection Protocol"} value={connection_config_protocol}/>
                        <APICallNameValue name={"Connection Type"} value={connection_config_type}/>
                    </div>

                </Tabs.TabPane>
                <Tabs.TabPane tab="Request" key="request">
                    <Tabs defaultActiveKey="http-request" tabPosition="left">
                        <Tabs.TabPane tab="HTTP Request" key="http-request">
                            <CallContent title="Request Line" content={http_request_line}/>
                            <CallContent title="Headers" content={http_request_headers}/>
                            <CallContent title="Content" content={http_request} showPrettify={true}/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="XML-RPC Request" key="xmlrpc-request">
                            <CallContent title="Content" content={xmlrpc_request} showPrettify={true}/>
                        </Tabs.TabPane>
                    </Tabs>
                </Tabs.TabPane>


                <Tabs.TabPane tab="Response" key="response">
                    <Tabs defaultActiveKey="http-reply" tabPosition="left">
                        <Tabs.TabPane tab="HTTP Reply" key="http-reply">
                            <CallContent title="Status Line" content={http_status_line}/>
                            <CallContent title="Headers" content={http_response_headers}/>
                            <CallContent title="Content" content={http_reply} showPrettify={true}/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="XML-RPC Reply" key="xmlrpc-reply">
                            <CallContent title="Content" content={xmlrpc_reply} showPrettify={true}/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Geni Reply" key="geni-reply">
                            <CallContent title="Code" content={geni_response_code}/>
                            <div className="geni-response-description">{geni_response_code_description}</div>
                            <CallContent title="Output" content={geni_response_output}/>
                            <CallContent title="Value" content={geni_value} showPrettify={true}/>
                        </Tabs.TabPane>
                        {protogeni_spew_log_url ?
                            <Tabs.TabPane tab="Server-side logs" key="protogeni log">
                                <iframe title="Server-side Logs" src={protogeni_spew_log_url}
                                        style={{width: "100%", height: 480}}/>
                            </Tabs.TabPane>
                            : null}
                    </Tabs></Tabs.TabPane>
            </Tabs>);
    }
}

APICall.propTypes = {
    call: PropTypes.object.isRequired
};

export default APICall;