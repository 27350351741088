import React, {Component} from 'react';
import {Button, Icon, Layout, Table, Tag, Tooltip, message, Popconfirm, /* Col, Row, Card,*/ Switch} from 'antd';
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {loadLatestResultsByTestbedAndTestDefinition} from "../../actions/testDefinitionResults";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import './WiLab.css';
import {faVial} from "@fortawesome/fontawesome-free-solid";
import PropTypes from "prop-types";
import {Page} from "../../components/Page";
import moment from "moment";
import FedmonUtils from "../../utils";
import {restartTestInstance} from "../../actions/testInstances";
// import NumberInfo from "ant-design-pro/lib/NumberInfo";

const {Content} = Layout;

const WILAB_TESTBED_ID = "wilab1";
const WILAB_TESTDEFINITION = "autosensortest";


class SummaryTag extends Component {

    static SUMMARY_COLORS = {
        success: 'green',
        warning: 'orange',
        failure: 'volcano',
        info: 'blue'
    };

    render() {
        const {summary} = this.props;

        const summaryName = summary ? summary : "INFO";
        const summaryColor = SummaryTag.SUMMARY_COLORS[summaryName.toLowerCase()];

        return (<Tag color={summaryColor}>{summaryName} </Tag>);
    }
}

SummaryTag.propTypes = {
    summary: PropTypes.string
};

export class TimeAgoWithTooltip extends Component {
    render() {
        const {time, tooltipFormat} = this.props;

        return (<Tooltip
            title={tooltipFormat ? moment(time).format(tooltipFormat) : moment(time).toISOString()}>{moment(time).fromNow()}</Tooltip>);
    }
}

TimeAgoWithTooltip.propTypes = {
    time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    tooltipFormat: PropTypes.string,
};

class WiLab extends Component {
    constructor(props) {
        super(props);

        this.restartingMessages = {};

        this.state = {
            showYepkitId: true,
            showDeviceId: true,
            showTemperature: true,
            showDeviceName: true,
            showDate: true,
        }
    }

    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(loadLatestResultsByTestbedAndTestDefinition(WILAB_TESTBED_ID, WILAB_TESTDEFINITION));
    }

    componentDidUpdate(prevProps) {
        this.props.restarting.forEach(restartingId => {
            if (!prevProps.restarting.includes(restartingId)) {
                //show restarting message
                this.restartingMessages[restartingId] = message.loading(`Restarting test for ${restartingId}`, 0);
            }
        });

        prevProps.restarting.forEach(restartingId => {
            if (!this.props.restarting.includes(restartingId)) {
                //hide restarting message
                const hideMessage = this.restartingMessages[restartingId];
                this.restartingMessages[restartingId] = undefined;

                hideMessage();

                //check if restart was successful

                //find resultId
                const record = this.props.dataset.filter(record => record.nodename === restartingId);

                if (this.props.restarted[record.testInstanceId]) {
                    message.success(`Restarted test for ${restartingId}`);
                } else {
                    message.error(`Could not restart test for ${restartingId}`)
                }
            }
        });

    }

    restartTest(record) {
        const {dispatch} = this.props;

        dispatch(restartTestInstance(record.testInstanceId));
    }

    renderExpandedRow(record) {
        const {restarting} = this.props;

        const isRecordRestarting = restarting.includes(record.nodename);

        return (<div>
            <div>
                <Button.Group>
                    <Button href={`/result/${record.resultId}`}><Icon type="area-chart"/> Test details</Button>
                    <Button href={`/result/${record.resultId}/steps`}><Icon type="bars"/> Test steps</Button>
                    <Button href={`/result/${record.resultId}/ansible`}>
                        <img alt="" src="/assets/icons/ansible.svg"
                             style={{height: 16, width: 16, verticalAlign: "text-bottom", marginRight: 8}}/> Ansible
                        output</Button>

                </Button.Group>
                {/*</div>*/}
                {/*<div>*/}
                <Button.Group style={{marginLeft: 24, marginRight: 24}}>
                    <Button href={`/history/${record.testInstanceId}`}><Icon type="calendar"/>Node Test
                        history</Button></Button.Group>
                {/*</div>*/}
                {/*<div>*/}

                <Button.Group>
                    <Popconfirm title="Are you sure that you want to restart this test?"
                                onConfirm={() => this.restartTest(record)} okText="Yes" cancelText="No">
                        <Button type="danger" icon="reload" loading={isRecordRestarting}>Restart</Button>
                    </Popconfirm></Button.Group>
            </div>
            <div style={{marginTop: 24}}>
                <h5>Extra data</h5>
                <span className="extra-data">{record.info.join(', ')}</span>
            </div>
        </div>);

    }

    render() {
        const {dataset} = this.props;
        const {showYepkitId, showDeviceName, showDeviceId, showTemperature, showDate} = this.state;

        const nodename_filters = [...new Set(dataset.map(record => record.nodename.split('-')[0]))].sort().map(key => {
            return {text: `${key}-*`, value: key}
        });
        const summary_filters = [...new Set(dataset.map(record => record.summary))].map(key => {
            return {text: key, value: key}
        });


        const columns = [{
            title: "Node name",
            dataIndex: 'nodename',
            key: 'nodename',
            filters: nodename_filters,
            onFilter: (activePrefixes, record) => activePrefixes.includes(record.nodename.split('-')[0]),
            sorter: (a, b) => ('' + a.nodename).localeCompare(b.nodename),
        }, {
            title: "Result",
            dataIndex: 'summary',
            key: 'summary',
            filters: summary_filters,
            onFilter: (activeSummaries, record) => activeSummaries.includes(record.summary),
            render: (text, record) => (<Link to={`/result/${record.resultId}`}>{text}</Link>)
        }];
        if (showYepkitId) {
            columns.push({
                title: "YepkitID",
                dataIndex: 'yepkitId',
                key: 'yepkitId',
                sorter: (a, b) => ('' + a.yepkitId).localCompare(b.yepkitId),
            });
        }
        if (showDeviceId) {
            columns.push({
                title: "Sensor device",
                dataIndex: 'dev',
                key: 'dev'
            });
        }
        if (showDeviceName) {
            columns.push({
                title: "Sensor name",
                dataIndex: 'devName',
                key: 'devName',
                sorter: (a, b) => ('' + a.devName).localCompare(b.devName),
            });
        }
        if (showTemperature) {
            columns.push({
                title: "Temperature",
                dataIndex: 'temperature',
                key: 'temperature',
                sorter: (a, b) => a.temperature - b.temperature,
                render: (text) => {
                    let number;
                    if ((number = Number(text))) {
                        number /= 10;
                        return <span>{number.toFixed(1)}&deg;C</span>
                    } else {
                        return text;
                    }
                }
            });
        }
        if (showDate) {
            columns.push({
                title: "Date",
                dataIndex: 'createdDate',
                key: 'createdDate',
                sorter: (a, b) => (moment(a.createdDate) < moment(b.createdDate)) ? 1 : -1,
                render: (text) => <TimeAgoWithTooltip tooltipFormat="LLLL" time={text}/>
            });
        }

        const actions = (<Button.Group>
            <Button
                href="https://flsmonitor-api.fed4fire.eu/result?last=&testbed=wilab1&testdefinitionname=autosensortest&enabled=true"
                icon="file-text">Raw Data as JSON</Button>
            <Button
                href="https://flsmonitor-api.fed4fire.eu/result/results/extract/nodeinfo?last=&testbed=wilab1&testdefinitionname=autosensortest&enabled=true"
                icon="table">Raw Data as CSV</Button>
        </Button.Group>);

        let extraContent = null;

        if (dataset.length > 0) {
            const successCount = dataset.filter(record => record.summary === "SUCCESS").length;
            const successPercentage = (100 * successCount) / dataset.length;
            const warningCount = dataset.filter(record => record.summary === "WARNING").length;
            const warningPercentage = (100 * warningCount) / dataset.length;
            const failureCount = dataset.filter(record => record.summary === "FAILURE").length;
            const failurePercentage = (100 * failureCount) / dataset.length;

            extraContent = (<div style={{height: "2em", minWidth: 350, display: "flex", alignItems: "center"}}>
                {successCount > 0 ?
                    <Tooltip title={`${successCount} nodes have status 'Success'`} placement="bottom">
                        <div className="wilab-chart wilab-chart-success"
                             style={{width: `${successPercentage}%`}}>{successCount}</div>
                    </Tooltip> : null}
                {warningCount > 0 ?
                    <Tooltip title={`${warningCount} nodes have status 'Warning'`} placement="bottom">
                        <div className="wilab-chart wilab-chart-warning"
                             style={{width: `${warningPercentage}%`}}>{warningCount}</div>
                    </Tooltip> : null}
                {failureCount > 0 ?
                    <Tooltip title={`${failureCount} nodes have status 'Failure'`} placement="bottom">
                        <div className="wilab-chart wilab-chart-failure"
                             style={{width: `${failurePercentage}%`}}>{failureCount}</div>
                    </Tooltip> : null}
            </div>);
        }


        return (<Page title="imec w-iLab.t continuous testing"
                      image={<FontAwesomeIcon icon={faVial} style={{height: 72, width: 72}}/>}
                      subtitle="Weekly tests are performed on all imec WiLab1 nodes to ensure their correct functioning"
                      action={actions} extraContent={extraContent}>

            {/*{dataset.length > 0 ? (*/}
            {/*<Row gutter={24} style={{margin: 24}}>*/}
            {/*<Col md={8} sm={12} xs={24}>*/}
            {/*<Card>*/}
            {/*<NumberInfo subTitle="Successful nodes"*/}
            {/*total={dataset.filter(record => record.summary === "SUCCESS").length}/>*/}
            {/*</Card>*/}
            {/*</Col> <Col md={8} sm={12} xs={24}>*/}
            {/*<Card>*/}
            {/*<NumberInfo subTitle="Nodes with warning"*/}
            {/*total={dataset.filter(record => record.summary === "WARNING").length}/>*/}
            {/*</Card>*/}
            {/*</Col> <Col md={8} sm={12} xs={24}>*/}
            {/*<Card>*/}
            {/*<NumberInfo subTitle="Failing nodes"*/}
            {/*total={dataset.filter(record => record.summary === "FAILURE").length}/>*/}
            {/*</Card>*/}
            {/*</Col>*/}
            {/*</Row>) : null}*/}

            <Content className="content-wrapper">
                <div style={{marginBottom: 16, display: 'flex'}}>
                    <span style={{flex: '1 1 auto', textAlign: 'right', fontWeight: 'bold'}}>Show:</span>
                    <span style={{marginLeft: 16}}>
                        <Switch checked={showYepkitId}
                                onChange={(checked) => {
                                    this.setState({showYepkitId: checked});
                                }}/> YepkitID</span>
                    <span style={{marginLeft: 16}}>
                        <Switch checked={showDeviceName}
                                onChange={(checked) => {
                                    this.setState({showDeviceName: checked});
                                }}/> Sensor Device</span>
                    <span style={{marginLeft: 16}}>
                        <Switch checked={showDeviceId}
                                onChange={(checked) => {
                                    this.setState({showDeviceId: checked});
                                }}/> Sensor ID</span>
                    <span style={{marginLeft: 16}}>
                        <Switch checked={showTemperature}
                                onChange={(checked) => {
                                    this.setState({showTemperature: checked});
                                }}/> Temperature</span>
                    <span style={{marginLeft: 16}}>
                        <Switch checked={showDate}
                                onChange={(checked) => {
                                    this.setState({showDate: checked});
                                }}/>Date</span>
                </div>
                <Table columns={columns} dataSource={dataset} loading={dataset.length === 0}
                       rowClassName={(record) => `wilab-summary-${record.summary.toLowerCase()}`}
                       expandedRowRender={this.renderExpandedRow.bind(this)}
                       pagination={false}/>
            </Content>

        </Page>);
    }
}

function

mapStateToProps(state, ownProps) {
    const testbedResults = state.testDefinitionResults.byId[WILAB_TESTBED_ID] || {};
    const testDefinitionInTestbedResults = testbedResults[WILAB_TESTDEFINITION] || {};

    const latestResults = Object.values(testDefinitionInTestbedResults);

    latestResults.sort(function (a, b) {
        const a_parts = (a.results.fixed_node_name || "").split('-');
        const b_parts = (b.results.fixed_node_name || "").split('-');

        if (a_parts.length > 1)
            a_parts[1] = Number(a_parts[1]);
        if (b_parts.length > 1)
            b_parts[1] = Number(b_parts[1]);

        if (a_parts.length !== b_parts.length) {
            return a_parts.length - b_parts.length;
        }
        for (let i = 0; i < a_parts.length; i++) {
            if (a_parts[i] < b_parts[i])
                return -1;
            else if (b_parts[i] < a_parts[i])
                return 1;
        }
        return 0;
    });

    const dataset = latestResults.map(result => {
        let nodename = null;
        let info = null;
        let {controlIFace, controlIp, hostname, yepkitId, dev, devName, temperature, exp, date} = {};

        if (result.results) {
            nodename = result.results.fixed_node_name;
            if (result.results.extract && result.results.extract.nodeinfo) {
                info = result.results.extract.nodeinfo.split(',');
                info = info.map(s => s.trim());
                [controlIFace, controlIp, hostname, yepkitId, dev, devName, temperature, exp, date] = info;
            }
        }


        return {
            key: nodename,
            resultId: result.id,
            testInstanceId: FedmonUtils.getId(result.testInstance),
            summary: result.summary,
            createdDate: result.created,
            nodename,
            controlIFace, controlIp, hostname, yepkitId, dev, devName, temperature, exp, date,
            info,
            result,
        }
    });

    const restarting =
        dataset.filter(record => {
            return state.testInstances.isRestarting.includes(record.testInstanceId)
        }).map(record => record.nodename);

    return {
        latestResults,
        dataset,
        restarting,
        restarted: state.testInstances.statisticsById
    }
}

export default withRouter(connect(mapStateToProps)(WiLab));