import React, {Component} from 'react';
import {connect} from 'react-redux';
import './SelfTest.css'
import {Link} from 'react-router-dom';
import {PropTypes} from 'prop-types';

class SelfTestStatus extends Component {

    render() {
        const {results, error} = this.props;

        let summaryStatus = "error";
        let summaryText = "";
        let linkUrl = "/history/365";
        if (error) {
            summaryStatus = "error";
            summaryText = "Could not fetch Federation monitor status"
        } else if (results && results.length > 0) {
            const lastResult = results[0];
            linkUrl = "/result/".concat(lastResult.id);
            summaryStatus = lastResult.summary && lastResult.summary.toLowerCase();

            if (summaryStatus === "success") {
                summaryText = "Healthy"
            } else if (summaryStatus === "warning") {
                summaryText = "Has issues"
            } else if (summaryStatus === "error" || summaryStatus === "failure") {
                summaryText = "Failing"
            }
        } else {
            summaryStatus = "unavailable";
            summaryText = "No status available";
        }

        return (
            <Link to={linkUrl}>
                <div className={`selftest-header-status ${summaryStatus}`}>
                    <svg className="selftest-status-icon" ref="healthIcon" viewBox={`0 0 73 66`}>
                        <use href={"#health-".concat(summaryStatus)}/>
                    </svg>
                    <div className="selftest-header-info"> {summaryText}</div>
                </div>
            </Link>);
    }

}

SelfTestStatus.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    results: PropTypes.array.isRequired,
    error: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    // state = { selftest: { fetch_success: true, results [ ...] }
    const st = state.selftest;

    return {
        isLoading: st.isLoading,
        results: st.results,
        error: st.error
    }
}

export default connect(mapStateToProps)(SelfTestStatus);