import moment from "moment";

export const FEDMON_BASE_URL = "https://flsmonitor-api.fed4fire.eu/";

const DEFAULT_TESTS = ["ping","anyGetVersion", "listResources",
    "nodelogin","nodelogin2", "nodelogin2long",
    "repl_sum_nodelogin", "internalStatus", "aggregateTestbedStatus" ];


function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

function doApiCall(url, options) {
    return fetch(FEDMON_BASE_URL + url, options)
        .then(handleErrors)
        .then(response => response.json());
}

class FedmonApi {

    static getTestDefinition(id){
        return doApiCall(`testdefinition/${id}`)
    }
    static getTestResult(id){
        return doApiCall(`result/${id}`)
    }

    static getTestInstanceResults(id) {
        return doApiCall("result?testinstanceid=" + id);
    }

    static getLastTestInstanceResult(id) {
        return doApiCall("result?testinstanceid=" + id);
    }

    static getTestbedsByCategory(categoryname) {
        return FedmonApi.getTestbedsByCategories([categoryname]);
    }

    static getTestbedsByCategories(categories, embed = true) {
        return doApiCall(`testbed?embed=${embed}&category=${categories.join(',')}`);
    }

    static getCategories() {
        return doApiCall(`testbedcategory`);
    }

    static getLatestResultsByTestbeds(testbeds, testdefinitionnames = DEFAULT_TESTS, last=true,
                                        cancelled_excluded = false, disabled = false, embed = true) {
        return doApiCall(`result?`
            + `testbed=${testbeds.join(',')}&`
            + `testdefinitionname=${testdefinitionnames.join(',')}&`
            + `last=${last ? 1: 0}&`
            + `cancelled_excluded=${cancelled_excluded ? 1: 0}&`
            + `disabled=${disabled? 1: 0}&`
            + `embed=${embed ? 1: 0}`);
    }

    static getResultByTestbedCategories(categories, testdefinitionnames = DEFAULT_TESTS, last=false,
                                        cancelled_excluded = false, disabled = false, embed = true) {
        return doApiCall(`result?`
            + `testbedcategory=${categories.join(',')}&`
            + `testdefinitionname=${testdefinitionnames.join(',')}&`
            + `last=${last ? 1: 0}&`
            + `cancelled_excluded=${cancelled_excluded ? 1: 0}&`
            + `disabled=${disabled? 1: 0}&`
            + `embed=${embed ? 1: 0}`);
    }
    static getLatestResultsByTestbedCategories(categories, testdefinitionnames = DEFAULT_TESTS,
                                               cancelled_excluded = false, disabled = false, embed = true) {
        return FedmonApi.getResultByTestbedCategories(categories, testdefinitionnames, true, cancelled_excluded, disabled,embed);
    }


    static getRrdData(rrdId){
        return doApiCall(`rrd/${rrdId}/data`);
    }

    static getTestInstancesByTestbed(testbedId){
        return doApiCall(`testinstance?testbed=${testbedId}`);
    }

    static getTestInstance(testInstanceId){
        return doApiCall(`testinstance/${testInstanceId}`);
    }
    static getTestInstanceStatistics(testInstanceId){
        return doApiCall(`testinstancestatistics/${testInstanceId}`);
    }

    static restartTestInstance(testInstanceId){
        const fullTestInstanceId = `${FEDMON_BASE_URL}testinstance/${testInstanceId}`;

        const bareStats = {
            'testInstance' : fullTestInstanceId,
            '@id' : fullTestInstanceId,
            '@type' : 'TestInstanceStatistics',
            'nextRun' : moment().toISOString()
        };

        return doApiCall(`testinstancestatistics/${testInstanceId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(bareStats)
        });
    }
    static getRrdByTestbed(testbedId){
        return doApiCall(`rrd?testbedid=${testbedId}`);
    }


}

export default FedmonApi;