import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import moment from 'moment';
import Loading from '../../components/Loading';
import {Doughnut} from 'react-chartjs-2';
import AbstractChart from './AbstractChart'

export default class SumNodeLogin extends Component {

    static propTypes = {
        testResult: PropTypes.object
    };

    render() {
        const {testResult} = this.props;

        let chartStage = [];
        let chartNotes = [];

        if (!testResult) {
            chartStage = (<Loading/>);
        } else {
            const {created} = testResult;
            const summaryCount = testResult.results.summaryCounts;

            const chartData = {
                labels: ["Success", "Warning", "Failure"],
                datasets: [{
                    data: summaryCount ? [summaryCount.success, summaryCount.warning, summaryCount.failure] : [0, 0, 0],
                    backgroundColor: ["#73d483", "#ff855c", "#fe6672",],
                    hoverBackgroundColor: ["#57b566", "#d86945", "#d74d58"],
                }]
            };

            const chartOptions = {
                legend: {
                    position: 'bottom',
                }
            };

            chartStage = (
                <div>
                    <Doughnut data={chartData} options={chartOptions} width={200} height={200}/>
                </div>
            );

            chartNotes.push("Last test compilation was performed at " + moment(created).format('D MMMM YYYY HH:mm') + " and took " + moment.duration({ms: testResult.results.timing.durationMs}).humanize())

        }
        return (

            <AbstractChart title="Node Login Summary" loading={!testResult} footer={chartNotes}>
                {chartStage}
            </AbstractChart>
        );


    }
}