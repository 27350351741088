import { combineReducers } from 'redux';
import coreInfo from './coreinfo';
import selftest from './selftest';
import selectableCategories from './selectableCategories';
import testResults from './testResults'
import rrd from './rrd'
import testDefinitions from './testDefinitions'
import testDefinitionResults from './testDefinitionResults'
import latestResults from './latestTestResults'
import latestResultsSummary from './latestTestResultsSummary'
import testbeds from './testbeds';
import testInstances from './testInstances';

export default combineReducers({
    coreInfo,
    selftest,
    selectableCategories,
    testResults,
    latestResults,
    latestResultsSummary,
    testbeds,
    testInstances,
    rrd,
    testDefinitions,
    testDefinitionResults
});