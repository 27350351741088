import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {Doughnut} from 'react-chartjs-2';
import {Link} from 'react-router-dom';
import {Tag, Timeline, Button, Icon, Modal} from 'antd';
import moment from "moment";
import FedmonUtils from "../../../utils";
import {DashboardPanel} from '../../../components/DashboardPanel/index'

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import NumberInfo from 'ant-design-pro/lib/NumberInfo';

import {
    faCheckCircle,
    faExclamationCircle,
    faStopCircle,
    faTimesCircle
} from "@fortawesome/fontawesome-free-solid/index";
import './DetailParts.css'

export class TimingDetail extends Component {
    render() {
        const {timing} = this.props;
        return (
            <DashboardPanel title="Timing">
                <NumberInfo subTitle="Duration"
                            total={timing.durationMs ? FedmonUtils.formatDuration(timing.durationMs) : "Not available"}/>
                <NumberInfo subTitle="Start time" total={timing.startTimeMsSinceEpoch ? (
                    <span>{moment(timing.startTimeMsSinceEpoch).format('MMMM D, YYYY HH:mm:ss.')}
                        <small>{moment(timing.startTimeMsSinceEpoch).format('SSS')}</small></span>) : "Not available"}/>
                <NumberInfo subTitle="Stop time" total={timing.stopTimeMsSinceEpoch ? (
                    <span>{moment(timing.stopTimeMsSinceEpoch).format('MMMM D, YYYY HH:mm:ss.')}
                        <small>{moment(timing.stopTimeMsSinceEpoch).format('SSS')}</small></span>) : "Not available"}/>
            </DashboardPanel>
        );
    }
}

TimingDetail.propTypes = {
    timing: PropTypes.object.isRequired
};

const customDot = (status) => {

    switch (status) {
        case "SUCCESS":
            return (<FontAwesomeIcon type="success" icon={faCheckCircle} style={{color: 'green'}}/>);
        case "WARNING":
        case "WARN":
            return (<FontAwesomeIcon type="warn" icon={faExclamationCircle} style={{color: 'orange'}}/>);
        case "FAILURE":
        case "FAILED":
            return (<FontAwesomeIcon type="failure" icon={faTimesCircle} style={{color: 'orangered'}}/>);
        case "SKIPPED":
        default:
            return (<FontAwesomeIcon type="skipped" icon={faStopCircle} style={{color: 'gray'}}/>);
    }
}

export class StepsDetail extends Component {
    render() {
        const {resultId, steps} = this.props;
        return (
            <DashboardPanel title="Steps">
                <Timeline className="steps-timeline" direction="vertical" size="small">
                    {steps.map(step => (
                        <Timeline.Item key={step.name} dot={customDot(step.state)}><Link
                            to={`/result/${resultId}/steps/#${step.name}`}>{step.name}</Link></Timeline.Item>))}
                </Timeline>
            </DashboardPanel>
        );
    }
}

StepsDetail.propTypes = {
    steps: PropTypes.array.isRequired,
    resultId: PropTypes.string.isRequired
};


export class SummaryCountDetail extends Component {
    render() {
        const {summaryCount} = this.props;

        const chartData = {
            labels: ["Success", "Warning", "Failure"],
            datasets: [{
                data: [summaryCount.success, summaryCount.warning, summaryCount.failure],
                backgroundColor: ["#73d483", "#ff855c", "#fe6672",],
                hoverBackgroundColor: ["#57b566", "#d86945", "#d74d58"],
            }]
        };

        const chartOptions = {
            legend: {
                position: 'bottom',
            }
        };

        return (
            <DashboardPanel title="Summary Counts">
                <Doughnut data={chartData} options={chartOptions} width={250} height={250}/>
            </DashboardPanel>
        );
    }
}

SummaryCountDetail.propTypes = {
    summaryCount: PropTypes.object.isRequired
};

export class SliceDetail extends Component {
    render() {
        const {slice} = this.props;


        return (
            <DashboardPanel title="Slice used for test">
                <p><strong>URN:</strong> <samp
                    style={{wordWrap: "break-word"}}>{slice.urn}</samp></p>
                <p><strong>Name:</strong> <samp>{slice.name}</samp></p>
                <p><strong>UUID:</strong> <samp>{slice.uuid}</samp></p>
            </DashboardPanel>
        );
    }
}

SliceDetail.propTypes = {
    slice: PropTypes.object.isRequired
};

export class PingDetail extends Component {
    render() {
        const {pingValue} = this.props;


        return (
            <DashboardPanel title="Ping">
                <p><strong>Ping time:</strong> <samp>{pingValue} ms</samp></p>
            </DashboardPanel>
        );
    }
}

PingDetail.propTypes = {
    pingValue: PropTypes.string.isRequired
};

export class PartsDetail extends Component {
    render() {
        const {parts} = this.props;


        return (
            <DashboardPanel title="Parts">
                <ul>
                    {Object.keys(parts).map(partId => {
                        const part = parts[partId]

                        return (<li><Link to={`/result/${part.lastResultId}`}>{partId}</Link>
                            {part.expired ? (<Tag color="volcano">Expired</Tag>) : []}
                            {part.warnCount ? (
                                <Tag color="orange">Last {part.warnCount} in Warning</Tag>) : []}
                            {part.failCount ? (
                                <Tag color="volcano">Last {part.failCount} in Failure</Tag>) : []}
                        </li>);


                    })}
                </ul>
            </DashboardPanel>
        );
    }
}

PartsDetail.propTypes = {
    parts: PropTypes.object.isRequired
};

export class HealthDetail extends Component {
    render() {
        const {health} = this.props;


        return (
            <DashboardPanel title="Testbed health">
                <NumberInfo subTitle="Health score" total={`${health.percent}%`}/>

                <p style={{marginTop: 18}}>{health.fullExplanation}</p>
            </DashboardPanel>
        );
    }
}

HealthDetail.propTypes = {
    health: PropTypes.object.isRequired
};
export class SelectedNodesDetail extends Component {
    render() {
        const {rawDetails} = this.props;

        const manifestNodes = rawDetails['rspec-manifest-fixed-nodes'];
        const requestNodes = rawDetails['rspec-request-fixed-nodes'];

        return (
            <DashboardPanel title="Selected nodes">
                {requestNodes? (<div className="selected-nodes"><h4>In Request</h4>
                    {requestNodes.map(node => (<div>{node}</div>))}
                </div>): null}
                {manifestNodes? (<div  className="selected-nodes"><h4>In Manifest</h4>
                    {manifestNodes.map(node => (<div>{node}</div>))}
                </div>): null}
            </DashboardPanel>
        );
    }
}

SelectedNodesDetail.propTypes = {
    rawDetails: PropTypes.object.isRequired
};

export class AnsibleDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHostsFileModel: false,
            showMergedRSpecModal: false,
        };
    }

    render() {
        const {ansible, resultId} = this.props;


        return (
            <DashboardPanel title="Ansible">

                <div style={{marginBottom: 16}}>
                    {ansible.secondsDuration ?
                        <NumberInfo subTitle={ansible.secondsDuration2 ? "Setup Duration" : "Duration"}
                                    total={FedmonUtils.formatDuration(Number(ansible.secondsDuration) * 1000)}/>
                        : null}
                    {ansible.secondsDuration2 ?
                        <NumberInfo subTitle="Test Duration"
                                    total={FedmonUtils.formatDuration(Number(ansible.secondsDuration2) * 1000)}/> : null
                    }
                </div>

                <Button.Group>
                    {ansible.output ? <Button href={`/result/${resultId}/ansible/`}><Icon
                        type="file-text"/> {ansible.output2 ? "Setup output" : "Output"}</Button> : null}
                    {ansible.output2 ?
                        <Button href={`/result/${resultId}/ansible/test`}><Icon type="file-text"/> Test output</Button> : null}
                    {ansible.hostsFile ?
                        <Button onClick={() => {this.setState({showHostsFileModel: true})}}><Icon type="file-text"/> Hosts-file</Button> : null}
                    {ansible.mergedRequestRspec ?
                        <Button onClick={() => {this.setState({showMergedRSpecModal: true})}}><Icon type="file-text"/> Merged Request
                            RSpec</Button> : null}
                </Button.Group>
                <Modal visible={this.state.showHostsFileModel}
                       title="Log"
                       footer={(<Button href={ansible.hostsFile}>Source</Button>)}
                       onCancel={() => this.setState({showHostsFileModel: false})}
                       width={768}>
                    <iframe title={`Hosts for ${resultId}`}
                            src={ansible.hostsFile}
                            style={{width: '100%', minHeight: '500px', height: '100%'}}/>

                </Modal>
                <Modal visible={this.state.showMergedRSpecModal}
                       title="Log"
                       footer={(<Button href={ansible.mergedRequestRspec}>Source</Button>)}
                       onCancel={() => this.setState({showMergedRSpecModal: false})}
                       width={768}>
                    <iframe title={`Merged Request RSpec for ${resultId}`}
                            src={ansible.mergedRequestRspec}
                            style={{width: '100%', minHeight: '500px', height: '100%'}}/>

                </Modal>

            </DashboardPanel>
        )

    }

}

AnsibleDetail.propTypes = {
    ansible: PropTypes.object.isRequired,
    resultId: PropTypes.string.isRequired
}