import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Card, Tag, Row, Col} from "antd";

class VerdictTag extends Component {

    static VERDICT_COLORS = {
        pass: 'green',
        warn: 'orange',
        fail: 'volcano',
        error: 'red',
        skipped: 'blue'
    };
    render(){
        const {verdict} = this.props;

        const verdictName = verdict && verdict !== "None" ? verdict : "skipped";
        const verdictColor = VerdictTag.VERDICT_COLORS[verdictName];

        return (<Tag color={verdictColor}>{verdictName} </Tag>);
    }
}

VerdictTag.propTypes = {
    verdict: PropTypes.string
};

class FInteropPartialVerdict extends Component {

    render() {
        const {name, verdict, details} = this.props;

        return (
            <div>
                <span>{name} <VerdictTag verdict={verdict} />< br/>
                <p style={{fontSize: 'smaller'}}><samp>{details}</samp></p></span>
            </div>);
    }
}

FInteropPartialVerdict.propTypes = {
    name: PropTypes.string.isRequired,
    verdict: PropTypes.string,
    details: PropTypes.string
};

class FInteropTestResultDetail extends Component {

    render() {
        const {testsuitename, testresult} = this.props;
        return (
            <Card id={`${testsuitename}-${testresult.testcase_id}`} title={testresult.testcase_id} extra={<VerdictTag verdict={testresult.verdict}/>}>
                <p><strong>Description: </strong>
                    <samp>{testresult.description}</samp>
                </p>
                {
                    testresult.partial_verdicts && testresult.partial_verdicts.length > 0 ? (
                        <div><strong>Partial verdicts: </strong>

                            {testresult.partial_verdicts.map(pv => (
                                <FInteropPartialVerdict key={pv[0]} name={pv[0]} verdict={pv[1]} details={pv[2]}/>))}

                        </div>
                    ) : []
                }
            </Card>);
    }
}

FInteropTestResultDetail.propTypes = {
    testsuitename: PropTypes.string.isRequired,
    testresult: PropTypes.object.isRequired,
};

export default class FInteropTestSuiteDetail extends Component {

    render() {
        const {testsuite} = this.props;
        let content = (<span>No info available</span>);
        if (testsuite['tc_results']) {
            content = testsuite.tc_results.map(result => (
                <Col key={result.testcase_id} lg={8} md={12} sm={24} style={{marginBottom: 16}}><FInteropTestResultDetail testsuitename={testsuite.testname} testresult={result}/></Col>));
        }


        return (
            <div id={testsuite.testname}>
                <h2><strong>Test {testsuite.testname}</strong></h2>
                <Row gutter={16}>
                    {content}
                </Row>
            </div>
        );
    }
}

FInteropTestSuiteDetail.propTypes = {
    testsuite: PropTypes.object.isRequired,
};