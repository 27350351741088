import * as types from "./actionTypes";



export function setCategoryEnabled(id, enabled) {
    return {'type': types.SET_CATEGORY_ENABLED, id, enabled}
}

export function setEnabledCategories(enabled_category_ids) {
    return {'type': types.SET_ENABLED_CATEGORIES, enabled_category_ids}
}
