import * as types from "../actions/actionTypes";

const initialState = {'isLoading': false, 'results': [], 'error': null, 'timestamp': null};

export default function selfTestReducer(state = initialState, action) {
    switch (action.type) {
        case types.SELFTESTHISTORY_IS_LOADING:
            return {...state, 'isLoading': action.isLoading};
        case types.LOAD_SELFTESTHISTORY_SUCCESS:
            return {...state, results: action.results, error: null, timestamp: action.timestamp};
        case types.LOAD_SELFTESTHISTORY_ERROR:
            return {...state, results: [], error: action.error, timestamp: action.timestamp};
        default:
            return state;
    }
}