import React, {Component} from 'react';
import {Button, Icon, Timeline} from 'antd';
import {PropTypes} from 'prop-types';
import FedmonUtils from '../../utils';
import moment from 'moment';
import DashboardPanel from "../../components/DashboardPanel/DashboardPanel";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/fontawesome-free-solid";


export default class NodeLogin extends Component {

    static propTypes = {
        testResult: PropTypes.object
    };

    render() {
        const {testResult} = this.props;

        let content = [];
        let details = [];
        let footer = [];

        if (testResult) {
            if (testResult.created) {
                footer.push(<small key="nodeloginlasttest">{"Last test was performed at " +
                moment(testResult.created).format('D MMMM YYYY HH:mm')}</small>);

                if (testResult.results.timing.durationMs) {
                    footer.push(<small key="nodelogintook">{" and took " + moment.duration({ms: testResult.results.timing.durationMs}).humanize()}</small>);
                }
            }

            if (testResult.results && testResult.results.steps) {
                content = (<Timeline>{testResult.results.steps.map(step =>
                    (<Timeline.Item key={step.name}
                                    dot={FedmonUtils.getFontAwesomeFromStatus(step.state)}>{step.name}</Timeline.Item>))}</Timeline>);
            } else {
                if (testResult.summary !== 'SUCCESS' && testResult.summary !== 'WARNING') {
                    content = (<span><Icon type="exclamation-circle" theme="twoTone" twoToneColor="#eb2f96"/> Test Result: {testResult.summary}</span>);
                } else {
                    content = (<span><Icon type="exclamation-circle" theme="twoTone" twoToneColor="#eb2f96"/> Test Result is {testResult.summary} but no test step results available</span>);
                }
            }

            details = (<Button alt="Test Result Details" href={`/result/${testResult.id}`}>
                <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;Details</Button>);
        } else {
            content = (<span><Icon type="warning" theme="twoTone" twoToneColor="#fa8c16"/> No test result available</span>);
        }

        return (
            <DashboardPanel title="Node Login"
                            loading={!testResult} notes={footer} extra={details}>
                {content}
            </DashboardPanel>
        );


    }
}