import React, {Component} from 'react';
import './TestbedTile.css';
// import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import { Tooltip } from 'antd';
import {Link} from 'react-router-dom';
import {PropTypes} from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {faHeart, faQuestion} from '@fortawesome/fontawesome-free-solid';
import {faCheckCircle, faTimesCircle, faExclamationCircle} from '@fortawesome/fontawesome-free-solid';


import StatusIcon from './StatusIcon';
import FedmonUtils from "../../utils";
import Gauge from '../Gauge';


class TestbedTile extends Component {


    render() {
        const {testbedId, testbedName, testResultsSummary} = this.props;

        const shortId = testbedId ? FedmonUtils.getIdFromUrlString(testbedId) : null;

        let statusIndicators = [];
        let tooltipContent;
        let healthStatus;
        let healthPercent;
        let healthTooltipContent;

        let tileHealth;
        if (!testResultsSummary) {
            tooltipContent = "No info available";
            healthStatus = "UNAVAILABLE";
            healthPercent = 0;
            healthTooltipContent = "No info available";

            tileHealth = "UNAVAILABLE";
        } else {
            const {
                pingValue, pingSummary, pingExpired,
                anyGetVersionSummary, listResourcesSummary, apiSummary, apiExpired,
                nodeLoginCounts, nodeLoginSteps, nodeloginSummary, nodeloginExpired,
                resourceCounts, overallStateExplanation,
                health, healthExplanation
            } = testResultsSummary;
            healthPercent = health;
            healthStatus = testResultsSummary.healthStatus;

            tileHealth = testResultsSummary.tileHealth;
            healthTooltipContent = healthExplanation;
            
            //first render all the tooltips
            const pingTooltip = pingValue ? `Ping ${pingValue} ms` : "No connectivity info available";

            statusIndicators.push(<StatusIcon key="ping" tooltipContent={pingTooltip} iconName="connectivity-icon"
                                              status={pingSummary} late={pingExpired}/>);

            const apiTooltip = (
                <div>{FedmonUtils.getFontAwesomeFromStatus(anyGetVersionSummary)} GetVersion call<br/>
                    {FedmonUtils.getFontAwesomeFromStatus(listResourcesSummary)} ListResources call</div>);

            statusIndicators.push(<StatusIcon key="api" tooltipContent={apiTooltip} iconName="api-icon"
                                              status={apiSummary} viewBoxWidth="380" viewBoxHeight="380"
                                              late={apiExpired}/>);

            const nodeloginTooltip = [];
            if (nodeLoginCounts) {
                nodeloginTooltip.push((<span key="succeeded"><FontAwesomeIcon key="success" icon={faCheckCircle}
                                                                          style={{color: 'green'}}/> {nodeLoginCounts.success}
                    &nbsp;succeeded<br/></span>));
                nodeloginTooltip.push((<span key="warning"><FontAwesomeIcon key="warning" icon={faExclamationCircle}
                                                                        style={{color: 'orange'}}/> {nodeLoginCounts.success}
                    &nbsp;warning<br/></span>));
                nodeloginTooltip.push((<span key="failure"><FontAwesomeIcon key="failure" icon={faTimesCircle}
                                                                        style={{color: 'orangered'}}/> {nodeLoginCounts.success}
                    &nbsp;failed<br/></span>));
            }

            if (nodeLoginSteps) {
                nodeLoginSteps.forEach(step => {
                    nodeloginTooltip.push(<span
                        key={step.name}>{FedmonUtils.getFontAwesomeFromStatus(step.state)} {step.name} <br/></span>);
                });
            }

            if (nodeloginTooltip.length === 0) {
                nodeloginTooltip.push("No detailed info available");
            }

            statusIndicators.push(<StatusIcon key="nodelogin" tooltipContent={nodeloginTooltip} iconName="login-process"
                                              status={nodeloginSummary} late={nodeloginExpired}/>);


            //render the gauges
            const gauges = resourceCounts.map(count => (<div key={count.name} className="resource-gauges">
                <Gauge value={count.available} max={count.total} width="45"
                       height="45"
                       label={`${count.name} free`}/>
            </div>));

            while (gauges.length < 2) {
                gauges.push((
                    <div key={`filler${gauges.length}`} className="resource-gauges" style={{height: "45px"}}/>));
            }
            gauges.forEach(gauge => statusIndicators.push(gauge));


            tooltipContent = overallStateExplanation;
        }


        //now render the tile

        const classNames = ["testbed-wrapper", "testbed-" + tileHealth.toLowerCase(), "testbed-health-" + healthStatus.toLowerCase()].join(" ");

        let title = (<div>{testbedName}</div>);

        if (shortId) {
            title = (<Link to={`/testbed/${shortId}`}>{title}</Link>);
        }

        let healthIcon = (
            <FontAwesomeIcon icon={faQuestion} className="health-questionmark" stack="1x" />);
        if (healthPercent !== undefined) {
            healthIcon = (<span className="health-icon-text">{healthPercent}</span>);
        }

        healthIcon =
            (<span className="health-icon">
                <FontAwesomeIcon className="health-icon-icon" icon={faHeart}/>
                {healthIcon}
            </span>);

        if (healthTooltipContent) {
            healthIcon = (
                <Tooltip placement="bottom" title={healthTooltipContent}>
                    {healthIcon}
                </Tooltip>);
        }

        return (
            <div className={classNames}>
                <div className="health-box">
                    {healthIcon}
                </div>
                <div className="testbed-title">
                    <Tooltip placement="bottom" title={tooltipContent}>
                        {title}
                    </Tooltip>
                </div>
                <div className="testbed-status-indicators">
                    {statusIndicators}
                </div>
            </div>
        );
    }

}

TestbedTile.propTypes = {
    testbedId: PropTypes.string,
    testbedName: PropTypes.string.isRequired,
    testResultsSummary: PropTypes.shape({
        pingValue: PropTypes.number,
        pingSummary: PropTypes.oneOf(['SUCCESS', 'WARNING', 'FAIL']),
        pingExpired: PropTypes.bool,
        resourceCounts: PropTypes.arrayOf(PropTypes.shape({
            available: PropTypes.number.isRequired,
            total: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })),
        overallState: PropTypes.oneOf(['SUCCESS', 'WARNING', 'FAIL']),
        overallStateExplanation: PropTypes.string
    })
};

export default TestbedTile;