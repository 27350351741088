import FedmonApi from '../api/fedmonApi'
import * as types from "./actionTypes";

//import {normalize} from 'normalizr';
//import * as schema from '../api/schema';



export function loadTestbed(testbedId) {
    //currently only loads RRD
    return (dispatch) => {
        dispatch(testbedRrdIsLoading(true, testbedId));

        FedmonApi.getRrdByTestbed(testbedId)
            .then(result => {
                dispatch(testbedRrdIsLoading(false, testbedId));
                dispatch(fetchTestbedRrdSuccess(result, testbedId));
            })
            .catch(error => {
                dispatch(testbedRrdIsLoading(false, testbedId));
                dispatch(fetchTestbedRrdError(error, testbedId));
            });

        FedmonApi.getTestInstancesByTestbed(testbedId)
    }
}

function testbedShouldUpdate(testbedId, state){
    return !state.testbeds.isLoading.includes(testbedId) &&
        !state.testbeds.byId[testbedId]; //no timeout for this data, as it always never changes
}

export function loadTestbedIfNeeded(testbedId){
    return (dispatch, getState) => {
        if(testbedShouldUpdate(testbedId, getState())){
            dispatch(loadTestbed(testbedId));
        }
    }
}

export function testbedRrdIsLoading(isLoading, testbedId) {
    return {'type': types.TESTBED_IS_LOADING, isLoading, testbedId}
}

export function fetchTestbedRrdSuccess(results, testbedId) {
    return {'type': types.LOAD_TESTBED_SUCCESS, testbedId, results, 'timestamp': Date.now()}
}

export function fetchTestbedRrdError(error, testbedId) {
    return {'type': types.LOAD_TESTBED_ERROR, testbedId, error, 'timestamp': Date.now()}
}