import {schema} from 'normalizr';

const DEFAULT_OPTIONS = {'idAttribute': '@id'}

export const category = new schema.Entity('categories', {}, DEFAULT_OPTIONS);
export const organisation = new schema.Entity('organisations', {}, DEFAULT_OPTIONS);

export const service = new schema.Entity('services', {}, DEFAULT_OPTIONS);
export const server = new schema.Entity('servers', {
    services: [service]
}, DEFAULT_OPTIONS);


export const testbed = new schema.Entity('testbeds', {
    categories: [category],
    organisation: organisation,
    servers: [server]
}, DEFAULT_OPTIONS);

export const result = new schema.Entity('results');
export const testDefinition = new schema.Entity('testDefinitions');
export const testInstance = new schema.Entity('testInstances');


