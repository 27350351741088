import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Layout, Menu, Switch, message} from 'antd';
import {PropTypes} from 'prop-types';
import isEqual from 'lodash.isequal';
import {setEnabledCategories} from "../../actions/selectableCategories";
import './OverviewMap.css';
import {getRequestedCategoryIds, getSortedCategories} from "../../selectors/selectableCategories";
import {subscribeToResultUpdates, unsubscribeToResultUpdates} from "../../actions/latestTestResults";
import {withRouter} from "react-router";
import {stateReducer, STATE_PRECEDENCE} from '../../reducers/latestTestResultsSummary'
import {TestbedTileContainer} from '../../components/TestbedTile';
import {Map, Marker, TileLayer, Tooltip} from 'react-leaflet';
import {createContextProvider} from '../../utils'
import Loading from "../../components/Loading/Loading";
import {Icon} from 'leaflet';

const {Content, Sider, Footer} = Layout;

const icon_props = {
    shadowUrl: '/assets/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
};

const greenMarker = new Icon({
    iconUrl: '/assets/images/marker-icon-green.png',
    ...icon_props
});
const orangeMarker = new Icon({
    iconUrl: '/assets/images/marker-icon-orange.png',
    ...icon_props
});
const redMarker = new Icon({
    iconUrl: '/assets/images/marker-icon-red.png',
    ...icon_props
});

const greyMarker = new Icon({
    iconUrl: '/assets/images/marker-icon-grey.png',
    ...icon_props
});


const markerIcons = {
    "success": greenMarker,
    "warning": orangeMarker,
    "failure": redMarker,
    "unavailable": greyMarker,
};


class OverviewMap extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            enabled_category_ids: props.requested_category_ids,
            siderCollapsed: null,
        }
    }


    componentWillMount() {
        const {dispatch} = this.props;
        dispatch(subscribeToResultUpdates());
    }

    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(unsubscribeToResultUpdates());
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps.requested_category_ids, this.props.requested_category_ids)) {
            //specific categories were requested by the url, reflect this in the state!
            this.setState({
                enabled_category_ids: nextProps.requested_category_ids
            });
        }
    }

    render() {
        const {isCoreInfoLoading, categories, sortedCategories, testbeds, servers, organisations, latestResults, latestResultsSummary, dispatch, history} = this.props;
        const {enabled_category_ids, siderCollapsed} = this.state;

        if (isCoreInfoLoading || testbeds.byId.length === 0 || Object.values(latestResults.byTestbedId).length === 0) {
            return (<div className="content-wrapper"><Loading/></div>);
        }

        // const updatePercentage = latestResults.timestamp ? ((Date.now() - latestResults.timestamp) / TESTRESULT_MS_BETWEEN_UPDATES) : 0;


        function testbedInActiveCategory(testbed) {
            return testbed.categories
                .map(cat => categories.byId[cat])
                .filter(cat => enabled_category_ids.includes(cat.name)).length > 0;
        }

        function getTestbedLocation(testbed) {
            const firstServerIdWithLocation = testbed.servers.find(server => servers.byId[server].location);
            if (firstServerIdWithLocation) {
                return servers.byId[firstServerIdWithLocation].location;
            } else
                return organisations.byId[testbed.organisation].location;
        }

        const filteredTestbeds = Object.values(testbeds.byId)
            .filter(testbed => testbedInActiveCategory(testbed));

        const testbedsPerPosition = [];

        const arraysMatch = (a1, a2) => (a1.length === a2.length && a1.every((v, i) => v === a2[i]));

        filteredTestbeds.forEach(testbed => {
            const location = getTestbedLocation(testbed);
            if (location) {
                const simpleLocation = [location.latitude, location.longitude];

                let tpp = testbedsPerPosition.find(tpp => arraysMatch(tpp.location, simpleLocation));

                if (!tpp) {
                    tpp = {
                        location: simpleLocation,
                        values: []
                    };
                    testbedsPerPosition.push(tpp);
                }
                tpp.values.push(testbed);
            }
        });

        const bounds = testbedsPerPosition.length > 0 ? testbedsPerPosition.map(tpp => tpp.location) : null;

        testbedsPerPosition.forEach(tpp => {
            tpp.summaryState = tpp.values
                .map(testbed => latestResultsSummary[testbed.id] ? latestResultsSummary[testbed.id].overallState : "UNAVAILABLE")
                .reduce(stateReducer, STATE_PRECEDENCE[STATE_PRECEDENCE.length - 1])
                .toLowerCase();
        });

        const setCategoryEnabled = (id, enabled) => {

            let new_enabled_ids = enabled ?
                [...enabled_category_ids, id] :
                enabled_category_ids.filter(enabled_id => id !== enabled_id);

            new_enabled_ids.sort((id1, id2) => {
                const idx1 = sortedCategories.findIndex(cat => cat.shortId === id1);
                const idx2 = sortedCategories.findIndex(cat => cat.shortId === id2);

                if (idx1 > -1 && idx2 > -1)
                    return idx1 - idx2;
                else if (idx1 > -1) { //these cases shouldn't happen, but test for them anyway
                    return 1;
                } else if (idx2 > -1) {
                    return -1;
                } else {
                    return id1.localeCompare(id2);
                }
            });

            dispatch(setEnabledCategories(new_enabled_ids));

            if (new_enabled_ids.length !== sortedCategories.length) {
                history.push(`/map/${new_enabled_ids.join(',')}`);
            } else {
                history.push('/map/');
            }

            // this.setState({
            //     enabled_category_ids: new_enabled_ids
            // });

        };


        const ContextProvider = createContextProvider(this.context);

        const markers = testbedsPerPosition.map(tpp =>
            (<Marker position={tpp.location} icon={markerIcons[tpp.summaryState]}>
                <Tooltip>
                    <ContextProvider>
                        <div>
                            {tpp.values.map(
                                testbed => {
                                    return (
                                        <div key={testbed.id} className="testbedtilecontainer-wrapper">
                                            {/*{testbed.longName}*/}
                                            <TestbedTileContainer testbedId={testbed['@id']}/>
                                        </div>);
                                })}
                        </div>
                    </ContextProvider>
                </Tooltip>
            </Marker>));

        const onSiderBreakPoint = (broken) => {
            this.setState({siderCollapsed: broken});
        };

        const onTestbedMenuClick = (event) => {
            console.log(event);
            const map = this.refs.map.leafletElement;

            const tb = testbeds.byId[event.key];
            if (tb != null) {
                const tbLocation = getTestbedLocation(tb);
                if (tbLocation) {
                    map.flyTo({lat: tbLocation.latitude, lng: tbLocation.longitude}, 8);
                } else {
                    message.error(`No location available for ${tb.longName}`);
                }
            } else {
                message.error(`Could not retrieve testbed with ID '${event.key}'`)
            }
        };

        const createTestbedMenuItem = (tb) => {
            return (<Menu.Item key={tb['@id']}>
                {tb.longName}
            </Menu.Item>);
        };

        const createCategoryMenu =
            (cat) => {
                return (<Menu.SubMenu key={cat.shortId} disabled={!enabled_category_ids.includes(cat.shortId)}
                                      title={(<div className="overview-menuitem">
                                          <div className="overview-menuitem-name">{cat.name}</div>
                                          <Switch key={cat.id} size="small"
                                                  checked={enabled_category_ids.includes(cat.shortId)}
                                                  disabled={enabled_category_ids.includes(cat.shortId) && enabled_category_ids.length === 1}
                                                  onChange={(checked) => setCategoryEnabled(cat.shortId, checked)}/>
                                      </div>)}>
                    {Object.values(testbeds.byId).filter(tb => tb.categories.includes(cat.id)).map(createTestbedMenuItem)}
                </Menu.SubMenu>);
            };

        return (
            <Layout>
                <Sider theme="light" collapsible={true} collapsedWidth={0} width={280}
                       breakpoint="md" trigger={siderCollapsed ? undefined : null} onBreakpoint={onSiderBreakPoint}>
                    <Menu className="overview-menu overviewmap-menu" onClick={onTestbedMenuClick}>
                        {sortedCategories.map(createCategoryMenu)}
                    </Menu>
                </Sider>
                <Content>
                    <Layout>
                        <Content className="content-wrapper">
                            <Map ref="map" bounds={bounds} zoom={6} className="map">
                                <TileLayer
                                    attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy;
        <a href="https://carto.com/attribution">CARTO</a>'
                                    url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
                                />
                                {markers}
                            </Map>
                        </Content>
                        <Footer>&copy; imec</Footer>
                    </Layout>
                </Content>
            </Layout>
        );
    }
}

OverviewMap.propTypes = {
    categories: PropTypes.object.isRequired,
    sortedCategories: PropTypes.array.isRequired,
    requested_category_ids: PropTypes.array.isRequired,
    isCoreInfoLoading: PropTypes.bool.isRequired,
    testbeds: PropTypes.object.isRequired,
    organisations: PropTypes.object.isRequired,
    servers: PropTypes.object.isRequired,
    latestResults: PropTypes.object.isRequired,
    latestResultsSummary: PropTypes.object.isRequired
};

OverviewMap.contextTypes = {
    store: PropTypes.object,
    router: PropTypes.object
};


function mapStateToProps(state, ownProps) {

    return {
        categories: state.coreInfo.categories,
        sortedCategories: getSortedCategories(state),
        requested_category_ids: getRequestedCategoryIds(state, ownProps),
        isCoreInfoLoading: state.coreInfo.isCoreInfoLoading,
        testbeds: state.coreInfo.testbeds,
        organisations: state.coreInfo.organisations,
        servers: state.coreInfo.servers,
        latestResults: state.latestResults,
        latestResultsSummary: state.latestResultsSummary,
    }
}

export default withRouter(connect(mapStateToProps)(OverviewMap));