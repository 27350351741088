import React, {Component} from "react";
import {push} from "connected-react-router";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Card, Icon} from "antd";

class CardErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {error: null, errorInfo: null}
    }

    componentDidCatch(error, errorInfo) {
        console.log("Catching error", error);
        this.setState({error, errorInfo})
    }

    resetError() {
        const {dispatch} = this.props;

        dispatch(push('/'));
        this.setState({error: null, errorInfo: null});
    }

    render() {
        const {title} = this.props;
        const {error /*, errorInfo */} = this.state;

        if (error) {
            return (<Card title={title}><Icon type="exclamation-circle" theme="twoTone" twoToneColor="#eb2f96" /> An error occurred: {error.message ? error.message : "No info"}</Card>);
        }

        return this.props.children;
    }
}

CardErrorBoundary.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.object,
};

export default connect()(CardErrorBoundary);