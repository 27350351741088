import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Table, Tooltip} from "antd";
import moment from "moment";

import './LogLinesTable.css'

class LogLinesTable extends Component {

    render() {
        const {logLines} = this.props;

        const logTypes = [...new Set(logLines.map(ll => ll.type))]
            .sort()
            .map(type => {return {text: type, value: type}});

        const columns = [{
            title: 'Timestamp',
            dataIndex: 'timeInMs',
            render: (text, record, index) => (
                <Tooltip title={moment(Number(text)).toISOString()}>{moment(Number(text)).format("HH:mm:ss")}.
                    <small>{moment(Number(text)).format("SSS")}</small>
                </Tooltip>),
            width: 120,
        }, {
            title: 'Type',
            dataIndex: 'type',
            filters: logTypes,
            onFilter: (values, ll) => values.includes(ll.type),
            width: 96,
        }, {
            title: 'Text',
            dataIndex: 'text',
            render: (text, logline, index) => {
                if (logline.formatAsCode === "true") {
                    return (<pre>{text}</pre>)
                } else {
                    return text;
                }
            }
        }];

        return (<Table className="loglines-table" columns={columns} dataSource={logLines} bordered={false}
                       rowClassName={(line) => `logline-row-${line.type ? line.type.toLowerCase() : "unknown"}`}
                       size="small" pagination={false}/>);
    }
}

LogLinesTable.propTypes = {
    logLines: PropTypes.arrayOf(PropTypes.shape({
        timeInMs: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        formatAsCode: PropTypes.string
    })).isRequired
};

export default LogLinesTable;