import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {Row, Col, Card} from 'antd';
import moment from "moment";
import {DashboardPanel} from '../../../components/DashboardPanel/index'

import NumberInfo from 'ant-design-pro/lib/NumberInfo';


export class ResultInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showContextModal: false,
        };
    }

    render() {
        const {rawDetails} = this.props;

        let resultInfo = [];

        if (rawDetails.note
            || rawDetails.lastFailDate
            || rawDetails.lastOkDate
            || rawDetails.okTimeMs
            || rawDetails.thisFailTimeMs) {

            resultInfo = (
                <Row gutter={16}>
                    {rawDetails.lastFailDate ? (
                        <Col sm={12} md={6}><Card><NumberInfo key="lastfail"
                                                              subTitle={<span>Last failure date</span>}
                                                              total={moment(rawDetails.lastFailDate).format('LLL')}/></Card></Col>
                    ) : []}
                    {rawDetails.lastOkDate ? (
                        <Col sm={12} md={6}><Card><NumberInfo key="lastok" subTitle={<span>Last OK date</span>}
                                                              total={moment(rawDetails.lastOkDate).format('LLL')}/></Card></Col>) : []}
                    {rawDetails.okTimeMs ? (
                        <Col sm={12} md={6}><Card><NumberInfo key="oktime" subTitle="OK since"
                                                              total={moment.duration(rawDetails.okTimeMs).humanize()}/></Card></Col>) : []}
                    {rawDetails.thisFailTimeMs ? (
                        <Col sm={12} md={6}><Card><NumberInfo key="failtime" subTitle="Failing
                            since" total={moment.duration(rawDetails.thisFailTimeMs).humanize()}/></Card></Col>) : []}


                    {rawDetails.note ? (
                        <Col sm={24} md={12}>
                            <DashboardPanel title="Result note">
                                <samp>{rawDetails.note}</samp></DashboardPanel></Col>) : []}
                </Row>);
        } else {
            resultInfo = []; //(<div>No result info available</div>)
        }

        return resultInfo;
    }
}

ResultInfo.propTypes = {
    resultId: PropTypes.number.isRequired,
    rawDetails: PropTypes.object.isRequired
};