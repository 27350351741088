import FedmonApi from '../api/fedmonApi'
import * as types from "./actionTypes";


export function loadTestDefinition(testDefinitionId) {
    return (dispatch) => {
        dispatch(testDefinitionIsLoading(true, testDefinitionId));

        FedmonApi.getTestDefinition(testDefinitionId)
            .then(result => {
                dispatch(testDefinitionIsLoading(false, testDefinitionId));
                dispatch(fetchTestDefinitionSuccess(result, testDefinitionId));
            })
            .catch(error => {
                dispatch(testDefinitionIsLoading(false, testDefinitionId));
                dispatch(fetchTestDefinitionError(error, testDefinitionId));
            });
    }
}

export function shouldLoadTestDefinition(testDefinitionId, state) {
    return !state.testDefinitions.isLoading.includes(testDefinitionId)
        && !state.testDefinitions.byId[testDefinitionId];
}

export function loadTestDefinitionIfNeeded(testDefinitionId) {
    return (dispatch, getState) => {
        if (shouldLoadTestDefinition(testDefinitionId, getState())) {
            dispatch(loadTestDefinition(testDefinitionId));
        }
    }
}

export function testDefinitionIsLoading(isLoading, testDefinitionId) {
    return {'type': types.TESTDEFINITION_IS_LOADING, isLoading, testDefinitionId}
}

export function fetchTestDefinitionSuccess(result, testDefinitionId) {
    return {'type': types.LOAD_TESTDEFINITION_SUCCESS, result, testDefinitionId, 'timestamp': Date.now()}
}

export function fetchTestDefinitionError(error, testDefinitionId) {
    return {'type': types.LOAD_TESTDEFINITION_ERROR, error, testDefinitionId, 'timestamp': Date.now()}
}