export const SELFTESTHISTORY_IS_LOADING = 'SELFTESTHISTORY_IS_LOADING';
export const LOAD_SELFTESTHISTORY_SUCCESS = 'LOAD_SELFTESTHISTORY_SUCCESS';
export const LOAD_SELFTESTHISTORY_ERROR = 'LOAD_SELFTESTHISTORY_ERROR';


export const SET_CATEGORY_ENABLED = 'fedmon-dashboard/selectableCategories/SET_CATEGORY_ENABLED';
export const SET_ENABLED_CATEGORIES = 'fedmon-dashboard/selectableCategories/SET_ENABLED_CATEGORIES';


export const COREINFO_IS_LOADING = 'COREINFO_IS_LOADING';
export const LOAD_COREINFO_SUCCESS = 'LOAD_COREINFO_SUCCESS';
export const LOAD_COREINFO_ERROR = 'LOAD_COREINFO_ERROR';

export const LATEST_RESULTS_IS_LOADING = 'LATEST_RESULTS_IS_LOADING';
export const LOAD_LATEST_RESULTS_SUCCESS = 'LOAD_LATEST_RESULTS_SUCCESS';
export const LOAD_LATEST_RESULTS_ERROR = 'LOAD_LATEST_RESULTS_ERROR';

export const UPDATE_DIREVED_INFO_LATEST_RESULTS = 'UPDATE_DIREVED_INFO_LATEST_RESULTS';

export const SUBSCRIBE_TO_LATEST_RESULTS = "SUBSCRIBE_TO_LATEST_RESULTS";
export const UNSUBSCRIBE_TO_LATEST_RESULTS = "UNSUBSCRIBE_TO_LATEST_RESULTS";


export const TESTBED_IS_LOADING = 'TESTBED_IS_LOADING';
export const LOAD_TESTBED_SUCCESS = 'LOAD_TESTBED_SUCCESS';
export const LOAD_TESTBED_ERROR = 'LOAD_TESTBED_ERROR';

export const TESTRESULTHISTORY_IS_LOADING = 'TESTRESULTHISTORY_IS_LOADING';
export const LOAD_TESTRESULTHISTORY_SUCCESS = 'LOAD_TESTRESULTHISTORY_SUCCESS';
export const LOAD_TESTRESULTHISTORY_ERROR = 'LOAD_TESTRESULTHISTORY_ERROR';

export const TESTRESULT_IS_LOADING = 'TESTRESULT_IS_LOADING';
export const LOAD_TESTRESULT_SUCCESS = 'LOAD_TESTRESULT_SUCCESS';
export const LOAD_TESTRESULT_ERROR = 'LOAD_TESTRESULT_ERROR';

export const TESTINSTANCES_BY_TESTBED_IS_LOADING = 'TESTINSTANCES_BY_TESTBED_IS_LOADING';
export const LOAD_TESTINSTANCES_BY_TESTBED_SUCCESS = 'LOAD_TESTINSTANCES_BY_TESTBED_SUCCESS';
export const LOAD_TESTINSTANCES_BY_TESTBED_ERROR = 'LOAD_TESTINSTANCES_BY_TESTBED_ERROR';

export const TESTINSTANCE_IS_LOADING = 'TESTINSTANCE_IS_LOADING';
export const LOAD_TESTINSTANCE_SUCCESS = 'LOAD_TESTINSTANCE_SUCCESS';
export const LOAD_TESTINSTANCE_ERROR = 'LOAD_TESTINSTANCE_ERROR';

export const TESTINSTANCESTATISTICS_IS_LOADING = 'TESTINSTANCESTATISTICS_IS_LOADING';
export const LOAD_TESTINSTANCESTATISTICS_SUCCESS = 'LOAD_TESTINSTANCESTATISTICS_SUCCESS';
export const LOAD_TESTINSTANCESTATISTICS_ERROR = 'LOAD_TESTINSTANCESTATISTICS_ERROR';

export const TESTINSTANCE_IS_RESTARTING = 'TESTINSTANCE_IS_RESTARTING';
export const RESTART_TESTINSTANCE_SUCCESS = 'RESTART_TESTINSTANCE_SUCCESS';
export const RESTART_TESTINSTANCE_ERROR = 'RESTART_TESTINSTANCE_ERROR';

export const RRD_IS_LOADING = 'RRD_IS_LOADING';
export const LOAD_RRD_SUCCESS = 'LOAD_RRD_SUCCESS';
export const LOAD_RRD_ERROR = 'LOAD_RRD_ERROR';

export const TESTDEFINITION_IS_LOADING = 'TESTDEFINITION_IS_LOADING';
export const LOAD_TESTDEFINITION_SUCCESS = 'LOAD_TESTDEFINITION_SUCCESS';
export const LOAD_TESTDEFINITION_ERROR = 'LOAD_TESTDEFINITION_ERROR';

export const TESTDEFINITION_LATESTRESULTS_IS_LOADING = 'TESTDEFINITION_LATESTRESULTS_IS_LOADING';
export const LOAD_TESTDEFINITION_LATESTRESULTS_SUCCESS = 'LOAD_TESTDEFINITION_LATESTRESULTS_SUCCESS';
export const LOAD_TESTDEFINITION_LATESTRESULTS_ERROR = 'LOAD_TESTDEFINITION_LATESTRESULTS_ERROR';
