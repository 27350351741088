import React, {Component} from 'react';
import {Col, Row, Empty, Button} from 'antd';
import {PropTypes} from 'prop-types';
import moment from 'moment';
import CalendarHeatmap from '../../components/CalendarHeatmap';
import {DashboardPanel} from "../../components/DashboardPanel";
import {loadRrdIfNeeded} from "../../actions/rrd";
import {loadTestbedIfNeeded} from "../../actions/testbeds";
import {loadTestInstanceStatisticsIfNeeded} from "../../actions/testInstances";
import {FEDMON_BASE_URL} from "../../api/fedmonApi";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/fontawesome-free-solid";

export const percentToStatus = (percent) => {
    if (percent > 98.5) {
        return "success";
    } else if (percent > 95) {
        return "warning";
    } else {
        return "error";
    }
};

const isSummationEmpty = (summation) => {
    if (!summation) {
        return true;
    }
    if (!summation.week) {
        return true;
    }
    if (!summation.month) {
        return true;
    }
    if (!summation.year) {
        return true;
    }
    return isNaN(summation.week.successPercent) &&
        isNaN(summation.month.successPercent) &&
        isNaN(summation.year.successPercent)
};

class HistoryChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeRrdId: null,
            showDetails: false,
        }
    }

    componentDidMount() {
        const {dispatch, testbedId, testInstanceId} = this.props;

        if (testbedId) {
            dispatch(loadTestbedIfNeeded(testbedId));
        }
        if (testInstanceId) {
            dispatch(loadTestInstanceStatisticsIfNeeded(testInstanceId));
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dispatch, testbedId, testInstanceId} = this.props;
        const {activeRrdId} = this.state;

        if (testbedId && testbedId !== prevProps.testbedId) {
            dispatch(loadTestbedIfNeeded(testbedId));
        }

        if (activeRrdId && activeRrdId !== prevState.activeRrdId) {
            dispatch(loadRrdIfNeeded(activeRrdId));
        }

        if (testInstanceId && testInstanceId !== prevProps.testInstanceId) {
            dispatch(loadTestInstanceStatisticsIfNeeded(testInstanceId));
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {yearRrdId} = nextProps;
        let {activeRrdId} = this.state;

        if (!activeRrdId && yearRrdId) {
            activeRrdId = yearRrdId;

            this.setState({
                activeRrdId
            });
        }
    }

    static NUMBER_OF_COLORS = 4;

    render() {
        const self = this;
        const {rrdData, testInstanceStatistics, testInstanceId, rrdIsLoading, statisticsIsLoading} = this.props;
        const {activeRrdId} = this.state;

        const activeRrdData = rrdData[activeRrdId];

        const loading = rrdIsLoading || statisticsIsLoading;
        const emptyRrd = !activeRrdData || activeRrdData.length === 0;
        const emptySummation = (!testInstanceStatistics) || (!testInstanceStatistics.summation) || isSummationEmpty(testInstanceStatistics.summation);
        const hasRrd = !loading && !emptyRrd;
        const hasSummation = !loading && !emptySummation;

        const onToggleDetails = (broken) => {
            this.setState({showDetails: !this.state.showDetails});
        };
        const showDetailsButton = <Button size="small" onClick={onToggleDetails}><FontAwesomeIcon
            icon={faInfoCircle}/>&nbsp;{this.state.showDetails ? "Hide Details" : "Show Details"}
        </Button>;

        //NOTE: rrd values for downtime are in ms, but aggregate values are is s
        const values = activeRrdData ? activeRrdData.map(result => {
            return {...result, date: result.from}
        }) : [];

        let footer = null;

        let summationStats = null;

        if (hasSummation) {
            const SummationAlert = self.makeSummationAlert;
            summationStats = (
                <Row style={{marginTop: 8}}>
                    <Col md={8}>
                        <SummationAlert summation={testInstanceStatistics.summation.week} name="week"
                                        style={{marginRight: 4}}/>
                    </Col> <Col md={8}>
                    <SummationAlert summation={testInstanceStatistics.summation.month} name="month"
                                    style={{marginLeft: 4, marginRight: 4}}/>
                </Col> <Col md={8}>
                    <SummationAlert summation={testInstanceStatistics.summation.year} name="year"
                                    style={{marginLeft: 4}}/>
                </Col>
                </Row>)
        } else {
            summationStats = [];
        }

        if (hasRrd && this.state.showDetails) {
            // footer = <small>Showing aggregate status from {moment(activeRrdData[0].from).format('D MMMM YYYY')} until {moment(activeRrdData[activeRrdData.length - 1].from).format('D MMMM YYYY')} for TestInstance <a href={`${FEDMON_BASE_URL}testinstance/${testInstanceId}`}>{testInstanceId}</a> with <a href={`${FEDMON_BASE_URL}rrd?testinstanceid=${testInstanceId}`}>RRD</a> and <a href={`${FEDMON_BASE_URL}testinstancestatistics/${testInstanceId}`}>statistics</a></small>;
            footer = <small>Showing Graph from {moment(activeRrdData[0].from).format('D MMMM YYYY')} until {moment(activeRrdData[activeRrdData.length - 1].from).format('D MMMM YYYY')} using <a href={`${FEDMON_BASE_URL}rrd/${activeRrdId}`}>RRD</a>.
                Aggregate statistics are for TestInstance <a href={`${FEDMON_BASE_URL}testinstance/${testInstanceId}`}>{testInstanceId}</a> with <a href={`${FEDMON_BASE_URL}testinstancestatistics/${testInstanceId}`}>statistics</a>.
            </small>;
        }

        const classForValue = (value) => { return self.getClassForValue(value); };
        const customTitleForValue = (value) => { return self.getCustomTitleForValue(value); };

        return (
            <DashboardPanel loading={loading} title={self.title} footer={footer} extra={showDetailsButton}>
                {!hasRrd ?
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No detailed history found"/> :
                    <CalendarHeatmap
                        endDate={values[values.length - 1].date}
                        numDays={365}
                        values={values}
                        horizontal={true}
                        classForValue={classForValue}
                        titleForValue={customTitleForValue}/>
                }
                {!hasSummation ?
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                           description="No statistics found"/> :
                    summationStats
                }

            </DashboardPanel>);
    }
}

HistoryChart.propTypes = {
    testbedId: PropTypes.string.isRequired,
};

export default HistoryChart;
