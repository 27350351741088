import * as types from "../actions/actionTypes";

const initialState = {
    isLoading: [],//list of testInstanceIds for which the info is loading
    byTestInstanceId: {},
    byId: {
        "123": {
            "id": 48265542,
            "created": "2018-06-07T08:01:07Z",
            "logUrl": "https://flsmonitor-api.fed4fire.eu/log/88152908/content",
            "summary": "SUCCESS",
            "results": {
                "ansible": {
                    "output": "https://flsmonitor-api.fed4fire.eu/log/88153345/content",
                    "hostsFile": "https://flsmonitor-api.fed4fire.eu/log/88153343/content",
                    "secondsDuration": 92.667,
                    "mergedRequestRspec": "https://flsmonitor-api.fed4fire.eu/log/88153332/content"
                },
                "automatedTesterConfig": "https://flsmonitor-api.fed4fire.eu/log/88153348/content",
                "extract": {
                    "coapthon-cli-vs-coapthon-server": {
                        "testname": "coapthon-cli-vs-coapthon-server",
                        "_api_version": "1.1.0",
                        "tc_results": [{
                            "description": " Mismatch: CoAP(opt=Opt(CoAPOptionContentFormat()))",
                            "testcase_id": "TD_COAP_CORE_01",
                            "verdict": "fail",
                            "partial_verdicts": [["TD_COAP_CORE_01_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_01_step_03", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_01_step_04", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 7] GET /test> Match: CoAP(type=0, code=1)"], ["tat_check_2", "fail", " Mismatch: CoAP(opt=Opt(CoAPOptionContentFormat()))"]]
                        }, {
                            "description": "No interoperability error was detected,",
                            "testcase_id": "TD_COAP_CORE_02",
                            "verdict": "pass",
                            "partial_verdicts": [["TD_COAP_CORE_02_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_02_step_03", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_02_step_04", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 951] DELETE /test> Match: CoAP(type=0, code=4)"], ["tat_check_2", "pass", "<Frame   2: [bbbb::2 -> bbbb::1] CoAP [ACK 951] 2.02 Deleted > Match: CoAP(c$de=66, mid=0x03b7, tok=b'')"]]
                        }, {
                            "description": " Mismatch: CoAP(code=Any(65,68), mid=0x0167, tok=b'')",
                            "testcase_id": "TD_COAP_CORE_03",
                            "verdict": "inconclusive",
                            "partial_verdicts": [["TD_COAP_CORE_03_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_03_step_03", "pass", "VERIFY step: User informed that the information was displayed $orreclty on his/her IUT", ""], ["TD_COAP_CORE_03_step_04", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_03_step_05", "pass", "VERIFY step: User informed that the inform$tion was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 359] PUT /test> Match: CoAP(type=0, code=3)"], ["tat_check_$", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 359] PUT /test> Match: CoAP(opt=Opt(CoAPOptionContentFormat()))"], ["tat_check_3", "inconclusive", " Mismatch: CoAP(code=An$(65,68), mid=0x0167, tok=b'')"]]
                        }, {
                            "description": "Testcase TD_COAP_CORE_04 was skipped.",
                            "testcase_id": "TD_COAP_CORE_04",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_05 was skipped.",
                            "testcase_id": "TD_COAP_CORE_05",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_06 was skipped.",
                            "testcase_id": "TD_COAP_CORE_06",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_07 was skipped.",
                            "testcase_id": "TD_COAP_CORE_07",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_08 was skipped.",
                            "testcase_id": "TD_COAP_CORE_08",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_09 was skipped.",
                            "testcase_id": "TD_COAP_CORE_09",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_10 was skipped.",
                            "testcase_id": "TD_COAP_CORE_10",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_11 was skippd.",
                            "testcase_id": "TD_COAP_CORE_11",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_12 was skipped.",
                            "testcase_id": "TD_COAP_CORE_1",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_13 was skipped.",
                            "testcase_id": "TD_COAP_CORE_13",
                            "verdict": "None",
                            "partial_vedicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_14 was skipped.",
                            "testcase_id": "TD_COAP_CORE_14",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcse TD_COAP_CORE_15 was skipped.",
                            "testcase_id": "TD_COAP_CORE_15",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_16 was skipped.",
                            "tetcase_id": "TD_COAP_CORE_16",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_17 was skipped.",
                            "testcase_id": "TD_COAP_CORE_17",
                            "verdct": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_18 was skipped.",
                            "testcase_id": "TD_COAP_CORE_18",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_19 was skipped.",
                            "testcase_id": "TD_COAP_CORE_19",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAPCORE_20 was skipped.",
                            "testcase_id": "TD_COAP_CORE_20",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_21 was skipped.",
                            "testcase_id": "TD_COAP_CORE_21",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_22 was skipped.",
                            "testcase_id": "TD_COAP_CORE_22",
                            "verdict": "Noe",
                            "partial_verdicts": []
                        }, {
                            "description": "Testcase TD_COAP_CORE_23 was skipped.",
                            "testcase_id": "TD_COAP_CORE_23",
                            "verdict": "None",
                            "partial_verdicts": []
                        }, {
                            "desciption": "Testcase TD_COAP_CORE_31 was skipped.",
                            "testcase_id": "TD_COAP_CORE_31",
                            "verdict": "None",
                            "partial_verdicts": []
                        }]
                    }
                },
                "fixed_node_name": "nuc0-1",
                "fixed_node_urn": "urn:publicid:IDN+wilab1.ilabt.iminds.be+node+nuc0-1",
                "inMaintenanceDuringTest": false,
                "nodelogin": {
                    "node": "ftester@nuc0-1.wilab1.ilabt.iminds.be:22",
                    "stdout": "https://flsmonitor-api.fed4fire.eu/log/88153327/content",
                    "command": "ls / && uname -a && who && (netstat -antW | grep ':22 ')"
                },
                "note": ["successMethodNames=[login, ansible] warningMethodNames=[] warningMethodSuccessful=null successMethodSuccessful=true"],
                "resultHtml": "https://flsmonitor-api.fed4fire.eu/log/88153349/content",
                "resultXml": "https://flsmonitor-api.fed4fire.eu/log/88153350/content",
                "rspec-manifest-fixed-nodes": ["urn:publicid:IDN+wilab1.ilabt.iminds.be+node+nuc0-1"],
                "rspec-request-fixed-nodes": ["urn:publicid:IDN+wilab1.ilabt.iminds.be+node+nuc0-1"],
                "rspec-request-fixed-nodes-0": ["urn:publicid:IDN+wilab1.ilabt.iminds.be+node+nuc0-1"],
                "rspec0": "https://flsmonitor-api.fed4fire.eu/log/88153325/content",
                "server": "https://flsmonitor-api.fed4fire.eu/server/484",
                "steps": [{"name": "setUp", "state": "SUCCESS"}, {
                    "name": "fetchAdvertisementRspec",
                    "state": "SUCCESS"
                }, {"name": "getRspec", "state": "SUCCESS"}, {
                    "name": "findProject",
                    "state": "SUCCESS"
                }, {"name": "createExperiment", "state": "SUCCESS"}, {
                    "name": "runExperiment",
                    "state": "SUCCESS"
                }, {"name": "checkManifest", "state": "SUCCESS"}, {
                    "name": "login",
                    "state": "SUCCESS"
                }, {"name": "ansible", "state": "SUCCESS"}, {"name": "deleteExperiment", "state": "SUCCESS"}],
                "testbed": "https://flsmonitor-api.fed4fire.eu/testbed/wilab1",
                "timing": {
                    "durationMs": 262599,
                    "stopTimeMsSinceEpoch": 1528358470069,
                    "startTimeMsSinceEpoch": 1528358207470
                }
            },
            "testInstance": "https://flsmonitor-api.fed4fire.eu/testinstance/1225",
            "testDefinition": "https://flsmonitor-api.fed4fire.eu/testdefinition/autosensortest",
            "@id": "https://flsmonitor-api.fed4fire.eu/result/48265542",
            "@type": "Result"
        },

        "124": {
            "id": 48265542,
            "created": "2018-06-07T08:01:07Z",
            "logUrl": "https://flsmonitor-api.fed4fire.eu/log/88152908/content",
            "summary": "SUCCESS",
            "results": {
                "ansible": {
                    "output": "https://flsmonitor-api.fed4fire.eu/log/88153345/content",
                    "hostsFile": "https://flsmonitor-api.fed4fire.eu/log/88153343/content",
                    "secondsDuration": 92.667,
                    "mergedRequestRspec": "https://flsmonitor-api.fed4fire.eu/log/88153332/content"
                },
                "automatedTesterConfig": "https://flsmonitor-api.fed4fire.eu/log/88153348/content",
                "extract": {
                    "californium-cli-vs-californium-server": {
                        "testname": "californium-cli-vs-californium-server",
                        "_api_version": "1.1.0",
                        "tc_results": [{
                            "testcase_id": "TD_COAP_CORE_01",
                            "verdict": "pass",
                            "description": "No interoperability error was detected,",
                            "partial_verdicts": [["TD_COAP_CORE_01_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_01_step_03", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_01_step_04", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 7292] GET /test> Match: CoAP(type=0, code=1)"], ["tat_check_2", "pass", "<Frame   2: [bbbb::2 -> bbbb::1] CoAP [ACK 7292] 2.05 Content > Match: CoAP(opt=Opt(CoAPOptionContentFormat()))"]]
                        }, {
                            "testcase_id": "TD_COAP_CORE_02",
                            "verdict": "pass",
                            "description": "No interoperability error was detected,",
                            "partial_verdicts": [["TD_COAP_CORE_02_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_02_step_03", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_02_step_04", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 28721] DELETE /test> Match: CoAP(type=0, code=4)"], ["tat_check_2", "pass", "<Frame   2: [bbbb::2 -> bbbb::1] CoAP [ACK 28721] 2.02 Deleted > Match: CoAP(code=66, mid=0x7031, tok=b'>\\\\x10\\\\xc2\\\\xe5\\\\x0c\\\\x87Q\\\\x80')"]]
                        }, {
                            "testcase_id": "TD_COAP_CORE_03",
                            "verdict": "pass",
                            "description": "No interoperability error was detected,",
                            "partial_verdicts": [["TD_COAP_CORE_03_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_03_step_03", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["TD_COAP_CORE_03_step_04", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_03_step_05", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 2591] PUT /test> Match: CoAP(type=0, code=3)"], ["tat_check_2", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 2591] PUT /test> Match: CoAP(opt=Opt(CoAPOptionContentFormat()))"], ["tat_check_3", "pass", "<Frame   2: [bbbb::2 -> bbbb::1] CoAP [ACK 2591] 2.04 Changed > Match: CoAP(code=Any(65,68), mid=0x0a1f, tok=b'\\\\xa2G6\\\\xe9\\\\xd1ix\\\\xce')"]]
                        }, {
                            "testcase_id": "TD_COAP_CORE_04",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_04 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_05",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_05 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_06",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_06 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_07",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_07 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_08",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_08 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_09",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_09 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_10",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_10 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_11",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_11 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_12",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_12 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_13",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_13 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_14",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_14 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_15",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_15 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_16",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_16 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_17",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_17 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_18",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_18 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_19",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_19 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_20",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_20 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_21",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_21 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_22",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_22 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_23",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_23 was skipped.",
                            "partial_verdicts": []
                        }, {
                            "testcase_id": "TD_COAP_CORE_31",
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_31 was skipped.",
                            "partial_verdicts": []
                        }]
                    },
                    "coapthon-cli-vs-californium-server": {
                        "testname": "coapthon-cli-vs-californium-server",
                        "_api_version": "1.1.0",
                        "tc_results": [{
                            "partial_verdicts": [["TD_COAP_CORE_01_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_01_step_03", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_01_step_04", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   5: [bbbb::1 -> bbbb::2] CoAP [CON 413] GET /test> Match: CoAP(type=0, code=1)"], ["tat_check_2", "pass", "<Frame   6: [bbbb::2 -> bbbb::1] CoAP [ACK 413] 2.05 Content > Match: CoAP(opt=Opt(CoAPOptionContentFormat()))"]],
                            "verdict": "pass",
                            "description": "No interoperability error was detected,",
                            "testcase_id": "TD_COAP_CORE_01"
                        }, {
                            "partial_verdicts": [["TD_COAP_CORE_02_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_02_step_03", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_02_step_04", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   7: [bbbb::1 -> bbbb::2] CoAP [CON 499] DELETE /test> Match: CoAP(type=0, code=4)"], ["tat_check_2", "pass", "<Frame   8: [bbbb::2 -> bbbb::1] CoAP [ACK 499] 2.02 Deleted > Match: CoAP(code=66, mid=0x01f3, tok=b'')"]],
                            "verdict": "pass",
                            "description": "No interoperability error was detected,",
                            "testcase_id": "TD_COAP_CORE_02"
                        }, {
                            "partial_verdicts": [["TD_COAP_CORE_03_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_03_step_03", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["TD_COAP_CORE_03_step_04", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_03_step_05", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   5: [bbbb::1 -> bbbb::2] CoAP [CON 773] PUT /test> Match: CoAP(type=0, code=3)"], ["tat_check_2", "pass", "<Frame   5: [bbbb::1 -> bbbb::2] CoAP [CON 773] PUT /test> Match: CoAP(opt=Opt(CoAPOptionContentFormat()))"], ["tat_check_3", "pass", "<Frame   6: [bbbb::2 -> bbbb::1] CoAP [ACK 773] 2.04 Changed > Match: CoAP(code=Any(65,68), mid=0x0305, tok=b'')"]],
                            "verdict": "pass",
                            "description": "No interoperability error was detected,",
                            "testcase_id": "TD_COAP_CORE_03"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_04 was skipped.",
                            "testcase_id": "TD_COAP_CORE_04"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_05 was skipped.",
                            "testcase_id": "TD_COAP_CORE_05"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_06 was skipped.",
                            "testcase_id": "TD_COAP_CORE_06"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_07 was skipped.",
                            "testcase_id": "TD_COAP_CORE_07"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_08 was skipped.",
                            "testcase_id": "TD_COAP_CORE_08"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_09 was skipped.",
                            "testcase_id": "TD_COAP_CORE_09"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_10 was skipped.",
                            "testcase_id": "TD_COAP_CORE_10"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_11 was skipped.",
                            "testcase_id": "TD_COAP_CORE_11"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_12 was skipped.",
                            "testcase_id": "TD_COAP_CORE_12"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_13 was skipped.",
                            "testcase_id": "TD_COAP_CORE_13"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_14 was skipped.",
                            "testcase_id": "TD_COAP_CORE_14"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_15 was skipped.",
                            "testcase_id": "TD_COAP_CORE_15"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_16 was skipped.",
                            "testcase_id": "TD_COAP_CORE_16"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_17 was skipped.",
                            "testcase_id": "TD_COAP_CORE_17"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_18 was skipped.",
                            "testcase_id": "TD_COAP_CORE_18"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_19 was skipped.",
                            "testcase_id": "TD_COAP_CORE_19"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_20 was skipped.",
                            "testcase_id": "TD_COAP_CORE_20"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_21 was skipped.",
                            "testcase_id": "TD_COAP_CORE_21"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_22 was skipped.",
                            "testcase_id": "TD_COAP_CORE_22"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_23 was skipped.",
                            "testcase_id": "TD_COAP_CORE_23"
                        }, {
                            "partial_verdicts": [],
                            "verdict": "None",
                            "description": "Testcase TD_COAP_CORE_31 was skipped.",
                            "testcase_id": "TD_COAP_CORE_31"
                        }]
                    },
                    "californium-cli-vs-coapthon-server": {
                        "testname": "californium-cli-vs-coapthon-server",
                        "_api_version": "1.1.0",
                        "tc_results": [{
                            "testcase_id": "TD_COAP_CORE_01",
                            "description": " Mismatch: CoAP(opt=Opt(CoAPOptionContentFormat()))",
                            "partial_verdicts": [["TD_COAP_CORE_01_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_01_step_03", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_01_step_04", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 38140] GET /test> Match: CoAP(type=0, code=1)"], ["tat_check_2", "fail", " Mismatch: CoAP(opt=Opt(CoAPOptionContentFormat()))"]],
                            "verdict": "fail"
                        }, {
                            "testcase_id": "TD_COAP_CORE_02",
                            "description": "No interoperability error was detected,",
                            "partial_verdicts": [["TD_COAP_CORE_02_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_02_step_03", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_02_step_04", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 6089] DELETE /test> Match: CoAP(type=0, code=4)"], ["tat_check_2", "pass", "<Frame   2: [bbbb::2 -> bbbb::1] CoAP [ACK 6089] 2.02 Deleted > Match: CoAP(code=66, mid=0x17c9, tok=b')"]],
                            "verdict": "pass"
                        }, {
                            "testcase_id": "TD_COAP_CORE_03",
                            "description": " Mismatch: CoAP(code=Any(65,68), mid=0xb11a, tok=b)",
                            "partial_verdicts": [["TD_COAP_CORE_03_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_03_step_03", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["TD_COAP_CORE_03_step_04", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_03_step_05", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 45338] PUT /test> Match: CoAP(type=0, code=3)"], ["tat_check_2", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 45338] PUT /test> Match: CoAP(opt=Opt(CoAPOptionContentFormat()))"], ["tat_check_3", "inconclusive", " Mismatch: CoAP(code=Any(65,68), mid=0xb11a, tok=b"]],
                            "verdict": "inconclusive"
                        }, {
                            "testcase_id": "TD_COAP_CORE_04",
                            "description": "Testcase TD_COAP_CORE_04 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_05",
                            "description": "Testcase TD_COAP_CORE_05 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_06",
                            "description": "Testcase TD_COAP_CORE_06 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_07",
                            "description": "Testcase TD_COAP_CORE_07 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_08",
                            "description": "Testcase TD_COAP_CORE_08 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_09",
                            "description": "Testcase TD_COAP_CORE_09 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_10",
                            "description": "Testcase TD_COAP_CORE_10 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_11",
                            "description": "Testcase TD_COAP_CORE_11 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_12",
                            "description": "Testcase TD_COAP_CORE_12 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_13",
                            "description": "Testcase TD_COAP_CORE_13 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_14",
                            "description": "Testcase TD_COAP_CORE_14 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_15",
                            "description": "Testcase TD_COAP_CORE_15 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_16",
                            "description": "Testcase TD_COAP_CORE_16 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_17",
                            "description": "Testcase TD_COAP_CORE_17 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_18",
                            "description": "Testcase TD_COAP_CORE_18 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_19",
                            "description": "Testcase TD_COAP_CORE_19 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_20",
                            "description": "Testcase TD_COAP_CORE_20 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_21",
                            "description": "Testcase TD_COAP_CORE_21 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_22",
                            "description": "Testcase TD_COAP_CORE_22 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_23",
                            "description": "Testcase TD_COAP_CORE_23 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }, {
                            "testcase_id": "TD_COAP_CORE_31",
                            "description": "Testcase TD_COAP_CORE_31 was skipped.",
                            "partial_verdicts": [],
                            "verdict": "None"
                        }]
                    },
                    "coapthon-cli-vs-coapthon-server": {
                        "testname": "coapthon-cli-vs-coapthon-server",
                        "_api_version": "1.1.0",
                        "tc_results": [{
                            "testcase_id": "TD_COAP_CORE_01",
                            "verdict": "fail",
                            "partial_verdicts": [["TD_COAP_CORE_01_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_01_step_03", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_01_step_04", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 194] GET /test> Match: CoAP(type=0, code=1)"], ["tat_check_2", "fail", " Mismatch: CoAP(opt=Opt(CoAPOptionContentFormat()))"]],
                            "description": " Mismatch: CoAP(opt=Opt(CoAPOptionContentFormat()))"
                        }, {
                            "testcase_id": "TD_COAP_CORE_02",
                            "verdict": "pass",
                            "partial_verdicts": [["TD_COAP_CORE_02_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_02_step_03", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_02_step_04", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 797] DELETE /test> Match: CoAP(type=0, code=4)"], ["tat_check_2", "pass", "<Frame   2: [bbbb::2 -> bbbb::1] CoAP [ACK 797] 2.02 Deleted > Match: CoAP(code=66, mid=0x031d, tok=b'')"]],
                            "description": "No interoperability error was detected,"
                        }, {
                            "testcase_id": "TD_COAP_CORE_03",
                            "verdict": "inconclusive",
                            "partial_verdicts": [["TD_COAP_CORE_03_step_02", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_03_step_03", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["TD_COAP_CORE_03_step_04", null, "CHECK step: postponed", ""], ["TD_COAP_CORE_03_step_05", "pass", "VERIFY step: User informed that the information was displayed correclty on his/her IUT", ""], ["tat_check_1", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 237] PUT /test> Match: CoAP(type=0, code=3)"], ["tat_check_2", "pass", "<Frame   1: [bbbb::1 -> bbbb::2] CoAP [CON 237] PUT /test> Match: CoAP(opt=Opt(CoAPOptionContentFormat()))"], ["tat_check_3", "inconclusive", " Mismatch: CoAP(code=Any(65,68), mid=0x00ed, tok=b'')"]],
                            "description": " Mismatch: CoAP(code=Any(65,68), mid=0x00ed, tok=b'')"
                        }, {
                            "testcase_id": "TD_COAP_CORE_04",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_04 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_05",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_05 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_06",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_06 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_07",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_07 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_08",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_08 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_09",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_09 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_10",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_10 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_11",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_11 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_12",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_12 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_13",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_13 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_14",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_14 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_15",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_15 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_16",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_16 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_17",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_17 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_18",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_18 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_19",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_19 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_20",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_20 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_21",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_21 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_22",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_22 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_23",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_23 was skipped."
                        }, {
                            "testcase_id": "TD_COAP_CORE_31",
                            "verdict": "None",
                            "partial_verdicts": [],
                            "description": "Testcase TD_COAP_CORE_31 was skipped."
                        }]
                    }

                },
                "fixed_node_name": "nuc0-1",
                "fixed_node_urn": "urn:publicid:IDN+wilab1.ilabt.iminds.be+node+nuc0-1",
                "inMaintenanceDuringTest": false,
                "nodelogin": {
                    "node": "ftester@nuc0-1.wilab1.ilabt.iminds.be:22",
                    "stdout": "https://flsmonitor-api.fed4fire.eu/log/88153327/content",
                    "command": "ls / && uname -a && who && (netstat -antW | grep ':22 ')"
                },
                "note": ["successMethodNames=[login, ansible] warningMethodNames=[] warningMethodSuccessful=null successMethodSuccessful=true"],
                "resultHtml": "https://flsmonitor-api.fed4fire.eu/log/88153349/content",
                "resultXml": "https://flsmonitor-api.fed4fire.eu/log/88153350/content",
                "rspec-manifest-fixed-nodes": ["urn:publicid:IDN+wilab1.ilabt.iminds.be+node+nuc0-1"],
                "rspec-request-fixed-nodes": ["urn:publicid:IDN+wilab1.ilabt.iminds.be+node+nuc0-1"],
                "rspec-request-fixed-nodes-0": ["urn:publicid:IDN+wilab1.ilabt.iminds.be+node+nuc0-1"],
                "rspec0": "https://flsmonitor-api.fed4fire.eu/log/88153325/content",
                "server": "https://flsmonitor-api.fed4fire.eu/server/484",
                "steps": [{"name": "setUp", "state": "SUCCESS"}, {
                    "name": "fetchAdvertisementRspec",
                    "state": "SUCCESS"
                }, {"name": "getRspec", "state": "SUCCESS"}, {
                    "name": "findProject",
                    "state": "SUCCESS"
                }, {"name": "createExperiment", "state": "SUCCESS"}, {
                    "name": "runExperiment",
                    "state": "SUCCESS"
                }, {"name": "checkManifest", "state": "SUCCESS"}, {
                    "name": "login",
                    "state": "SUCCESS"
                }, {"name": "ansible", "state": "SUCCESS"}, {"name": "deleteExperiment", "state": "SUCCESS"}],
                "testbed": "https://flsmonitor-api.fed4fire.eu/testbed/wilab1",
                "timing": {
                    "durationMs": 262599,
                    "stopTimeMsSinceEpoch": 1528358470069,
                    "startTimeMsSinceEpoch": 1528358207470
                }
            },
            "testInstance": "https://flsmonitor-api.fed4fire.eu/testinstance/1225",
            "testDefinition": "https://flsmonitor-api.fed4fire.eu/testdefinition/autosensortest",
            "@id": "https://flsmonitor-api.fed4fire.eu/result/48265542",
            "@type": "Result"
        }
    },    // the rest of the info is kept here by testinstance-id
    errors: {},
    timestamps: {}
};


export default function testResultsReducer(state = initialState, action) {
    switch (action.type) {
        case types.TESTRESULTHISTORY_IS_LOADING:
            if (action.isLoading) {
                return {...state, isLoading: [...state.isLoading, action.testInstanceId]}
            } else {
                return {...state, isLoading: state.isLoading.filter(id => id !== action.testInstanceId)}
            }
        case types.LOAD_TESTRESULTHISTORY_SUCCESS:
            return {
                ...state,
                byTestInstanceId: {...state.byId, [action.testInstanceId]: action.result.result},
                byId: {...state.byId, ...action.result.entities.results},
                errors: {...state.errors, [action.testInstanceId]: null},
                timestamps: {...state.timestamps, [action.testInstanceId]: action.timestamp}
            };
        case types.LOAD_TESTRESULTHISTORY_ERROR:
            return {
                ...state,
                byTestInstanceId: {...state.byId, [action.testInstanceId]: null},
                errors: {...state.errors, [action.testInstanceId]: action.error},
                timestamps: {...state.timestamps, [action.testInstanceId]: action.timestamp}
            };

        case types.TESTRESULT_IS_LOADING:
            if (action.isLoading) {
                return {...state, isLoading: [...state.isLoading, action.testResultId]}
            } else {
                return {...state, isLoading: state.isLoading.filter(id => id !== action.testResultId)}
            }
        case types.LOAD_TESTRESULT_SUCCESS:
            return {
                ...state,
                byId: {...state.byId, [action.testResultId]: action.result},
                errors: {...state.errors, [action.testResultId]: null},
                timestamps: {...state.timestamps, [action.testResultId]: action.timestamp}
            };
        case types.LOAD_TESTRESULT_ERROR:
            return {
                ...state,
                byId: {...state.byId, [action.testResultId]: null},
                errors: {...state.errors, [action.testResultId]: action.error},
                timestamps: {...state.timestamps, [action.testResultId]: action.timestamp}
            };
        default:
            return state;
    }
}