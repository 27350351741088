import React from 'react';
import {connect} from 'react-redux';
import {Alert} from 'antd';
import {PropTypes} from 'prop-types';
import moment from 'moment';
import {getRrdByTestDefinitionAndPeriod, TestNames, Periods} from "../../selectors/rrd";
import FedmonUtils from "../../utils";
import HistoryChart, {percentToStatus} from "./HistoryChart";

class AggregateStatusChart extends HistoryChart {
    constructor(props) {
        super(props);

        this.title = ["Status History"];
    }

    makeSummationAlert({summation, name, ...other}) {
        return <Alert
            message={`${FedmonUtils.formatDouble(summation.successPercent, 2)}% up last ${name}`}
            description={`${summation.failure ? FedmonUtils.formatDurationShort(summation.failure * 1000.0) : "no"} downtime`}
            type={percentToStatus(summation.successPercent)} {...other} showIcon/>;
    }

    getClassForValue(value) {
        if (!value) {
            return 'color-empty';
        }
        const downtimePercentage = Math.min(value.downtime / (24 * 60 * 60 * 1000), 1);
        const colorNumber = Math.round(AggregateStatusChart.NUMBER_OF_COLORS * (1 - downtimePercentage));

        return `aggregate-color-${colorNumber}`;
    };

    getCustomTitleForValue(value) {
        if (!value)
            return "No info available";

        return "On " + moment(value.date).format('D MMMM YYYY') + " there was " + (value.downtime > 0 ? "a downtime of " + moment.duration(value.downtime).humanize() : 'no downtime');
    };
}

AggregateStatusChart.propTypes = {
    testbedId: PropTypes.string.isRequired,

};

function mapStateToProps(state, ownProps) {
    const {testbedId, testInstanceId} = ownProps;
    const rrdIsLoading = state.testbeds.isLoading.includes(testbedId);
    const statisticsIsLoading = state.testInstances.statisticsIsLoading.includes(testInstanceId);
    const rrd = state.testbeds.byId[testbedId];
    const testInstanceStatistics = state.testInstances.statisticsById[testInstanceId];

    const rrdData = {};

    let yearRrdId = null;

    if (rrd) {
        yearRrdId = getRrdByTestDefinitionAndPeriod(TestNames.AGGREGATE_STATUS, Periods.TEN_YEAR, rrd);

        rrdData[yearRrdId] = state.rrd.byId[yearRrdId];
    }

    return {yearRrdId, rrdData, testInstanceStatistics, rrdIsLoading, statisticsIsLoading}
}

export default connect(mapStateToProps)(AggregateStatusChart)
