import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {DashboardPanel} from "../../components/DashboardPanel";
import {Button, Col, Row, Alert, Card, Tooltip} from 'antd';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {
    faInfoCircle,
} from '@fortawesome/fontawesome-free-solid'
// import FedmonUtils from "../../utils";

import './AggregateStatusNow.css'

export default class AggregateStatusNow extends Component {

    static propTypes = {
        testResult: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            showDetails: false,
        }
    }

    getAlertFromStatus(status) {
        switch (status) {
            case "SUCCESS":
                return (<Alert
                    message="OK"
                    description="The testbed is OK."
                    type="success"
                    showIcon
                />);
            case "WARNING":
            case "WARN":
                return (<Alert
                    message="Warning"
                    description="The testbed has no available resources."
                    type="warning"
                    showIcon
                />);
            case "FAILURE":
            case "FAILED":
                return (<Alert
                    message="Failure"
                    description="The testbed is currently failing."
                    type="error"
                    showIcon
                />);
            case "CANCELLED":
            case "SKIPPED":
            default:
                return (<Alert
                    message="Unknown"
                    description="No info on testbed status."
                    type="warning"
                    showIcon
                />);
        }
    }

    getAlertFromHealth(healthPerc, explanation, fullExplanation) {
        let prefix = "";//"Health: ";
        let suffix = '% healthy';
        let explanationElems = explanation ? explanation.split('\n').filter(p => p.trim()).map(p => (
            <div>{p}</div>)) : [];
        if (healthPerc === null || typeof healthPerc === 'undefined' || healthPerc < 0) {
            return (<Alert
                message={prefix + "Unknown"}
                description="No info on testbed health."
                type="warning"
                showIcon
            />);
        }
        if (healthPerc === 100) {
            return (<Alert
                message={prefix + healthPerc + suffix}
                description={explanation ? explanationElems : "The testbed is OK and stable."}
                type="success"
                showIcon
            />);
        }
        if (healthPerc > 90) {
            return (<Alert
                message={prefix + healthPerc + suffix}
                description={explanation ? explanationElems : "The testbed is OK and mostly stable."}
                type="success"
                showIcon
            />);
        }
        if (healthPerc > 50) {
            return (<Alert
                message={prefix + healthPerc + suffix}
                description={explanation ? explanationElems : "The testbed is OK but not stable."}
                type="warning"
                showIcon
            />);
        }
        if (healthPerc > 10) {
            return (<Alert
                message={prefix + healthPerc + suffix}
                description={explanation ? explanationElems : "The testbed is experiencing problems."}
                type="warning"
                showIcon
            />);
        }
        return (<Alert
            message={prefix + healthPerc + suffix}
            description={explanation ? explanationElems : "The testbed is currently failing."}
            type="error"
            showIcon
        />);
    }


    render() {
        const {testResult} = this.props;

        if (!testResult) {
            return (
                <DashboardPanel title="Current Status" loading={!testResult}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        No data
                    </div>
                </DashboardPanel>
            );
        }
        let explanation = !testResult.results.testbedHealth ? null : 'explanation' in testResult.results.testbedHealth ? testResult.results.testbedHealth.explanation : null;
        let fullExplanation = !testResult.results.testbedHealth ? '' : testResult.results.testbedHealth.fullExplanation;
        let health = !testResult.results.testbedHealth ? null : testResult.results.testbedHealth.percent;

        let fullExplanationParts = fullExplanation.split('\n')
            .filter(p => p.trim())
            .map(p => (<li>{p}</li>));

        let parts = !testResult.results.testbedHealth ? [] : testResult.results.parts;
        //parts keys: ping nodelogin getVersion listResources
        //   keys for each subpart: expired failCount warnCount lastResultId

        let partOverview = [];
        ['ping', 'getVersion', 'listResources', 'nodelogin'].forEach(partKey => {
            let part = partKey in parts ? parts[partKey] : {};
            partOverview.push(
                (<Row gutter={16}>
                    <Col span={8}>{partKey + ": "}</Col>
                    <Col span={8}>
                        <div
                            class={part.failCount > 0 ? "status-detail-error" : "status-detail-success"}>{part.failCount + ""}</div>
                    </Col>
                    <Col span={8}>
                        <div
                            class={part.warnCount > 0 ? "status-detail-warning" : "status-detail-success"}>{part.warnCount + ""}</div>
                    </Col>
                </Row>)
            );
        });

        const onToggleDetails = (broken) => {
            this.setState({showDetails: !this.state.showDetails});
        };

        return (
            <DashboardPanel title="Status" loading={!testResult}
                            extra={(
                                <Button size="small" onClick={onToggleDetails}><FontAwesomeIcon
                                    icon={faInfoCircle}/>&nbsp;{this.state.showDetails ? "Hide Details" : "Show Details"}
                                </Button>)}
            >
                <div style={{marginBottom: 24}}>
                <Tooltip placement="bottom" title="Calculated using recent basic test data.">
                    <h4>Current Status: </h4>
                </Tooltip>
                {this.getAlertFromStatus(testResult.summary)}

                    {this.state.showDetails ? (<Card>
                            <Row gutter={16}>
                                {/*<Col span={8}>&nbsp;</Col>*/}
                                <Col offset={8} span={8}>Failures</Col>
                                <Col span={8}>Warnings</Col>
                            </Row>
                            {partOverview}
                        </Card>) :
                        null
                    }
                </div>

                <Tooltip placement="bottom" title="Calculated using the last month of full test data.">
                    <h4>One month status:</h4></Tooltip>
                {this.getAlertFromHealth(health, explanation, fullExplanation)}

                {this.state.showDetails ?
                    (<Card>
                        <ul>{fullExplanationParts}</ul>
                    </Card>) :
                    null
                }

            </DashboardPanel>
        );
    }
}
