import * as types from "../actions/actionTypes";
import FedmonUtils from "../utils";

const initialTestResultsState = {
    isLoading: [], //can contain both categories and testbedIds
    byTestbedId: {},
    timestamps: {}, //can contain both categories and testbedIds as keys
    errors: {}, //can contain both categories and testbedIds as keys
    updateRequestersCount: 0,
};


export default function latestTestResultsReducer(state = initialTestResultsState, action) {
    switch (action.type) {
        case types.LATEST_RESULTS_IS_LOADING:
            if (action.isLoading) {
                return {
                    ...state,
                    isLoading: [...state.isLoading, ...(action.testbedIds || []), ...(action.categories || [])]
                }
            } else {
                const isFinishedLoading = id => {
                    return (action.testbedIds && action.testbedIds.includes(id)) ||
                        (action.categories && action.categories.includes(id))
                };

                return {...state, isLoading: state.isLoading.filter(isFinishedLoading)}
            }

        case types.LOAD_LATEST_RESULTS_SUCCESS: {
            const newResultsByTestbedId = {};
            const newTimestamps = {};
            const newErrors = {};

            if (action.categories) {
                action.categories.forEach(category => {
                    newTimestamps[category] = action.timestamp;
                    newErrors[category] = null;
                });
            }

            Object.entries(action.results.entities.results).forEach(([id, newResult]) => {
                const testbedId = FedmonUtils.getIdFromUrlString(newResult.results.testbed);
                newResultsByTestbedId[testbedId] = newResultsByTestbedId[testbedId] || {};

                const testDefinitionId = newResult.testDefinition;
                newResultsByTestbedId[testbedId][testDefinitionId] = newResult;

                newTimestamps[testbedId] = action.timestamp;
                newErrors[testbedId] = null;
            });

            return {
                ...state, byTestbedId: {...state.byTestbedId, ...newResultsByTestbedId},
                errors: {...state.errors, newErrors},
                timestamps: {...state.timestamps, ...newTimestamps}
            };
        }
        case types.LOAD_LATEST_RESULTS_ERROR: {
            const newTimestamps = {};
            const newResultsByTestbedId = {};
            const newErrors = {};

            if (action.testbedIds) {
                action.testbedIds.forEach(testbedId => {
                    newTimestamps[testbedId] = action.timestamp;
                    newResultsByTestbedId[testbedId] = null;
                    newErrors[testbedId] = action.error;
                });
            }
            if (action.categories) {
                action.categories.forEach(category => {
                    newTimestamps[category] = action.timestamp;
                    newErrors[category] = action.error;
                });
            }

            return {
                ...state,
                byTestbedId: {...state.byTestbedId, ...newResultsByTestbedId},
                errors: {...state.errors, ...newErrors},
                timestamps: {...state.timestamps, ...newTimestamps}
            };
        }
        case types.SUBSCRIBE_TO_LATEST_RESULTS:
            return {...state, updateRequestersCount: state.updateRequestersCount + 1};
        case types.UNSUBSCRIBE_TO_LATEST_RESULTS:
            return {...state, updateRequestersCount: state.updateRequestersCount - 1};
        default:
            return state;
    }
}