import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './store/configureStore';
import {loadCoreInfo}from './actions/coreinfo'
import { Provider } from 'react-redux';
import {Route} from 'react-router'
import App from './App'

import { createBrowserHistory } from 'history'
import { ConnectedRouter } from 'connected-react-router'

import startTimer from './timer'

import './index.css';



const history = createBrowserHistory();

const store = configureStore(history);
store.dispatch(loadCoreInfo());
startTimer(store);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <div>
                <Route path="/" component={App}/>
            </div>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);