import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, Tooltip, Popover} from 'antd';
import {PropTypes} from 'prop-types';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {faHistory, faStepForward, faInfoCircle} from '@fortawesome/fontawesome-free-solid';
import {Button} from 'antd';
import Loading from "../../components/Loading/Loading";
import {FEDMON_BASE_URL} from "../../api/fedmonApi";
import FedmonUtils from "../../utils";

class TestInstancesList extends Component {


    constructor(props) {
        super(props);

        this.state = {
            showDetails: false,
        }
    }

    render() {
        const {testInstances, testInstancesIsLoading, lastResultsByTestInstance} = this.props;

        const onToggleDetails = (broken) => {
            this.setState({showDetails: !this.state.showDetails});
        };

        const content = [];

        if (testInstancesIsLoading) {
            content.push((<Loading key="loading"/>));
        } else if (!testInstances) {
            content.push((<div key="none">None available</div>));
        } else {
            testInstances.forEach(testInstance => {
                const lastResultId = lastResultsByTestInstance[testInstance['@id']] ? lastResultsByTestInstance[testInstance['@id']].id : 0;
                if (testInstance.enabled) {
                    content.push((
                        <li key={testInstance.id}>{testInstance.name}
                            <span> </span>
                            <Button.Group size="small">
                                { this.state.showDetails ?
                                    <Popover content={<ul>
                                        <li>ID: <a href={`${FEDMON_BASE_URL}testinstance/${testInstance.id}`}>{testInstance.id}</a></li>
                                        <li>Name: {testInstance.name}</li>
                                        <li>Origins Version: {testInstance.testVersion}</li>
                                        {/*<li>Enabled: {testInstance.enabled}</li>*/}
                                        <li>Definition: <a href={testInstance.testDefinition}>{FedmonUtils.getIdFromUrlString(testInstance.testDefinition)}</a></li>
                                        <li>Results: <a href={`/history/${testInstance.id}`}>History</a> and <a href={`/result/${lastResultId}`}>Last Result</a></li>
                                    </ul>} title="Details" trigger="click">
                                        <Button size="small" alt="Details" ><FontAwesomeIcon style={{color: 'black'}} icon={faInfoCircle}/></Button>
                                    </Popover> :
                                    null
                                }
                                <Tooltip placement="bottom" title="Browse History">
                                    <Button alt="Browse History" href={`/history/${testInstance.id}`}>
                                        <FontAwesomeIcon icon={faHistory}/></Button>
                                </Tooltip>
                                <Tooltip placement="bottom" title="Last Result">
                                    <Button alt="Last Result" disabled={lastResultId === 0}
                                            href={`/result/${lastResultId}`}><FontAwesomeIcon
                                        icon={faStepForward}/></Button>
                                </Tooltip>
                            </Button.Group>
                        </li>));
                }
            })
        }

        const showDetailsButton = <Button size="small" onClick={onToggleDetails}><FontAwesomeIcon
                icon={faInfoCircle}/>&nbsp;{this.state.showDetails ? "Hide Details" : "Show Details"}
            </Button>;

        return (
            <Card title="Tests" loading={testInstancesIsLoading} extra={showDetailsButton}>
                <div style={{maxHeight: 450, overflowY: "auto"}}>{content}</div>
            </Card>
        );

    }

}

TestInstancesList.propTypes = {
    testbedId: PropTypes.string.isRequired,
    testInstances: PropTypes.array,
    testInstancesIsLoading: PropTypes.bool.isRequired,
    lastResultsByTestInstance: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    const testbedId = ownProps.testbedId;

    const testInstances = state.testInstances.byTestbedId[testbedId];
    const testInstancesIsLoading = state.testInstances.isLoading.indexOf(testbedId) >= 0;

    const lastResults = state.latestResults.byTestbedId[testbedId];
    const lastResultsByTestInstance = {};
    if (lastResults) {
        Object.values(lastResults).forEach(result => {
            lastResultsByTestInstance[result.testInstance] = result;
        })
    }

    return {
        testInstances,
        testInstancesIsLoading,
        lastResultsByTestInstance
    }
}

export default connect(mapStateToProps)(TestInstancesList);