import * as types from "../actions/actionTypes";
import FedmonUtils from '../utils';

const initialTestResultsState = {};


export default function latestTestResultsSummaryReducer(state = initialTestResultsState, action) {
    switch (action.type) {
        case types.UPDATE_DIREVED_INFO_LATEST_RESULTS:

            const {results} = action;
            const testResultsSummary = {};

            try {
                //get all the testbed-ids which are present in these results

                const testbedShortIds =
                    new Set(Object.values(results.entities.results)
                        .map(result => FedmonUtils.getIdFromUrlString(result.results.testbed)));
                testbedShortIds
                    .forEach(testbedShortId => {
                            //get the tests for this testbed
                            const testsFromTestbed = Object.values(results.entities.results)
                                .filter((result) => FedmonUtils.getIdFromUrlString(result.results.testbed) === testbedShortId);

                            testResultsSummary[testbedShortId] = new TestbedSummary(testsFromTestbed);

                        }
                    );
            } catch (ex) {
                console.error("could not summarize:" + ex);
            }

            return {...state, ...testResultsSummary};
        default:
            return state;
    }
}

export const RESOURCE_PRECEDENCE = ["raw", "vm", "ipv4"];
export const STATE_PRECEDENCE = ["FAILURE", "WARNING", "SUCCESS", "UNAVAILABLE"];

export function stateReducer(acc, val) {
    return STATE_PRECEDENCE.indexOf(acc) < STATE_PRECEDENCE.indexOf(val) ? acc : val;
}

function compareResourceKeys(a, b) {
    if (RESOURCE_PRECEDENCE.indexOf(a[0]) < 0)
        return 1;
    else if (RESOURCE_PRECEDENCE.indexOf(b[0]) < 0)
        return -1;
    else
        return RESOURCE_PRECEDENCE.indexOf(a[0]) - RESOURCE_PRECEDENCE.indexOf(b[0]);
}

class TestbedSummary {


    constructor(results) {

        const r = {};
        results.forEach(result => {
            r[FedmonUtils.getIdFromUrlString(result.testDefinition)] = result;
        });


        //PING
        this.pingSummary = "UNAVAILABLE";
        this.pingValue = null;

        if (r.ping) {
            this.pingSummary = r.ping.summary;
            this.pingValue = r.ping.results ? Number(r.ping.results.pingValue) : -1;
        }

        //API
        this.apiSummary = "UNAVAILABLE";
        if (r.anyGetVersion && r.listResources) {
            this.anyGetVersionSummary = r.anyGetVersion.summary;
            this.listResourcesSummary = r.listResources.summary;

            if (this.anyGetVersionSummary === "SUCCESS") {
                if (this.listResourcesSummary === "SUCCESS") {
                    this.apiSummary = "SUCCESS";
                } else {
                    this.apiSummary = "WARNING";
                }
            } else {
                this.apiSummary = "FAILURE";
            }
        }

        //node login
        this.nodeloginSummary = 'UNAVAILABLE';
        if (r.repl_sum_nodelogin) {
            this.nodeloginSummary = r.repl_sum_nodelogin.summary;
            this.nodeLoginCounts = r.repl_sum_nodelogin.results.summaryCounts;
        } else if (r.nodelogin2) {
            this.nodeloginSummary = r.nodelogin2.summary;
            this.nodeLoginSteps = r.nodelogin2.results.steps;
        }else if (r.nodelogin) {
            this.nodeloginSummary = r.nodelogin.summary;
            this.nodeLoginSteps = r.nodelogin.results.steps;
        }

        //summary status
        this.overallState =
            [this.pingSummary, this.apiSummary, this.nodeloginSummary]
                .reduce(stateReducer, STATE_PRECEDENCE[STATE_PRECEDENCE.length - 1]);

        this.healthStatus = this.overallState;
        this.tileHealth = this.healthStatus;
        this.healthExplanation = "";
        this.healthFullExplanation = "";

        if (r.aggregateTestbedStatus) {
            this.overallState = r.aggregateTestbedStatus.summary;

            if (r.aggregateTestbedStatus.results.parts) {
                this.pingExpired = r.aggregateTestbedStatus.results.parts.ping && r.aggregateTestbedStatus.results.parts.ping.expired;
                this.apiExpired = (r.aggregateTestbedStatus.results.parts.getVersion && r.aggregateTestbedStatus.results.parts.getVersion.expired)
                    || (r.aggregateTestbedStatus.results.parts.listResources && r.aggregateTestbedStatus.results.parts.listResources.expired);
                this.nodeloginExpired = r.aggregateTestbedStatus.results.parts.nodelogin && r.aggregateTestbedStatus.results.parts.nodelogin.expired;
            }
            if (r.aggregateTestbedStatus.results.testbedHealth) {
                this.overallStateExplanation = r.aggregateTestbedStatus.results.testbedHealth.explanation;
                this.health = r.aggregateTestbedStatus.results.testbedHealth.percent;
                this.healthExplanation = r.aggregateTestbedStatus.results.testbedHealth.explanation;
                this.healthFullExplanation = r.aggregateTestbedStatus.results.testbedHealth.fullExplanation;

                if (this.health >= 95) {
                    this.healthStatus = "SUCCESS";

                } else if (this.health >= 50) {
                    this.healthStatus = "WARNING";
                }
                else {
                    this.healthStatus = "FAILURE";
                }

                if (this.health >= 60){
                    this.tileHealth = "SUCCESS";
                }else if (this.health >= 50){
                    this.tileHealth = "WARNING";
                }else{
                    this.tileHealth = "FAILURE";
                }
            }
        }


        //resource counts


        const counts = r.listResources && r.listResources.results.count;
        if (counts) {
            this.resourceCounts = Object.entries(counts)
                .filter(([key, count]) => key !== "summary" && count.total > 0)
                .sort(compareResourceKeys)
                .slice(0, 2)
                .map(([key, count]) => {
                    return {
                        name: key,
                        available: count.available,
                        total: count.total
                    }
                });
        } else {
            this.resourceCounts = [];
        }
    }
}