import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'antd';
import FedmonUtils from '../../../utils';

import Loading from '../../../components/Loading/index'
import AvailableResources from '../../Testbed/AvailableResources';
import {loadTestResultIfNeeded} from "../../../actions/testResults";
import {loadTestInstanceIfNeeded} from "../../../actions/testInstances";
import {loadTestDefinitionIfNeeded} from "../../../actions/testDefinition";
import {
    StepsDetail,
    TimingDetail,
    SliceDetail,
    SummaryCountDetail,
    PartsDetail,
    PingDetail,
    HealthDetail, AnsibleDetail, SelectedNodesDetail
} from "./DetailParts";
import {ResultInfo} from "./GeneralParts"


class ResultDetail extends Component {

    componentDidMount() {
        const {resultId, dispatch} = this.props;
        dispatch(loadTestResultIfNeeded(resultId));
    }

    componentDidUpdate(prevProps) {
        const {resultId, dispatch} = this.props;

        if (resultId !== prevProps.resultId) {
            dispatch(loadTestResultIfNeeded(resultId));
        }
    }

    componentWillReceiveProps(nextProps) {
        const {dispatch, result} = this.props;

        if (nextProps.result && nextProps.result !== result) {
            dispatch(loadTestInstanceIfNeeded(FedmonUtils.getIdFromUrlString(nextProps.result.testInstance)));
            dispatch(loadTestDefinitionIfNeeded(FedmonUtils.getIdFromUrlString(nextProps.result.testDefinition)));
        }
    }

    render() {
        const {result} = this.props;

        if (!result) {
            return (<Loading/>);
        }

        const rawDetails = result.results;

        let details = (<div>No details available</div>);


        if (rawDetails) {
            details = [];

            if (rawDetails.timing) {
                details.push(<TimingDetail id="timing" timing={rawDetails.timing}/>);
            }
            if (rawDetails.ansible) {
                details.push(<AnsibleDetail id="ansible" resultId={result.id} ansible={rawDetails.ansible}/>);
            }

            if (rawDetails.summaryCounts) {
                details.push(<SummaryCountDetail id="summary" summaryCount={rawDetails.summaryCounts}/>)
            }

            if (rawDetails.count) {
                details.push(<AvailableResources id="resources" testResult={result}/>);
            }

            if (rawDetails.slice) {
                details.push(<SliceDetail id="slice" slice={rawDetails.slice}/>)
            }

            if (rawDetails.pingValue) {
                details.push(<PingDetail id="ping" pingValue={rawDetails.pingValue}/>)
            }

            if (rawDetails.parts) {
                details.push(<PartsDetail id="parts" parts={rawDetails.parts}/>)
            }

            if (rawDetails.testbedHealth) {
                details.push(<HealthDetail id="health" health={rawDetails.testbedHealth}/>)
            }

            if (rawDetails['rspec-manifest-fixed-nodes'] || rawDetails['rspec-request-fixed-nodes']) {
                details.push(<SelectedNodesDetail rawDetails={rawDetails}/>)
            }
            if (rawDetails.steps) {
                details.push(<StepsDetail id="steps" resultId={result.id} steps={rawDetails.steps}/>);
            }

        }


        return (<div>
                <div style={{margin: 24}}>
                    <ResultInfo resultId={result.id} rawDetails={rawDetails}/>

                </div>

                {details.length > 0 ? (<div>
                    <div style={{margin: 24}}>
                        <h4>Details</h4>
                    </div>

                    <Row type="flex" gutter={16} style={{margin: 24}}>
                        {details.map(detail => (<Col lg={8} md={12} xs={24} key={detail.props.id} style={{marginBottom: 24}}>{detail}</Col>))}
                    </Row>
                </div>) : null}
            </div>
        )
    }

}

function mapStateToProps(state, ownProps) {
    const resultId = ownProps.match.params.resultId;
    const result = state.testResults.byId[resultId];

    return {resultId, result}
}

export default connect(mapStateToProps)(ResultDetail);