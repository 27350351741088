import React, {Component} from "react";
import PropTypes from 'prop-types';
import {parseString as parseXmlString} from 'xml2js';
import Loading from "../../../components/Loading";
import {loadTestResultIfNeeded} from "../../../actions/testResults";
import {connect} from "react-redux";
import {Element} from 'react-scroll';
import {Layout, Anchor, Alert} from "antd";

import {CallStateHeader, CallStateContent} from "./CallState";
import {scroller} from "react-scroll/modules/index";

const {Sider, Content} = Layout;

function XMLStringToObject(string) {
    return new Promise((resolve, reject) => {
        parseXmlString(string, {explicitArray: false}, (err, result) => {
            if (err) reject(err);

            return resolve(result);
        });
    });
}

class CallsInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            callReport: null,
            siderCollapsed: null,
        };
    }

    componentDidMount() {
        const {resultId, callsXml, dispatch} = this.props;
        dispatch(loadTestResultIfNeeded(resultId));

        if (callsXml) {
            this.fetchXml(callsXml);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {resultId, callsXml, dispatch, hash} = this.props;
        const {callReport} = this.state;

        if (resultId !== prevProps.resultId) {
            dispatch(loadTestResultIfNeeded(resultId));
        }

        if (callsXml !== prevProps.callsXml) {
            this.fetchXml(callsXml);
        }

        if (hash !== prevProps.hash || callReport !== prevState.callReport) {
            scroller.scrollTo(hash, {offset: -24});
        }
    }

    fetchXml(resultXml) {
        this.setState({callReport: null, hasError: false});

        fetch(resultXml)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(XMLStringToObject)
            .then(result => {
                if (result.CallReport.states.state && !(result.CallReport.states.state instanceof Array)) {
                    result.CallReport.states.state = [result.CallReport.states.state]
                }

                this.setState({callReport: result ? result : null});
            }).catch(err => {
            console.log(err);
            this.setState({hasError: true, error: err});
        });
    }

    render() {
        const {hasError, error, callReport, siderCollapsed} = this.state;

        if (hasError) {
            return (<Layout><Content className="content-wrapper"><Alert
                message="An error occured while fetching the calls info" description={error ? error.message : null}
                type="error"/></Content></Layout>);
        } else if (!callReport) {
            return (<Layout><Content className="content-wrapper"><Loading/></Content></Layout>);
        } else {
            const {title /*, reporter*/} = callReport.CallReport['$'];

            return (<Layout>
                <Sider style={{backgroundColor: "#f0f2f5"}} width={240} breakpoint="lg" theme="light" collapsedWidth={0}
                       onCollapse={(status) => this.setState({siderCollapsed: status})}>
                    {!siderCollapsed ?
                        <Anchor className="apicalls-anchor" offsetTop={24}
                                style={{paddingTop: 24, marginLeft: 24, height: '100%', backgroundColor: "#f0f2f5"}}>
                            {callReport.CallReport.states.state.map(state => {
                                const {name} = state;

                                return (
                                    <Anchor.Link href={`#${name}`} title={<CallStateHeader callState={state}/>}/>);
                            })}
                        </Anchor>
                        : null}
                </Sider>
                <Content style={{margin: 24}}>
                    <h2>{title}</h2>
                    {callReport.CallReport.states.state.map(state => {
                        const {name} = state;

                        return (<Element id={name} key={name}>
                            <CallStateContent callState={state}/></Element>);
                    })}

                </Content>
            </Layout>);
        }
    }
}

CallsInfo.propTypes = {
    resultId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    const resultId = ownProps.match.params.resultId;
    const result = state.testResults.byId[resultId];

    let callsXml = null;
    if (result && result.results) {
        callsXml = result.results.callXmlLogUrl;
    }

    const hash = state.router.location.hash ? state.router.location.hash.substring(1) : null;

    return {resultId, callsXml, hash}
}

export default connect(mapStateToProps)(CallsInfo);