import {createSelector} from "reselect";

const getCategories = (state) => state.selectableCategories;
const getEnabledCategories = (state) => Object.values(state.selectableCategories).filter(cat => cat.enabled);

export const getSortedCategories = createSelector([getCategories],
    (categories) => Object.values(categories)
        .sort((cat1, cat2) => cat1.weight - cat2.weight));

export const getSortedEnabledCategoryIds = createSelector([getEnabledCategories],
    (categories) => {
        return categories
            .sort((cat1, cat2) => cat1.weight - cat2.weight)
            .map(cat => cat.shortId)
    });

const getCategoriesParameter = (state, props) => props.match.params.category;

export const getRequestedCategoryIds = createSelector([getCategoriesParameter, getCategories],
    (enabled_categories, selectableCategories) => {
        if (enabled_categories)
            return enabled_categories.toLowerCase().split(',')
                .filter(id => selectableCategories[id] !== undefined); //only include existing categories
        else {
            return Object.entries(selectableCategories).filter(([id, cat]) => cat.enabled).map(([id, cat]) => id);
        }
    });