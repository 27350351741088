import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router';
import './App.css';
import FDHeader from './components/Header';
import {Layout} from 'antd';

import About from './modules/About/';
import Overview from './modules/Overview/';
import OverviewMap from './modules/OverviewMap/';
import Testbed from './modules/Testbed';
import Result from './modules/Result';
import TestInstanceHistory from './modules/TestInstanceHistory';
import WiLab from './modules/WiLab';
import NotFound from './modules/Error/NotFound'
import ErrorBoundary from "./ErrorBoundary";


export default class App extends Component {


    render() {
        return (
            <Layout>
                <FDHeader/>
                <ErrorBoundary key="errorbound">
                    <Switch>
                        <Route path="/about" component={About}/>
                        <Route path="/overview/:category?" component={Overview}/>
                        <Route path="/map/:category?" component={OverviewMap}/>
                        <Route path="/testbed/:testbedId" component={Testbed}/>
                        <Route path="/result/:resultId" component={Result}/>
                        <Route path="/history/:testInstanceId" component={TestInstanceHistory}/>
                        <Route path="/wilab" component={WiLab}/>
                        <Route exact path="/" render={() => (<Redirect to="/overview/"/>)}/>

                        <Route component={NotFound}/>
                    </Switch>
                </ErrorBoundary>
            </Layout>
        );
    }
}
