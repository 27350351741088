import React, {PureComponent} from 'react';
import {PropTypes} from 'prop-types';
import {Card} from 'antd';

class DashboardPanel extends PureComponent {


    render() {
        const {title, children, notes, loading, extra, footer} = this.props;
        let content;
        if (footer) {
            const foot = [ (<div style={{ "margin-top": "30px", "text-align": "center"}}>{footer}</div>) ];
            content = children.concat(foot);
        } else {
            content = children;
        }
        return (
            <Card title={title} extra={extra} actions={notes ? [notes] : null} loading={loading}>
                {content}
            </Card>
        );
    }
}

DashboardPanel.propTypes = {
    title: PropTypes.node.isRequired,
    extra: PropTypes.node,
    children: PropTypes.node.isRequired,
    notes: PropTypes.node,
    loading: PropTypes.bool,
};

export default DashboardPanel;