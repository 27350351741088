import * as types from "../actions/actionTypes";

const initialState = {
    isCoreInfoLoading: false,
    'testbeds': {
        'byId': {},
        'allIds': []
    },
    'categories': {'byId': {}},
    'servers': {'byId': {}},
    'services': {'byId': {}},
    'organisations': {'byId': {}}

};


export default function selfTestReducer(state = initialState, action) {
    switch (action.type) {
        case types.COREINFO_IS_LOADING:
            return {...state, 'isCoreInfoLoading': action.isLoading};
        case types.LOAD_COREINFO_SUCCESS:
            const coreInfoState = {
                'testbeds': {
                    'byId': action.results.entities.testbeds || {},
                    'allIds': action.results.result
                },
                'organisations': {
                    'byId': action.results.entities.organisations || {}
                }, 'categories': {
                    'byId': action.results.entities.categories || {}
                },
                'servers': {
                    'byId': action.results.entities.servers || {}
                },
                'services': {
                    'byId': action.results.entities.services || {}
                },
            };
            return {...state, ...coreInfoState, 'error': null, 'timestamp': action.timestamp};
        case types.LOAD_COREINFO_ERROR:
            return {...state, 'error': action.error, 'timestamp': action.timestamp};
        default:
            return state;
    }
}