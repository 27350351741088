import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router';
import {push} from 'connected-react-router';
import {Tag, Icon, Button, Modal, Layout} from 'antd';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';

import moment from "moment";
import FedmonUtils from '../../utils';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {faFileAlt, faFileArchive, faFileCode} from "@fortawesome/fontawesome-free-regular/index";
import {faHistory} from "@fortawesome/fontawesome-free-solid/index";


import Loading from '../../components/Loading'
import {loadTestResultIfNeeded} from "../../actions/testResults";
import {loadTestInstanceIfNeeded} from "../../actions/testInstances";
import {Page} from '../../components/Page';

import ResultDetail from './ResultDetail';
import {FInteropOverview, FInteropDetails} from './FInterop';

import './Result.css';
import CallsInfo from "./CallsInfo/CallsInfo";
import TestInfo from "./TestInfo/TestInfo";
import ResultLog from "./Logs/ResultLog";
import Ansible from "./Ansible/Ansible";

const {Content} = Layout;

function summaryToLabelStyle(summary) {
    if (!summary)
        return 'blue';
    switch (summary.toLowerCase()) {
        case 'success':
            return 'green';
        case 'warning':
            return 'orange';
        case 'failure':
            return 'volcano';
        default:
            return 'blue';
    }
}


class Result extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showLogModal: false,
            showContextModal: false,
        };
    }

    componentDidMount() {
        const {resultId, dispatch} = this.props;
        dispatch(loadTestResultIfNeeded(resultId));
    }

    componentDidUpdate(prevProps) {
        const {resultId, dispatch} = this.props;

        if (resultId !== prevProps.resultId) {
            dispatch(loadTestResultIfNeeded(resultId));
        }
    }

    componentWillReceiveProps(nextProps) {
        const {dispatch, result} = this.props;

        if (nextProps.result && nextProps.result !== result) {
            dispatch(loadTestInstanceIfNeeded(FedmonUtils.getIdFromUrlString(nextProps.result.testInstance)));
        }
    }

    getActiveTabKey() {
        const {location} = this.props;
        const locationParts = location.pathname.split('/');

        if (locationParts.length < 4 || locationParts[3] === "") {
            return 'general';
        } else if (locationParts[3] === "finterop") {
            if (locationParts.length > 4 && locationParts[4] === "details") {
                return 'finterop-details';
            } else {
                return 'finterop-overview';
            }
        }else if (locationParts[3] === "ansible") {
            if (locationParts.length > 4 && locationParts[4] === "test") {
                return 'ansible-test';
            } else {
                return 'ansible';
            }
        } else if (locationParts[3] === "calls") {
            return "calls";
        } else if (locationParts[3] === "steps") {
            return "steps";
        } else if (locationParts[3] === "logs") {
            return "logs";
        }
        return null;
    }

    getUrlForTab(key) {
        const {result} = this.props;

        if (key === 'general') {
            return `/result/${result.id}/`;
        } else if (key === 'steps') {
            return `/result/${result.id}/steps/`;
        } else if (key === 'ansible') {
            return `/result/${result.id}/ansible/`;
        } else if (key === 'ansible-test') {
            return `/result/${result.id}/ansible/test`;
        } else if (key === 'calls') {
            return `/result/${result.id}/calls/`;
        } else if (key === 'logs') {
            return `/result/${result.id}/logs/`;
        } else if (key === 'finterop-overview') {
            return `/result/${result.id}/finterop/`;
        } else if (key === 'finterop-details') {
            return `/result/${result.id}/finterop/details`;
        } else {
            return null;
        }
    }

    getNameForTab(key) {
        if (key === 'general') {
            return "General Info";
        } else if (key === 'calls') {
            return "API Calls";
        } else if (key === 'logs') {
            return "Logs";
        } else if (key === 'steps') {
            return "Test Steps";
        }else if (key === 'ansible') {
            return "Ansible";
        }else if (key === 'ansible-test') {
            return "Test Ansible";
        } else if (key === 'finterop-overview') {
            return "F-Interop Overview";
        } else if (key === 'finterop-details') {
            return "F-Interop Details";
        } else {
            return null;
        }
    }

    getIconForTab(key) {
        if (key === 'general') {
            return <Icon type="info-circle-o" />;
        } else if (key === 'calls') {
            return <Icon type="api" />;
        } else if (key === 'logs') {
            return <Icon type="file-text" />;
        } else if (key === 'steps') {
            return <Icon type="bars" />;
        }else if (key === 'ansible') {
            return <img alt="" src="/assets/icons/ansible.svg" className="result-tab-image"  />;
        }else if (key === 'ansible-test') {
            return <img alt="" src="/assets/icons/ansible.svg" className="result-tab-image"  />;
        } else if (key === 'finterop-overview') {
            return <img alt="" src="/assets/img/finterop-logo.svg" className="result-tab-image" />;
        } else if (key === 'finterop-details') {
            return <img alt="" src="/assets/img/finterop-logo.svg" className="result-tab-image" />;
        } else {
            return null;
        }
    }

    createBreadcrumbs() {
        const {result, testbed, testInstance} = this.props;

        const breadcrumbs = [{
            title: (<Icon type="home"/>),
            href: '/',
        }];
        if (testbed) {
            breadcrumbs.push({
                title: testbed.longName,
                href: `/testbed/${testbed.id}`,
            });
        }

        if (testInstance) {
            breadcrumbs.push({
                title: testInstance.name,
                href: `/history/${testInstance.id}`,
            });
        }
        breadcrumbs.push({
            title: `Result #${result.id}`,
            href: `/result/${result.id}`,
        });

        const activeTabKey = this.getActiveTabKey();

        if (activeTabKey) {
            breadcrumbs.push({
                title: this.getNameForTab(activeTabKey),
                href: this.getUrlForTab(activeTabKey)
            })
        }

        return breadcrumbs;
    }

    createTabList() {
        const {result, testInstance} = this.props;

        const tabKeys = ['general'];
        if (result && result.results && result.results.resultXml) {
            tabKeys.push('steps');
        }
        if (result && result.results && result.results.ansible && result.results.ansible.output) {
            tabKeys.push('ansible');
        }
        if (result && result.results && result.results.ansible && result.results.ansible.output2) {
            tabKeys.push('ansible-test');
        }
        if (result && result.results && result.results.callXmlLogUrl) {
            tabKeys.push('calls');
        }
        if (result && result.results && result.results.xmlLogUrl) {
            tabKeys.push('logs');
        }
        if (testInstance && testInstance.id === 1413) {
            tabKeys.push('finterop-overview');
            tabKeys.push('finterop-details');
        }

        return tabKeys.map(key => {
            const icon = this.getIconForTab(key);
            const name = this.getNameForTab(key);

            const tab = (<span>{icon? icon: null} {name}</span>);

            return {key, tab}
        });
    }

    render() {
        const {result, testInstance, isCoreInfoLoading, dispatch} = this.props;

        if (!result || isCoreInfoLoading) {
            return (<Layout><Content className="content-wrapper"><Loading/></Content></Layout>);
        }

        const breadcrumbs = this.createBreadcrumbs();
        const tabList = this.createTabList();

        const image = (<div className="icon-header-image"><Icon type="file-text"/></div>);

        const title = (<span>Result &#x23;{result.id} <Tag
            color={summaryToLabelStyle(result.summary)}>{result.summary}</Tag></span>);

        const {created, expire} = result;
        const description = (<DescriptionList col={2}>
            <DescriptionList.Description
                term="Created">{moment(created).format('LLL')}</DescriptionList.Description>
            <DescriptionList.Description
                term="Expires">{moment(expire).format('LLL')}</DescriptionList.Description>
        </DescriptionList>);

        const action = (<Button.Group>
            <Button onClick={() => this.setState({showLogModal: true})}>
                <FontAwesomeIcon icon={faFileAlt}/> Log</Button>
            {testInstance && testInstance.id ? (
                <Button href={`/history/${testInstance.id}`}>
                    <FontAwesomeIcon icon={faHistory}/> History</Button>
            ) : []}
        </Button.Group>);

        let extra = [];

        const rawDetails = result.results;

        if (rawDetails) {
            extra = (<Button.Group>
                {rawDetails.contextFile ? (
                    <Button onClick={() => this.setState({showContextModal: true})}>
                        <FontAwesomeIcon icon={faFileAlt}/> Context</Button>
                ) : []}

                {rawDetails.resultHtml ? (
                    <Button href={rawDetails.resultHtml}>
                        <FontAwesomeIcon icon={faFileArchive}/> Detailed Result HTML</Button>
                ) : []}
                {rawDetails.resultXml ? (
                    <Button href={rawDetails.resultXml}>
                        <FontAwesomeIcon icon={faFileCode}/> Detailed Result XML</Button>
                ) : []}
                {rawDetails.callHtmlLogUrl ? (
                    <Button href={rawDetails.callHtmlLogUrl}>
                        <FontAwesomeIcon icon={faFileArchive}/> Call HTML Log</Button>
                ) : []}
                {rawDetails.callXmlLogUrl ? (
                    <Button href={rawDetails.callXmlLogUrl}>
                        <FontAwesomeIcon icon={faFileCode}/> Call XML Log</Button>
                ) : []}
                {rawDetails.rspecUrl ? (
                    <Button href={rawDetails.rspecUrl}>
                        <FontAwesomeIcon icon={faFileCode}/> RSpec</Button>
                ) : []}

            </Button.Group>);
        }


        return (
            <Page title={title} subtitle={description} image={image} roundedImage={true}
                  breadcrumbs={breadcrumbs}
                  action={action} extraContent={extra}
                  tabList={tabList.length > 1 ? tabList : null} tabActiveKey={this.getActiveTabKey()}
                  onTabChange={key => dispatch(push(this.getUrlForTab(key)))}

                  headerClassName="resultdetail-header">

                <Modal visible={this.state.showLogModal}
                       title="Log"
                       footer={(<Button href={result.logUrl}>Source</Button>)}
                       onCancel={() => this.setState({showLogModal: false})}
                       width={768}>
                    <iframe title={`Result log ${result.id}`}
                            src={result.logUrl}
                            style={{width: '100%', minHeight: '500px', height: '100%'}}/>

                </Modal>
                <Modal visible={this.state.showContextModal} width={768}
                       onCancel={() => this.setState({showContextModal: false})}
                       title="Context" footer={(<Button href={rawDetails.contextFile}>Source</Button>)}>
                    <iframe title={`Context for test ${result.id}`}
                            src={rawDetails.contextFile}
                            style={{width: '100%', minHeight: '500px', height: '100%'}}/>
                </Modal>

                <Switch>
                    <Route path="/result/:resultId/finterop/details" component={FInteropDetails}/>
                    <Route path="/result/:resultId/finterop/" component={FInteropOverview}/>
                    <Route path="/result/:resultId/ansible/:type?" component={Ansible}/>
                    <Route path="/result/:resultId/steps" component={TestInfo}/>
                    <Route path="/result/:resultId/calls" component={CallsInfo}/>
                    <Route path="/result/:resultId/logs" component={ResultLog}/>
                    <Route path="/result/:resultId/" component={ResultDetail}/>
                </Switch>
            </Page>
        )
    }

}

function mapStateToProps(state, ownProps) {
    const resultId = ownProps.match.params.resultId;
    const result = state.testResults.byId[resultId];

    let testInstance = null;

    let server = null;
    let testbed = null;

    if (result) {
        testInstance = state.testInstances.byId[FedmonUtils.getIdFromUrlString(result.testInstance)];

        if (result.results && result.results.server) {
            server = state.coreInfo.servers.byId[result.results.server]
        }
        if (server && server.testbed) {
            testbed = state.coreInfo.testbeds.byId[server.testbed];
        }
    }

    const isCoreInfoLoading = state.coreInfo.isCoreInfoLoading;
    return {resultId, result, testInstance, server, testbed, isCoreInfoLoading}

}

export default connect(mapStateToProps)(Result);