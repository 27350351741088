import React, {Component} from 'react';
import {PropTypes} from 'prop-types';

import Loading  from '../../components/Loading'
import moment from 'moment';
import {Line} from 'react-chartjs-2';

export default class PingGraph extends Component {

    static propTypes = {
        pingResults: PropTypes.array.isRequired
    };

    render() {

        const {pingResults} = this.props;

        if (!pingResults || pingResults.length === 0) {
            return (<div><Loading /></div>);
        }

        const chartData = {
            labels: pingResults.map(d => d.datetime),
            datasets: [{
                label: "Ping",
                data: pingResults.map(d => d.pingValue),
                xAxisID: "datetime",
                yAxisID: "pingValue",
                fill: false,
                lineTension: 0.1,
                borderColor: "#8a8ad6",
                pointRadius: 0,
            }]
        };

        const chartOptions = {
            legend: {
                display: false,
            },
            tooltips: {
                callbacks: {
                    title: (tti, data) => {
                        return moment(tti[0].xLabel).format('LLL');
                    },
                    label: (tti, data) => {
                        return tti.yLabel + " ms";
                    }
                }
            },
            animation: {
                animateScale: true,
            },
            hover: {
                mode: "x",
                intersect: false,
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    id: "datetime"
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'ms'
                    },
                    id: "pingValue"
                }]
            }
        };


        return (
            <Line options={chartOptions} data={chartData} height={100}/>
        );
    }
}