import React, {Component} from 'react';
import TestbedTile from "../../components/TestbedTile/TestbedTile";
import {Layout, Row, Col} from 'antd';

const {Content} = Layout;

class About extends Component {

    render() {

        const exampleOkTestbedSummary = {
            pingValue: 5,
            pingSummary: "SUCCESS",
            pingExpired: false,

            apiValue: "API status info",
            apiSummary: "SUCCESS",
            apiExpired: false,

            internalStatusValue: 5,
            internalStatusSummary: "SUCCESS",
            internalStatusExpired: false,


            nodeloginValue: 5,
            nodeloginSummary: "SUCCESS",
            nodeloginExpired: false,

            overallState: "SUCCESS",
            overallStateExplanation: "You will find info on the global status here",
            resourceCounts: [{available: 12, total: 15, name: "Raw"},
                {available: 85, total: 200, name: "VM"}],

            health: 100,
            tileHealth: "SUCCESS",
            healthStatus: "SUCCESS",
        };

        const exampleWarningTestbedSummary = {
            pingValue: 5,
            pingSummary: "SUCCESS",
            pingExpired: false,

            apiValue: "API status info",
            apiSummary: "SUCCESS",
            apiExpired: false,

            internalStatusSummary: "SUCCESS",
            internalStatusExpired: true,


            nodeloginSummary: "SUCCESS",
            nodeloginExpired: true,

            overallState: "WARNING",
            overallStateExplanation: "You will find info on the global status here",
            resourceCounts: [{available: 12, total: 15, name: "Raw"},
                {available: 85, total: 200, name: "VM"}],


            health: 80,
            tileHealth: "WARNING",
            healthStatus: "WARNING",
        };

        const exampleFailingTestbedSummary = {
            pingValue: 5,
            pingSummary: "SUCCESS",
            pingExpired: false,

            apiValue: "API status info",
            apiSummary: "WARNING",
            apiExpired: false,

            internalStatusValue: 5,
            internalStatusSummary: "WARNING",
            internalStatusExpired: false,


            nodeloginValue: 5,
            nodeloginSummary: "FAILURE",
            nodeloginExpired: false,

            overallState: "FAILURE",
            overallStateExplanation: "You will find info on the global status here",
            resourceCounts: [{available: 12, total: 15, name: "Raw"},
                {available: 85, total: 200, name: "VM"}],

            health: 10,
            tileHealth: "FAILURE",
            healthStatus: "FAILURE",
        };

        return (<Layout>
                <Content className="content-wrapper">
                    <h1>About the Fed4FIRE Federation Monitor<img src="./assets/img/fedmon-logo.svg" alt="" style={{
                        height: 60,
                        paddingLeft: 10
                    }}/></h1>
                    <p>The federation monitor continuously monitors the resources of testbeds federated in Fed4FIRE
                        testbed
                        federation.</p>
                    <p>The dashboard on this website shows a summary of the current status of each testbed. You can also
                        consult the history and details of each test on the detail pages.</p>

                    <h3>About the dashboard</h3>

                    <p>On the dashboard each testbed is displayed as a tile which shows a summary of the current testbed
                        status.</p>
                    <p>The color of the tile shows the overall current status: green for ok, amber for warning, and red
                        for
                        failing.</p>

                    <Row>
                        <Col sm={24} md={12} lg={8}>
                            <TestbedTile testbedName="OK Example" testResultsSummary={exampleOkTestbedSummary}/>
                        </Col>
                        <Col sm={24} md={12} lg={8}>
                            <TestbedTile testbedName="Warning Example"
                                         testResultsSummary={exampleWarningTestbedSummary}/>
                        </Col>
                        <Col sm={24} md={12} lg={8}>
                            <TestbedTile testbedName="Failing Example"
                                         testResultsSummary={exampleFailingTestbedSummary}/>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

export default About;