import React from 'react';
import {connect} from 'react-redux';
import {Alert} from 'antd';
import moment from 'moment';
import {getRrdByTestDefinitionsAndPeriod, TestNames, Periods} from "../../selectors/rrd";
import FedmonUtils from "../../utils";
import HistoryChart, {percentToStatus} from "./HistoryChart";

class LoginStatusChart extends HistoryChart {
    constructor(props) {
        super(props);

        this.title = ["NodeLogin Test History"];
    }

    makeSummationAlert({summation, name, ...other}) {
        return <Alert
            message={`${FedmonUtils.formatDouble(summation.successPercent, 2)}% nodelogin success last ${name}`}
            description={`Tests: ${Math.ceil(summation.failure)+" fail"}${summation.warning ? ", "+Math.ceil(summation.warning)+" warning" : ''}, ${Math.ceil(summation.success)+" succeed"} `}
            type={percentToStatus(summation.successPercent)} {...other} showIcon/>;
    }

    getClassForValue(value) {
        if (!value) {
            return 'color-empty';
        }
        var colorNumber = -1;
        if (value.ok) {
            colorNumber = 4;
        }
        if (value.warn) {
            colorNumber = 3;
        }
        if (value.warn > 0.9) {
            colorNumber = 2;
        }
        if (value.fail) {
            colorNumber = 1;
        }
        if (value.fail > 0.9) {
            colorNumber = 0;
        }
        if (colorNumber === -1) {
            return 'color-empty';
        }

        return `aggregate-color-${colorNumber}`;
    };

    getCustomTitleForValue(value) {
        if (!value)
            return "No info available";

        let res = [];
        res.push(<div key="titforvalhead">{moment(value.date).format('D MMMM YYYY')} login test totals:</div>);
        let ul = [];
        if (value.ok) {
            ul.push(<li key="titforvalsucc">{"Success: " + FedmonUtils.formatDouble(value.ok * 100.0, 3) + " %"}</li>);
        }
        if (value.warn) {
            ul.push(<li key="titforvalwarn">{"Warning: " + FedmonUtils.formatDouble(value.warn * 100.0, 3) + " %"}</li>);
        }
        if (value.fail) {
            ul.push(<li key="titforvalfail">{"Failure: " + FedmonUtils.formatDouble(value.fail * 100.0, 3) + " %"}</li>);
        }
        if (ul) {
            res.push(<ul key="titforvalul">{ul}</ul>);
        } else {
            res.push(<div key="titforvalnoul">No results.</div>)
        }
        return <div>{res}</div>;
    };

}

function mapStateToProps(state, ownProps) {
    const {testbedId, testInstanceId} = ownProps;
    const rrdIsLoading = state.testbeds.isLoading.includes(testbedId);
    const statisticsIsLoading = state.testInstances.statisticsIsLoading.includes(testInstanceId);
    const rrd = state.testbeds.byId[testbedId];
    const testInstanceStatistics = state.testInstances.statisticsById[testInstanceId];

    const rrdData = {};

    let yearRrdId = null;

    if (rrd) {
        yearRrdId = getRrdByTestDefinitionsAndPeriod([TestNames.NODELOGIN2LONG, TestNames.NODELOGIN2, TestNames.NODELOGIN], Periods.TEN_YEAR, rrd, testInstanceId);

        rrdData[yearRrdId] = state.rrd.byId[yearRrdId];
    }


    return {yearRrdId, rrdData, testInstanceStatistics, rrdIsLoading, statisticsIsLoading}
}

export default connect(mapStateToProps)(LoginStatusChart)
