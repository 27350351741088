import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {Col, Row} from 'antd'
import './TilesCategory.css';
import {TestbedTileContainer} from '../TestbedTile';
import Loading from "../Loading/Loading";

class TilesCategory extends Component {

    render() {
        const {selectableCategory, testbeds, isCoreInfoLoading, latestResultsTimestamp} = this.props;
        let content = [];


        if (isCoreInfoLoading || !latestResultsTimestamp) {
            content = (<Loading/>);
        } else if(testbeds.length === 0){
            content = (<i>Empty category</i>)
        }else {
            content = testbeds.map(testbed =>
                (<Col key={testbed.id} xs={24} sm={12} md={12}  lg={8} xl={6}>
                    <TestbedTileContainer testbedId={testbed['@id']}/>
                </Col>)
            );
        }


        return (
            <div className="tilesCategory">
                <h1>{selectableCategory.name}</h1>
                <div className="container-fluid">
                    <Row gutter={16}>{content}
                    </Row>
                </div>
                {/*<div className="update-timer" />*/}
            </div>
        );
    }
}


TilesCategory.propTypes = {
    categoryShortId: PropTypes.string.isRequired,
    selectableCategory: PropTypes.object.isRequired,
    testbeds: PropTypes.array.isRequired, //testbeds in the category
    isCoreInfoLoading: PropTypes.bool.isRequired,
    latestResultsIsLoading: PropTypes.bool.isRequired,
    latestResultsTimestamp: PropTypes.number
};

function filterTestbedsFromCategory(testbeds, category) {
    if (!category)
        return [];


    return Object.values(testbeds.byId)
        .filter(testbed => testbed.categories.includes(category['@id']))
        .sort((testbedA, testbedB) => testbedA.longName.localeCompare(testbedB.longName));
}

function mapStateToProps(state, ownProps) {
    const {categoryShortId} = ownProps;
    const selectableCategory = state.selectableCategories[categoryShortId];
    const category = state.coreInfo.categories.byId[selectableCategory.id];

    return {
        isCoreInfoLoading: state.coreInfo.isCoreInfoLoading,
        selectableCategory,
        testbeds: filterTestbedsFromCategory(state.coreInfo.testbeds, category),
        latestResultsIsLoading: state.latestResults.isLoading.includes(categoryShortId),
        latestResultsTimestamp: state.latestResults.timestamps[categoryShortId],
    };
}

export default connect(mapStateToProps)(TilesCategory);
