import * as types from "../actions/actionTypes";

const initialState = {
    isLoading: [], //list of testbed-ids for which the info is loading
    byId: {},   // the rest of the info is kept here by testbed-id
    errors: {},
    timestamps: {}
};


export default function testbedsReducer(state = initialState, action) {
    switch (action.type) {
        case types.TESTBED_IS_LOADING:
            if (action.isLoading) {
                return {...state, isLoading: [...state.isLoading, action.testbedId]}
            } else {
                return {...state, isLoading: state.isLoading.filter(id => id !== action.testbedId)}
            }
        case types.LOAD_TESTBED_SUCCESS:
            return {
                ...state,
                byId: {...state.byId, [action.testbedId]: action.results},
                errors: {...state.errors, [action.testbedId]: null},
                timestamps: {...state.timestamps, [action.testbedId]: action.timestamp}
            };
        case types.LOAD_TESTBED_ERROR:
            return {
                ...state,
                byId: {...state.byId, [action.testbedId]: null},
                errors: {...state.errors, [action.testbedId]: action.error},
                timestamps: {...state.timestamps, [action.testbedId]: action.timestamp}
            };
        default:
            return state;
    }
}