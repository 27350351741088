import React, {Component} from "react";
import {Tooltip} from "antd";
import moment from "moment";
import PropTypes from "prop-types";

export class TimeWithTooltip extends Component {
    render() {
        const {time, format, tooltipFormat} = this.props;

        return (<Tooltip
            title={tooltipFormat ? moment(time).format(tooltipFormat) : moment(time).toISOString()}>{moment(time).format(format ? format : "lll")}</Tooltip>);
    }
}

TimeWithTooltip.propTypes = {
    time: PropTypes.number.isRequired,
    format: PropTypes.string,
    tooltipFormat: PropTypes.string,
};