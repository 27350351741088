import React, {Component} from 'react';
import {connect} from "react-redux";
import {loadTestResultIfNeeded} from "../../../actions/testResults";
import {scroller} from "react-scroll/modules/index";
import {Alert, Anchor, Layout} from "antd";
import Loading from "../../../components/Loading";

import ReactJson from 'react-json-view'

import './Ansible.css';
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import {faCheckCircle, faExclamationCircle, faStopCircle, faTimesCircle} from "@fortawesome/fontawesome-free-solid";

const {Sider, Content} = Layout;

const parseAnsible = (ansibleOutput) => {
    return new Promise(function (resolve, reject) {

        const taskRegex = /TASK \[(.*?)\] \**\n(?:.*\n)*?(\w+): \[(.*?)\](?:: FAILED!)?(?:(?: =>)? (\(.*?\)))?(?: => ((?:\{.*?\}$|\{\n[\s\S]*?^\}))?)?/mg;

        const tasks = [];

        let match;
        while ((match = taskRegex.exec(ansibleOutput))) {
            let info = match[5];

            if (info) {
                try {
                    info = JSON.parse(info);
                } catch (err) {
                    console.error("Could not parse ansible output info into JSON", err, info);
                }
            }


            tasks.push({
                name: match[1],
                status: match[2],
                host: match[3],
                args: match[4],
                info,
                startIndex: match.index,
                length: match[0].length
            });

        }

        resolve({output: ansibleOutput, tasks});
    });
};

function taskStatusIcon(status) {
    switch (status) {
        case "ok":
            return (<FontAwesomeIcon type="success" icon={faCheckCircle} style={{color: 'limegreen'}}/>);
        case "changed":
            return (<FontAwesomeIcon type="success" icon={faCheckCircle} style={{color: 'green'}}/>);
        case "warning":
            return (<FontAwesomeIcon type="warn" icon={faExclamationCircle} style={{color: 'orange'}}/>);
        case "fatal":
            return (<FontAwesomeIcon type="failure" icon={faTimesCircle} style={{color: 'orangered'}}/>);
        case "skipping":
        default:
            return (<FontAwesomeIcon type="skipped" icon={faStopCircle} style={{color: 'gray'}}/>);
    }
}

class AnsibleOutput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            output: null,
            tasks: [],
            hasError: false,
            error: null,
            siderCollapsed: false,
        }
    }

    componentDidMount() {
        const {resultId, outputUrl, dispatch} = this.props;
        dispatch(loadTestResultIfNeeded(resultId));

        if (outputUrl) {
            this.fetchOutput(outputUrl);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {resultId, outputUrl, dispatch, hash} = this.props;
        const {output} = this.state;

        if (resultId !== prevProps.resultId) {
            dispatch(loadTestResultIfNeeded(resultId));
        }

        if (outputUrl !== prevProps.outputUrl) {
            this.fetchOutput(outputUrl);
        }

        if (hash !== prevProps.hash || output !== prevState.output) {
            scroller.scrollTo(hash, {offset: -24});
        }
    }

    fetchOutput(outputUrl) {
        this.setState({output: null, tasks: []});

        fetch(outputUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                } else {
                    console.log("returning text");
                    return response.text()
                }
            })
            .then(response => parseAnsible(response))
            .then((ansible) => {
                this.setState({output: ansible.output, tasks: ansible.tasks});
            }).catch(err => {
            console.log(err);
            this.setState({hasError: true, error: err})
        });
    }

    renderPart(task, output) {

        let info = null;

        if (task.info instanceof String) {
            info = <pre>{task.info}</pre>
        } else if (task.info instanceof Object) {
            info = <ReactJson src={task.info} name={null} enableClipboard={false} displayDataTypes={false}
                              theme={{
                                  base00: "white",
                                  base01: "#ddd",
                                  base02: "#ddd",
                                  base03: "#444",
                                  base04: "purple",
                                  base05: "#444",
                                  base06: "#444",
                                  base07: "#444",
                                  base08: "#444",
                                  base09: "rgba(70, 70, 230, 1)",
                                  base0A: "rgba(70, 70, 230, 1)",
                                  base0B: "rgba(70, 70, 230, 1)",
                                  base0C: "rgba(70, 70, 230, 1)",
                                  base0D: "rgba(70, 70, 230, 1)",
                                  base0E: "rgba(70, 70, 230, 1)",
                                  base0F: "rgba(70, 70, 230, 1)"
                              }}/>
        }

        return (
            <div className="ansible-part">
                <h4>TASK:
                    [{task.name}] {"*".repeat(Math.max(0, 80 - 9 /*for 'TASK: [' and '] '*/ - task.name.length))}</h4>
                <p>{taskStatusIcon(task.status)} {task.status} on {task.host}
                    {task.args ? <span><br/><strong>with arguments:</strong> {task.args}</span> : null}
                </p>
                {info}
            </div>);


    }

    render() {
        const {hasError, error, output, tasks, siderCollapsed} = this.state;

        if (hasError) {
            return (<Layout><Content className="content-wrapper"><Alert
                message="An error occured while fetching the calls info" description={error ? error.message : null}
                type="error"/></Content></Layout>);
        } else if (!output) {
            return (<Layout><Content className="content-wrapper"><Loading/></Content></Layout>);
        } else {
            const resultSpans = [];

            if (tasks && tasks.length > 0 && tasks[0].startIndex > 0) {
                resultSpans.push(<div key={`before-part0`} id={`before-part0`}
                                      className="other-part">{output.substr(0, tasks[0].startIndex)}</div>)

            }

            for (let i = 0; i < tasks.length; i++) {
                const currentPart = tasks[i];
                const nextPart = (i + 1 < tasks.length) ? tasks[i + 1] : null;

                resultSpans.push(<div key={`part${i}`} id={`part${i}`}>{this.renderPart(currentPart, output)}</div>);


                const endIndex = currentPart.startIndex + currentPart.length;
                if (nextPart) {
                    if (endIndex < nextPart.startIndex) {
                        resultSpans.push(<div key={`after-part${i}`} id={`after-part${i}`}
                                              className="other-part">{output.substr(endIndex, nextPart.startIndex - endIndex)}</div>)
                    }
                } else {
                    resultSpans.push(<div key={`after-part${i}`} id={`after-part${i}`}
                                          className="other-part">{output.substr(endIndex + 1)}</div>)

                }
            }


            return (<Layout>
                <Sider style={{backgroundColor: "#f0f2f5"}} width={280} breakpoint="lg" collapsedWidth={0}
                       onCollapse={(status) => this.setState({siderCollapsed: status})}>
                    {!siderCollapsed ? (
                        <Anchor className="parts-anchor">
                            {tasks.map((part, index) =>
                                (<Anchor.Link key={`link-part${index}`} href={`#part${index}`}
                                              title={<span>{taskStatusIcon(part.status)} {part.name}</span>}>
                                    {/*too many results: finteroptest.tc_results.map(tc => (<Anchor.Link key={tc.testcase_id} href={`#${finteroptest.testname}-${tc.testcase_id}`}
                                 title={tc.testcase_id} />))*/}
                                </Anchor.Link>))}

                        </Anchor>) : null}
                </Sider>
                <Content className="content-wrapper">
                    <div className="ansible-output">{resultSpans}</div>
                </Content></Layout>)

        }
    }
}

AnsibleOutput.propTypes = {};

function mapStateToProps(state, ownProps) {
    const resultId = ownProps.match.params.resultId;
    const typeParam = ownProps.match.params.type;

    const result = state.testResults.byId[resultId];

    let outputUrl = null;
    if (result && result.results && result.results.ansible) {
        if (typeParam === "test")
            outputUrl = result.results.ansible.output2;
        else
            outputUrl = result.results.ansible.output;
    }

    const hash = state.router.location.hash ? state.router.location.hash.substring(1) : null;

    return {resultId, outputUrl, hash}
}

export default connect(mapStateToProps)(AnsibleOutput);