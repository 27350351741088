import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {Layout, Table, Breadcrumb, Icon} from 'antd';
import Loading from '../../components/Loading'
import moment from 'moment';
import {Link, withRouter} from 'react-router-dom';
import FedmonUtils from '../../utils';

import './TestInstanceHistory.css'
import {loadTestInstanceIfNeeded} from "../../actions/testInstances";
import {loadTestResultHistoryIfNeeded} from "../../actions/testResults";
import {loadTestDefinitionIfNeeded} from "../../actions/testDefinition";

const {Content} = Layout;

const SPECIAL_PAGES = {
    1413:
        {
            icon: (<img src="/assets/img/finterop-logo.svg" style={{height: "15px"}} alt="F-Interop test"/>),
            name: "F-Interop",
            createUrl: resultId => `/result/${resultId}/finterop`,

        }
};


class TestInstanceHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            summaryFilter: null,
            sortedInfo: {},
        };
    }

    componentDidMount() {
        const {testInstanceId, dispatch} = this.props;

        dispatch(loadTestInstanceIfNeeded(testInstanceId));
        dispatch(loadTestResultHistoryIfNeeded(testInstanceId));
    }


    componentDidUpdate(prevProps) {
        const {testInstanceId, dispatch} = this.props;
        if (testInstanceId !== prevProps.testInstanceId) {
            dispatch(loadTestInstanceIfNeeded(testInstanceId));
            dispatch(loadTestResultHistoryIfNeeded(testInstanceId));
        }
    }

    componentWillReceiveProps(nextProps) {
        const {dispatch} = this.props;

        if (nextProps.testInstance) {
            dispatch(loadTestDefinitionIfNeeded(FedmonUtils.getIdFromUrlString(nextProps.testInstance.testDefinition)))
        }
    }

    renderActions(id) {
        const {testInstanceId} = this.props;

        const actions = [{
            url: `/result/${id}`,
            text: "Details"
        }];

        if (SPECIAL_PAGES[testInstanceId]) {
            const specialpage = SPECIAL_PAGES[testInstanceId];

            actions.push({
                url: specialpage.createUrl(id),
                text: specialpage.name,
            })
        }

        return (<ul className="testinstancehistory-actions">{
            actions.map(action => (<li key={action.text}><span><Link to={action.url}>{action.text}</Link></span></li>))
        }</ul>);
    };

    render() {
        const {testInstanceId, testDefinition, testInstance, server, testbed, results, isCoreInfoLoading} = this.props;
        const {summaryFilter, sortedInfo} = this.state;

        if (!testInstance || isCoreInfoLoading) {
            return (<Layout><Content className="content-wrapper"><Loading/></Content></Layout>);
        }

        const {name} = testInstance;


        const resultsData = results.map(result => {
            return {
                key: result.id,
                id: result.id,
                created: result.created,
                duration: result.results.timing.durationMs,
                summary: result.summary ? result.summary.substr(0,1) + result.summary.substr(1).toLowerCase() : null,
            }
        });

        const summaryFilters = [...new Set(resultsData.map(result => result.summary))].map(summary => {
            return {
                text: summary,
                value: summary
            }
        });

        const summaryType = {
            "SUCCESS": "Success",
            "WARNING": "Warning",
            "FAILURE": "Failure",
            "CANCELLED": "Cancelled",
        };

        const testInstanceDescription = [];
        if (testDefinition) {
            testInstanceDescription.push(<span key="testDefinitionName">
                '{testDefinition.geniDatastoreDescription}'</span>);
        }
        if (testbed && server) {
            testInstanceDescription.push(<span key="testInstanceLocation"> on <Link
                to={`/testbed/${testbed.id}`}>{server.name}</Link></span>);
        }

        const columns = [{
            dataIndex: 'created',
            key: 'created',
            title: 'Start',
            sorter: (a,b) => moment(a.created) - moment(b.created),
            sortOrder: sortedInfo.columnKey === 'created' && sortedInfo.order,
            render: text => moment(text).format('LLLL')
        }, {
            dataIndex: 'duration',
            key: 'duration',
            title: 'Duration',
            sorter: (a,b) => a.duration - b.duration,
            sortOrder: sortedInfo.columnKey === 'duration' && sortedInfo.order,
            render: text => FedmonUtils.formatDuration(text)
        }, {
            dataIndex: 'summary',
            key: 'summary',
            title: 'Summary',
            filters: summaryFilters,
            filteredValue: summaryFilter,
            onFilter: (activeSummaries, result) => activeSummaries.includes(result.summary),
            render: text => (<div className={`summarycell-${summaryType[text]}`}>{text}</div>)
        }, {
            dataIndex: 'id',
            key: 'actions',
            title: 'Actions',
            render: (id) => this.renderActions(id)
        }];

        const onTableChange = (pagination, filters, sorter) => {
            this.setState({
                summaryFilter: filters.summary || [],
                sortedInfo: sorter || {},
            });
        };

        return (
            <Layout className="page-with-breadcrumb-wrapper">
                <Breadcrumb className="breadcrumb-wrapper">
                    <Breadcrumb.Item href="/">
                        <Icon type="home"/>
                    </Breadcrumb.Item>
                    {testbed ? (
                        <Breadcrumb.Item href={`/testbed/${testbed.id}`}>
                            {testbed.longName}
                        </Breadcrumb.Item>
                    ) : []}
                    <Breadcrumb.Item href={`/history/${testInstanceId}`}>
                        {name}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Content className="content-after-breadcrumb">

                    <h1>History for {name}</h1>
                    <p className="lead">{testInstanceDescription}</p>


                    <h3>Results</h3>

                    <Table className="testinstance-results-table" dataSource={resultsData}
                           columns={columns} size="middle" onChange={onTableChange}
                           pagination={{showSizeChanger: true, defaultPageSize: 25, pageSizeOptions: ['10', '25', '50']}}
                           rowClassName={row => `testresult-summary-${row.summary ? row.summary.toLowerCase() : "unknown"}`}>
                        {/*<TableHeaderColumn dataField='created' isKey={true} dataFormat={dateFormatter}*/}
                        {/*filterFormatted*/}
                        {/*dataSort={true} width='40%'>Start</TableHeaderColumn>*/}
                        {/*<TableHeaderColumn dataField='duration' dataFormat={durationFormatter} dataSort={true}*/}
                        {/*width='20%'>Duration</TableHeaderColumn>*/}
                        {/*<TableHeaderColumn dataField='summary' dataFormat={enumFormatter}*/}
                        {/*formatExtraData={summaryType}*/}
                        {/*columnClassName={summaryColumnClassName}*/}
                        {/*filter={{type: 'SelectFilter', options: summaryType}} filterFormatted*/}
                        {/*width='20%'*/}
                        {/*dataAlign="center">Summary</TableHeaderColumn>*/}
                        {/*{specialcolumnHeaders}*/}
                    </Table>

                </Content></Layout>);
    }
}

TestInstanceHistory.propTypes = {
    testInstanceId: PropTypes.string.isRequired,
    testInstance: PropTypes.object,
    testDefinition: PropTypes.object,
    server: PropTypes.object,
    testbed: PropTypes.object,
    results: PropTypes.array
};

function mapStateToProps(state, ownProps) {
    const testInstanceId = ownProps.match.params.testInstanceId;

    const testInstance = state.testInstances.byId[testInstanceId];

    let server = null;
    let testbed = null;

    let testDefinition = null;
    if (testInstance) {
        if (testInstance.parameters && testInstance.parameters.server) {
            server = state.coreInfo.servers.byId[testInstance.parameters.server];

            if (server && server.testbed) {
                testbed = state.coreInfo.testbeds.byId[server.testbed];
            }
        }

        if (testInstance.testDefinition) {
            testDefinition = state.testDefinitions.byId[FedmonUtils.getIdFromUrlString(testInstance.testDefinition)];
        }
    }

    let results = [];

    if (state.testResults.byTestInstanceId[testInstanceId]) {
        results = state.testResults.byTestInstanceId[testInstanceId].map(id => state.testResults.byId[id])
    }

    return {
        testInstanceId,
        testInstance,
        testDefinition,
        server,
        testbed,
        results,
        isCoreInfoLoading: state.coreInfo.isCoreInfoLoading
    }
}

export default withRouter(connect(mapStateToProps)(TestInstanceHistory));