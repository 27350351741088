import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Layout, Anchor} from 'antd';
import {Element, scroller} from 'react-scroll';

import Loading from '../../../components/Loading/index'
import {loadTestResultIfNeeded} from "../../../actions/testResults";

import FInteropTestSuiteDetail from './FInteropTestSuiteDetail'

import './FInteropDetails.css'

const {Sider, Content} = Layout;

class FInteropDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            siderCollapsed: false,
        }
    }

    componentDidMount() {
        const {resultId, result, dispatch, hash} = this.props;
        dispatch(loadTestResultIfNeeded(resultId));


        if (result && hash) {
            scroller.scrollTo(hash, {offset: -24});

        }
    }

    componentDidUpdate(prevProps) {
        const {resultId, result, dispatch, hash} = this.props;

        if (resultId !== prevProps.resultId) {
            dispatch(loadTestResultIfNeeded(resultId));
        }

        if (result !== prevProps.result || hash !== prevProps.hash) {
            scroller.scrollTo(hash, {offset: -24});
        }
    }

    render() {
        const {result, hash} = this.props;
        const {siderCollapsed} = this.state;

        if (!result) {
            return (<Layout><Content className="content-wrapper"><Loading/></Content></Layout>);
        }
        const rawDetails = result.results;

        const finteroptests = [];
        if (rawDetails && rawDetails.extract) {
            //extract f-interop info

            Object.values(rawDetails.extract).forEach(testresult => {
                finteroptests.push(testresult)
            });
        }

        const selectedKeys = [];
        if (hash) {
            selectedKeys.push(hash);
        }
        return (
            <Layout>
                <Sider style={{backgroundColor: "#f0f2f5"}} width={280} breakpoint="lg" collapsedWidth={0}
                       onCollapse={(status) => this.setState({siderCollapsed: status})}>
                    {!siderCollapsed ? (
                        <Anchor className="finterop-testsuites-anchor">
                            {finteroptests.map(finteroptest =>
                                (<Anchor.Link key={finteroptest.testname} href={`#${finteroptest.testname}`}
                                              title={finteroptest.testname}>
                                    {/*too many results: finteroptest.tc_results.map(tc => (<Anchor.Link key={tc.testcase_id} href={`#${finteroptest.testname}-${tc.testcase_id}`}
                                 title={tc.testcase_id} />))*/}
                                </Anchor.Link>))}

                        </Anchor>) : null}
                </Sider>
                <Content>
                    {finteroptests.map(finteroptest =>
                        (<Element name={finteroptest.testname} key={finteroptest.testname}>
                                <div style={{margin: 24, padding: 24, backgroundColor: '#fff'}}>
                                    <FInteropTestSuiteDetail key={finteroptest.testname}
                                                             testsuite={finteroptest}/>
                                </div>
                            </Element>
                        )
                    )}
                </Content>
            </Layout>);

    }
}

FInteropDetails.propTypes = {
    resultId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    const resultId = ownProps.match.params.resultId;
    const result = state.testResults.byId[resultId];

    const hash = state.router.location.hash ? state.router.location.hash.substring(1) : null;

    return {resultId, result, hash}
}

export default connect(mapStateToProps)(FInteropDetails);