import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {Element, scroller} from 'react-scroll'
import {Row, Col, Layout, Menu, Affix} from 'antd';

import Loading from '../../../components/Loading/index'
import {loadTestResultIfNeeded} from "../../../actions/testResults";

import FInteropTestSuiteSummary from './FInteropTestSuiteSummary'
import FInteropTestSuiteTable from "./FInteropTestSuiteTable";

const {Sider, Content} = Layout;

class FInteropOverview extends Component {

    componentDidMount() {
        const {resultId, result, dispatch, hash} = this.props;
        dispatch(loadTestResultIfNeeded(resultId));

        if (result && hash) {
            scroller.scrollTo(hash, {offset: -24});
        }
    }

    componentDidUpdate(prevProps) {
        const {resultId, result, dispatch, hash} = this.props;

        if (resultId !== prevProps.resultId) {
            dispatch(loadTestResultIfNeeded(resultId));
        }

        if (result !== prevProps.result || hash !== prevProps.hash) {
            scroller.scrollTo(hash, {offset: -24});
        }
    }

    render() {
        const {result, hash} = this.props;

        if (!result) {
            return (<Layout><Content className="content-wrapper"><Loading/></Content></Layout>);
        }
        const rawDetails = result.results;

        const finteroptests = [];
        if (rawDetails && rawDetails.extract) {
            //extract f-interop info

            Object.values(rawDetails.extract).forEach(testresult => {
                finteroptests.push(testresult)
            });
        }

        const selectedKeys = [];
        if (hash) {
            selectedKeys.push(hash);
        }

        return (
            <Layout>
                <Sider style={{backgroundColor: "#f0f2f5"}}>
                    <Affix offsetTop={0}>
                        <Menu theme="light" mode="inline" selectedKeys={selectedKeys}
                              style={{paddingTop: 24, height: '100%', backgroundColor: "#f0f2f5"}}>
                            <Menu.Item key="graphs"><NavLink to="#graphs">Graphs</NavLink></Menu.Item>
                            <Menu.Item key="table"><NavLink to="#table">Table</NavLink></Menu.Item>
                        </Menu>
                    </Affix>
                </Sider>
                <Content>
                    <Element name="graphs">
                        <div id="graphs" style={{margin: 24}}>
                            <Row gutter={16}>
                                {finteroptests.map(finteroptest => (
                                    <Col key={finteroptest.testname} md={8} style={{marginBottom: 16}}>
                                        <FInteropTestSuiteSummary testresult={finteroptest}
                                                                  detailsUrl={`/result/${result.id}/finterop/details/#${finteroptest.testname}`}/>
                                    </Col>))}
                            </Row>
                        </div>
                    </Element>
                    <Element name="table">

                        <div id="table" style={{margin: 24, /*padding: 24,*/ backgroundColor: "#fff"}}>
                            <FInteropTestSuiteTable finteroptests={finteroptests} resultId={result.id}/>
                        </div>
                    </Element>
                </Content>
            </Layout>);

    }
}

FInteropOverview.propTypes = {
    resultId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    const resultId = ownProps.match.params.resultId;
    const result = state.testResults.byId[resultId];

    const hash = state.router.location.hash ? state.router.location.hash.substring(1) : null;

    return {resultId, result, hash}
}

export default connect(mapStateToProps)(FInteropOverview);