import FedmonApi from '../api/fedmonApi'
import * as types from "./actionTypes";

import {normalize} from 'normalizr';
import * as schema from '../api/schema';

function testInstancesByTestbedShouldUpdate(testbedId, state) {
    return !state.testInstances.isLoading.includes(testbedId)
        && !state.testInstances.byTestbedId[testbedId]; //no timeout for data as it rarely changes
}

export function loadTestInstancesByTestbedIfNeeded(testbedId) {
    if (!testbedId)
        throw new Error("testbedId must not be null");
    return (dispatch, getState) => {
        if (testInstancesByTestbedShouldUpdate(testbedId, getState())) {
            dispatch(loadTestInstancesByTestbed(testbedId));
        }
    }
}

export function loadTestInstancesByTestbed(testbedId) {
    if (!testbedId)
        throw new Error("testbedId must not be null");
    return (dispatch) => {
        dispatch(testInstancesByTestbedIsLoading(true, testbedId));

        FedmonApi.getTestInstancesByTestbed(testbedId)
            .then(result => {
                const normalizedResult = normalize(result, [schema.testInstance]);
                dispatch(testInstancesByTestbedIsLoading(false, testbedId));
                dispatch(fetchTestInstancesByTestbedSuccess(normalizedResult, testbedId));
            })
            .catch(error => {
                dispatch(testInstancesByTestbedIsLoading(false, testbedId));
                dispatch(fetchTestInstancesByTestbedError(error, testbedId));
            });
    }
}

export function testInstancesByTestbedIsLoading(isLoading, testbedId) {
    return {'type': types.TESTINSTANCES_BY_TESTBED_IS_LOADING, isLoading, testbedId}
}

export function fetchTestInstancesByTestbedSuccess(result, testbedId) {
    return {'type': types.LOAD_TESTINSTANCES_BY_TESTBED_SUCCESS, result, testbedId, 'timestamp': Date.now()}
}

export function fetchTestInstancesByTestbedError(error, testbedId) {
    return {'type': types.LOAD_TESTINSTANCES_BY_TESTBED_ERROR, error, testbedId, 'timestamp': Date.now()}
}


////////////////////////////////////////////////


function testInstanceShouldUpdate(testInstanceId, state) {
    return !state.testInstances.isLoading.includes(testInstanceId)
        && !state.testInstances.byId[testInstanceId]; //no timeout for data as it rarely changes
}

export function loadTestInstanceIfNeeded(testInstanceId) {
    if (!testInstanceId)
        throw new Error("testInstanceId must not be null");
    return (dispatch, getState) => {
        if (testInstanceShouldUpdate(testInstanceId, getState())) {
            dispatch(loadTestInstance(testInstanceId));
        }
    }
}

export function loadTestInstance(testInstanceId) {
    if (!testInstanceId)
        throw new Error("testInstanceId must not be null");
    return (dispatch) => {
        dispatch(testInstanceIsLoading(true, testInstanceId));

        FedmonApi.getTestInstance(testInstanceId)
            .then(result => {
                dispatch(testInstanceIsLoading(false, testInstanceId));
                dispatch(fetchTestInstanceSuccess(result, testInstanceId));
            })
            .catch(error => {
                dispatch(testInstanceIsLoading(false, testInstanceId));
                dispatch(fetchTestInstanceError(error, testInstanceId));
            });
    }
}

export function testInstanceIsLoading(isLoading, testInstanceId) {
    return {'type': types.TESTINSTANCE_IS_LOADING, isLoading, testInstanceId}
}

export function fetchTestInstanceSuccess(result, testInstanceId) {
    return {'type': types.LOAD_TESTINSTANCE_SUCCESS, result, testInstanceId, 'timestamp': Date.now()}
}

export function fetchTestInstanceError(error, testInstanceId) {
    return {'type': types.LOAD_TESTINSTANCE_ERROR, error, testInstanceId, 'timestamp': Date.now()}
}


export function restartTestInstance(testInstanceId) {
    if (!testInstanceId)
        throw new Error("testInstanceId must not be null");

    return (dispatch) => {
        dispatch(testInstanceIsRestarting(true, testInstanceId));

        //use this for test purposes
        // setTimeout(() => {
        //     dispatch(testInstanceIsRestarting(false, testInstanceId));
        // }, 2000);

        FedmonApi.restartTestInstance(testInstanceId)
            .then(result => {
                dispatch(testInstanceIsRestarting(false,testInstanceId));
                dispatch(restartTestInstanceSuccess(result,testInstanceId));
            })
            .catch(error => {
                dispatch(testInstanceIsRestarting(false,testInstanceId));
                dispatch(restartTestInstanceError(error,testInstanceId));
            });
    }
}

export function testInstanceIsRestarting(isRestarting, testInstanceId) {
    return {'type': types.TESTINSTANCE_IS_RESTARTING, isRestarting, testInstanceId}
}

export function restartTestInstanceSuccess(result, testInstanceId) {
    return {'type': types.RESTART_TESTINSTANCE_SUCCESS, result, testInstanceId, 'timestamp': Date.now()}
}

export function restartTestInstanceError(error, testInstanceId) {
    return {'type': types.RESTART_TESTINSTANCE_ERROR, error, testInstanceId, 'timestamp': Date.now()}
}


/////////////////////////////////////////



function testInstanceStatisticsShouldUpdate(testInstanceId, state) {
    return !state.testInstances.statisticsIsLoading.includes(testInstanceId)
        && !state.testInstances.statisticsById[testInstanceId]; //no timeout for data as it rarely changes
}

export function loadTestInstanceStatisticsIfNeeded(testInstanceId) {
    if (!testInstanceId)
        throw new Error("testInstanceId must not be null");
    return (dispatch, getState) => {
        if (testInstanceStatisticsShouldUpdate(testInstanceId, getState())) {
            dispatch(loadTestInstanceStatistics(testInstanceId));
        }
    }
}

export function loadTestInstanceStatistics(testInstanceId) {
    if (!testInstanceId)
        throw new Error("testInstanceId must not be null");
    return (dispatch) => {
        dispatch(testInstanceStatisticsIsLoading(true, testInstanceId));

        FedmonApi.getTestInstanceStatistics(testInstanceId)
            .then(result => {
                dispatch(testInstanceStatisticsIsLoading(false, testInstanceId));
                dispatch(fetchTestInstanceStatisticsSuccess(result, testInstanceId));
            })
            .catch(error => {
                dispatch(testInstanceStatisticsIsLoading(false, testInstanceId));
                dispatch(fetchTestInstanceStatisticsError(error, testInstanceId));
            });
    }
}

export function testInstanceStatisticsIsLoading(isLoading, testInstanceId) {
    return {'type': types.TESTINSTANCESTATISTICS_IS_LOADING, isLoading, testInstanceId}
}

export function fetchTestInstanceStatisticsSuccess(result, testInstanceId) {
    return {'type': types.LOAD_TESTINSTANCESTATISTICS_SUCCESS, result, testInstanceId, 'timestamp': Date.now()}
}

export function fetchTestInstanceStatisticsError(error, testInstanceId) {
    return {'type': types.LOAD_TESTINSTANCESTATISTICS_ERROR, error, testInstanceId, 'timestamp': Date.now()}
}
