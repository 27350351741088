import React, {Component} from 'react';
import {PropTypes} from 'prop-types';

import FontAwesome from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {Line} from 'react-chartjs-2';
import FedmonUtils from '../../utils';


export default class ResourcesGraph extends Component {

    static propTypes = {
        results: PropTypes.array,
        timeDivision: PropTypes.string,
        showAvailable: PropTypes.bool,
        showTotal: PropTypes.bool
    };

    render() {

        const {results, types, showAvailable, showTotal,timeDivision} = this.props;

        if (!results || results.length === 0) {
            return (<div><h4><FontAwesome icon='spinner' pulse/> Loading...</h4></div>);
        }

        const dataSets = [];

        types.forEach((type, index) => {

            dataSets.push({
                label: "Total " + type.friendlyName,
                data: results.map(d => d["total" + type.id] || 0),
                yAxisID: "count",
                xAxisID: "from",
                borderColor: FedmonUtils.colors[index * 2],
                backgroundColor: FedmonUtils.getOpaqueColor(index * 2, 0.3),
                lineTension: 0.1,
                pointRadius: 0,
                hidden: !type.active || !showTotal,
            });

            dataSets.push({
                label: "Available " + type.friendlyName,
                data: results.map(d =>  d["free" + type.id] || 0),
                yAxisID: "count",
                xAxisID: "from",
                borderColor: FedmonUtils.colors[(index * 2) + 1],
                backgroundColor: FedmonUtils.getOpaqueColor((index * 2) + 1, 0.3),
                lineTension: 0.1,
                pointRadius: 0,
                hidden: !type.active || !showAvailable,

            });
        });

        const chartData = {
            labels: results.map(d => d.from),
            datasets: dataSets
        };

        const chartOptions = {
            legend: {
                position: 'right',
            },
            tooltips: {
                callbacks: {
                    title: (tti) => {
                        return moment(tti[0].xLabel).format('LLL');
                    },
                }
            },
            animation: {
                animateScale: true,
            },
            hover: {
                mode: "x",
                intersect: false,
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    id: "from",
                    time : {
                        unit: timeDivision,
                    }
                }],
                yAxes: [{
                    display: true,
                    id: "count",
                    ticks: {
                        beginAtZero: true,
                    }
                }]
            }
        };

        return (
            <Line options={chartOptions} data={chartData} width={300} height={100}/>
        );
    }
}